import React from 'react'
import HighlightedMessage from "../HighlightedMessage"
import Button from "../Button"
import Attention from "../../images/sun.svg"

export default function EmergencyContactForm({ setEmergencyContact, setEditEmergencyContact }) {

  const [formData, setFormData] = React.useState({
    name: "", region_code: "", phone_number: ""})

  const [error, setError] = React.useState(null)

  const userRef = React.useRef()

  // go to the top of the page
  React.useEffect(() => {
    userRef.current.focus()
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault()

    if (formData.region_code.length != 2 || formData.length < 9) {

      setError("Por favor, preencha o telefone no formato correto. Por exemplo: Código de área: 49, número: 98100-4885")

    } else if (formData.name !== "" && formData.region_code != "" && formData.phone_number) {
      setError(null)

      const newEmergencyContact = {
        name: formData.name,
        phone_number: `(${formData.region_code}) ${formData.phone_number}`,
      }

      setEmergencyContact(newEmergencyContact)
      setEditEmergencyContact()
    } else {
      setError("Por favor, preencha todos os dados para adicionar o contato de emergência.")
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  return (
    <div className="">
      {(error) ?
        <div className="">
          <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} />  
        </div>
        : null }
      <form onSubmit={handleSubmit} className="login-form">
        <p className="body top-padding-xs">Nome</p>
        <input
          className="input-text bottom-margin-xs"
          ref = {userRef}
          name="name"
          onChange={handleChange}
          type="text"
          value={formData.name}
        />

        <p className="body top-padding-xs">Telefone (código de área + número)</p>
        <div className="content-two-columns-phone">
          <input
            className="input-text-two-columns bottom-margin-xs"
            name="region_code"
            onChange={handleChange}
            type="text"
            value={formData.region_code}
            placeholder="xx"
          />
          <input
            className="input-text-two-columns bottom-margin-xs"
            name="phone_number"
            onChange={handleChange}
            type="text"
            value={formData.phone_number}
            placeholder="9xxxx-xxxx"
          />
        </div>
        <div className="top-margin-xs">
          <Button type="primary right-margin-s">
             Alterar
          </Button>
          <Button buttonType="button" onClick={()=>setEditEmergencyContact()} type="tertiary right-margin-s bottom-margin-s">
            Cancelar
          </Button>
        </div>
      </form>
    </div>
  )
}
