
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post147() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoal. Alguém tem alergia no inverno que coça muito fica irritada a pele? É mais as pernas</title>
        <meta 
          name="description" 
          content="Oi pessoal. Alguém tem alergia no inverno que coça muito fica irritada a pele? É mais as pernas"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Elisa Orta &#183; 23/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoal. Alguém tem alergia no inverno que coça muito fica irritada a pele? É mais as pernas
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/147')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  3 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Elisa,<br/><br/>Eu entendo como pode ser desconfortável lidar com coceira e irritação na pele, especialmente durante o inverno. Muitas pessoas experimentam esse problema devido ao clima mais seco nessa época do ano. Aqui estão algumas dicas que podem te ajudar:<br/><br/>1. **Hidratação:** Use um bom hidratante, especialmente depois do banho, para manter a pele hidratada. Peles secas tendem a coçar mais.<br/>2. **Banhos Curtos e Morno:** Evite tomar banhos longos e muito quentes, pois eles podem ressecar ainda mais a pele.<br/>3. **Sabonetes Suaves:** Utilize sabonetes neutros e suaves para não irritar a pele.<br/>4. **Roupas Confortáveis:** Use roupas de algodão que sejam macias e respiráveis para evitar irritação.<br/><br/>Se a coceira continuar ou piorar, é importante conversar com seu médico para verificar se há alguma outra causa por trás disso e qual seria o melhor tratamento.<br/><br/>Espero que essas dicas ajudem você a se sentir melhor!<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 23/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/147')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Tomaz Silveira &#183; 13/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/81-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, já fazem suas semanas que meço minha glicose em jejum a após fazer 1 hora de musculação e 50 minutos de caminhada, e quando meço antes do almoço sem comer nada, ela aumenta entre 30 e 40 pontos.<br/><br/>Estou estranhando porque isso nunca aconteceu antes, sempre reduzia cerca de 20 a 30 pontos após os exercícios, mas semana passada de 210 em jejum foi pra 265 após os exercícios e hoje 235 foi  pra 262 após exercícios. <br/><br/>Oque vocês acham?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/81')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Maria Meyer &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/63-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. O resultado do meu exame de sangue deu 175 de triglicerideos. Me assustou um pouco, oque me aconselham a fazer? Alguma dica?<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/63')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Michele Lombardi &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/103-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, meu filho tem 10 anos e está com triglicerídeos e colesterol mais alto que deveria. Gostaria de saber como posso fazer melhor a alimentação dele, oque pode e não pode comer. Obrigado.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/103')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        2 comentários
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Elisa Orta &#183; 14/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/91-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá, boa tarde pessoa. Uma pessoa da minha familia esta com colangiocarcinoma<br/>(Cancer nas vias biliares), e está muito magro e não consegue comer. Ele vai passar pela primeira vez no oncologista semana que vem mas já está bem fraco. Alguém aqui com esse tipo de experiência?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/91')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Gabrielli Machado &#183; 11/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/70-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Alguém pra indicar um adoçante que seja melhor pra quem tem diabetes?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/70')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
