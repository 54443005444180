import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
//import Attention from "../images/sun.svg"
import Header from "../components/Header"
//import ProfileBriefing from "../components/ProfileBriefing"
//import EditableItemsCard from "../components/EditableItemsCard"
//import CalendarHistory from "../components/CalendarHistory"
import EatingHistory from "../components/EatingHistory"
import Return from "../components/Forum/Return"

import EditableItemsCard2 from '../components/EditableItemsCard2'
import EditableItemForCard2 from '../components/EditableItemForCard2'
import { putRequestToApi, postRequestToApi, deleteDataFromApi } from '../api'
import { PortalContext } from '../AuthenticatedUser'
import { format } from "date-fns"

export default function Exercise() {

  //const {id} = useParams()

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const { health, loadHealthDataFromAPI, auth, cookies } = React.useContext(PortalContext);

  //cookies.set("userId", id, { path: '/' })

  const [dataModified, setDataModified] = React.useState(0)
  const [userHealth, setUserHealth] = React.useState({
    name: null,
    age: null
  })

  const navigate = useNavigate()

  // go to the top of the page
  React.useEffect(() => {

    if (!cookies.get("userId")) {
      cookies.set('pageOfInterest', '/exercise', { path: '/' })
      navigate("/family")
    } else {
      //window.scrollTo(0, 0)
      loadHealthDataFromAPI(+cookies.get("userId"))
    }
  }, []);

  React.useEffect(() => {
    const id = +cookies.get("userId")

    if (health[id]) {
      setUserHealth({
        name: `${health[id].first_name} ${health[id].last_name}`,
        age: `${health[id].age}`,
        exercisingGoals: health[id].exercising_goals ? `${health[id].exercising_goals}` : 'Nenhuma por enquanto',
        exerciseRecord: health[id].exercise_record ? 
          health[id].exercise_record.map(item => ({
            id: item.id, date: new Date(item.date), description: item.description
            })) : []
      })

    }
  }, [health])

  //console.log(userHealth)

  const addItemtoArray = (value) => {
    if (Object.keys(value).length !== 0) {
      setUserHealth(prev => {
        const newArray = prev.exerciseRecord
        newArray.push(value)
        return {...prev, exerciseRecord: newArray}
      })

      const body = {
        "id": +cookies.get("userId"),
        "name": 'exerciseRecord',
        "item": { description: value.description, date: format(value.date,"M/d/yyyy") }
      }
      console.log(body)
      postRequestToApi(body, 'userhealthlist', auth, cookies)
    }
  }

  const removeItemFromArray = (itemId) => {
    console.log('*')
    setUserHealth(prev => {
      const newArray = prev.exerciseRecord

      const index = newArray.findIndex(item => item.id===itemId)

      const body = {
        "id": +cookies.get("userId"),
        "name": 'exerciseRecord',
        "record_id": itemId
      }

      deleteDataFromApi(body, 'userhealthlist', auth, cookies)

      newArray.splice(index, 1)
      return {...prev, exerciseRecord: newArray}
    })
  }


  const editUserField = (name, value) => {
    if (value) {
      setUserHealth(prev => ({...prev, [name]: value}))
      
      setDataModified(prev => prev + 1)
    }
  }

  React.useEffect(() => {
    if (dataModified > 0) {
      console.log('user health changed, sending new data to API')

      const body = {
        "id": +cookies.get("userId"),
        "exercising_goals": userHealth.exercisingGoals
      }

      putRequestToApi(body, 'userexercising', auth, cookies) 
    }
  }, [dataModified])

  //    <ProfileBriefing type="message" icon={Attention} header="Mensagem" 
  //        body="Você conseguiu fazer caminhadas todas as semanas nesse último mês, parabéns!
  //        Uma sugestão é ir alterando o itinerário para adicionar variedade no seu execício."
  //        />
  //
  return (
    (!userHealth.name) ? 
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando página...</p> 
      </div>
      :
    <>
    <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
      <Header tabletBreakpoint={tabletBreakpoint} menuItems="portal" id={+cookies.get("userId")} menuShow={true}/>
    </div>

    <>
    <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>

      <div className="top-margin-xl bottom-margin-xs">
        <Return to={`/profile`} backTo='Página inicial'/>
      </div>
      <p className="florence-title bottom-margin-xl">Exercícios Físicos</p>

        <EditableItemsCard2 
          clsName="bottom-margin-l"
          title="Seus objetivos"
          icons={false}
        >
          <EditableItemForCard2 edit={true} editUserField={editUserField} userField={["exercisingGoals"]}
            placeholder={["Escreva aqui seus objetivos"]}>
            <p className="body">{userHealth.exercisingGoals}</p>
          </EditableItemForCard2>
        </EditableItemsCard2>

    </div>

    <div className="colorful-background top-margin-l bottom-margin-l">
      <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
        <EatingHistory 
          calendarItems={userHealth.exerciseRecord} 
          title={'Diário de exercícios '} 
          tagged={false}
          grade={false}
          addItemtoArray={addItemtoArray}
          removeItemFromArray={removeItemFromArray}
          type={"exercise"}
        />
      </div>
    </div>
    </>
    </>
  )
}
