import React from 'react'
import CheckItem from '../images/check.svg'

export default function ClickableItemForCard({ children, colour, button=false, onClick, listId}) {

  return (
    <div className={(colour) ? 
      `editable-item-for-card-${colour} top-margin-xs vertical-padding-xs left-padding-s right-padding-xs`:
      "editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"}>

      <div className='editable-item-for-card-header-center'>
        <div>{children}</div>
        {(button) ? <button onClick={()=>onClick(listId)} className='carousel-btn confirm-button'>
          <div className="confirm-button-header">
            <p className="body secondary-color">Confirmar</p>
            <img className="" src={CheckItem}/>
          </div>
        </button>
        : null }
      </div>
  
    </div>
  )
}
