
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post7() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Dúvida saúde - geral - Colesterol bom e colesterol ruim</title>
        <meta 
          name="description" 
          content="Uma das primeiras coisas que descobri depois do diagnóstico de colesterol alto foi que existem dois tipos de colesterol: o colesterol ruim (LDL) e o colesterol do bem (HDL). O colesterol total é a soma desses dois, e colesterol alto é ruim porque quando ele está alto, a maior parte dele é composta do colesterol ruim (LDL). 

Importante: Pessoas com colesterol alto têm cerca de o dobro do risco de desenvolverem uma doença cardíaca em comparação com pessoas com níveis de colesterol normais.

Assim, para melhorar o colesterol, o que precisamos fazer é tentar REDUZIR nosso colesterol ruim (LDL) enquanto AUMENTAMOS nosso colesterol bom (HDL). 

A primeira causa de termos alto colesterol ruim (LDL) é comer comidas que têm gorduras ruins para nós (gorduras trans e gorduras saturadas). O colesterol bom (HDL) ajuda a combater e reduzir o colesterol ruim (LDL), e quantidade muito baixa dele é uma causa de doença cardíaca.

Os três fatores SOB NOSSO controle que influenciam nosso colesterol (aumentam o bom, reduzem o ruim) são:

1) Nosso peso: Obesidade pode aumentar nosso colesterol ruim (LDL) até 50%, ao mesmo tempo que pode reduzir nosso colesterol bom (HDL) em 20%.

2) O que nós comemos: Tem muita coisa para falar aqui, mas o importante é comer saudável: frutas, verduras, fibras, peixes. Evitar comida processada, frituras, carne vermelha.

3) Exercício físico: Exercício é o último fator porque ele influencia os outros dois: pessoas que se exercitam tendem a comer melhor e pesar menos. Exercício também é chave para fortalecer o músculo do coração.

Aprender sobre esses pontos me ajudou muito no início da minha melhora de saúde, e espero que possa servir para vocês também.
"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            L. Carrol &#183; 23/07/2024
          </p>
        </div>
        <p className="heading4 bottom-margin-s">Colesterol bom e colesterol ruim</p>


        <p className="body content bottom-padding-s">
            Uma das primeiras coisas que descobri depois do diagnóstico de colesterol alto foi que existem dois tipos de colesterol: o colesterol ruim (LDL) e o colesterol do bem (HDL). O colesterol total é a soma desses dois, e colesterol alto é ruim porque quando ele está alto, a maior parte dele é composta do colesterol ruim (LDL). <br/><br/>Importante: Pessoas com colesterol alto têm cerca de o dobro do risco de desenvolverem uma doença cardíaca em comparação com pessoas com níveis de colesterol normais.<br/><br/>Assim, para melhorar o colesterol, o que precisamos fazer é tentar REDUZIR nosso colesterol ruim (LDL) enquanto AUMENTAMOS nosso colesterol bom (HDL). <br/><br/>A primeira causa de termos alto colesterol ruim (LDL) é comer comidas que têm gorduras ruins para nós (gorduras trans e gorduras saturadas). O colesterol bom (HDL) ajuda a combater e reduzir o colesterol ruim (LDL), e quantidade muito baixa dele é uma causa de doença cardíaca.<br/><br/>Os três fatores SOB NOSSO controle que influenciam nosso colesterol (aumentam o bom, reduzem o ruim) são:<br/><br/>1) Nosso peso: Obesidade pode aumentar nosso colesterol ruim (LDL) até 50%, ao mesmo tempo que pode reduzir nosso colesterol bom (HDL) em 20%.<br/><br/>2) O que nós comemos: Tem muita coisa para falar aqui, mas o importante é comer saudável: frutas, verduras, fibras, peixes. Evitar comida processada, frituras, carne vermelha.<br/><br/>3) Exercício físico: Exercício é o último fator porque ele influencia os outros dois: pessoas que se exercitam tendem a comer melhor e pesar menos. Exercício também é chave para fortalecer o músculo do coração.<br/><br/>Aprender sobre esses pontos me ajudou muito no início da minha melhora de saúde, e espero que possa servir para vocês também.<br/>
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/7')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  1 pessoa achou útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              Nenhum comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/7')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Maria Silva &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/53-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, amanhã é minha última seção de quimioterapita. Peço orações por mim
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/53')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Neila Pereira &#183; 12/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/76-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoa. Uma pergunta por curiosidade. Qual seria a diferença entre diabetes tipo 1 e tipo 2?<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/76')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mario César &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/97-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Estou bastante triste, descobri que estou com catarata por conta da diabete segundo o oftalmologista. Não consigo entender muito bem, mantenho a glicose controlada, jejum 105, prandial deu até menos, 100 glicada 6.4. Estou preocupado.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/97')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            6 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marcela Prattes &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/66-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Poderiam me indicar alguma dieta pra café da manhã, almoço e janta. Estou com trigliceridios de 170
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/66')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariana Zandona &#183; 16/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/109-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi gente. Vai fazer dois meses que fiz a última quimioterapia da branca de cinco horas cada foram seis seções e estou ainda totalmente dormente nos pés e dor nas pernas e cólicas. Já fiz três braquiterapia e agora vou fazer 24 radioterapias. Mas essa dormência é terrível.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/109')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
