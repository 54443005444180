import React from 'react'
import LikeIcon from '../../images/like.svg'
import FilledLikeIcon from '../../images/like-filled.svg'
import { useNavigate, useLocation } from "react-router-dom"
import { ForumContext } from '../../forum/ForumData'
import AuthContext from '../../context/AuthProvider'
import { postRequestToApi, deleteDataFromApi } from '../../api'

export default function PostLikes({ postId, foundUseful, qtyPeopleFoundUseful }) {
 
  const { loggedIn, loadForumData } = React.useContext(ForumContext)
  const { auth, setAuth, cookies } = React.useContext(AuthContext);

  const [likesCache, setLikesCache] = React.useState({foundUseful: false, qtyPeopleFoundUseful: 0})

  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    setLikesCache({foundUseful, qtyPeopleFoundUseful})
  }, [])

  async function submitUsefulPost() {
    try {
      const data = await postRequestToApi({
        "id": +cookies.get("userId"),       
      }, `forum/${postId}/useful`, auth, cookies)
      //setResponse(data)
      console.log('debug')
      console.log(data)
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 
    } catch (err) {
      console.log("Error loading forum data")
      //navigate('/loginrequired')
      window.location.reload();
    } //finally {
      //loadForumData(true) // true: user is loggedin
    //}
  }

  async function deleteUsefulPost() {
    try {
      const data = await deleteDataFromApi({
        "id": +cookies.get("userId"),       
      }, `forum/${postId}/useful`, auth, cookies)
      //setResponse(data)
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 
    } catch (err) {
      console.log("Error loading forum data")
      //navigate('/loginrequired')
      window.location.reload();
    } //finally {
      //loadForumData(true) // true: user is loggedin
    //}
  }

  function toggleLike() {
    if (!cookies.get("userId")) {
      navigate('/loginrequired', { state: { pageOfInterest: location.pathname }})
    } else {
      if (likesCache.foundUseful) {
        setLikesCache(prev => ({
          foundUseful: false, qtyPeopleFoundUseful: prev.qtyPeopleFoundUseful - 1
        }))
        deleteUsefulPost()
      } else {
        setLikesCache(prev => ({
          foundUseful: true, qtyPeopleFoundUseful: prev.qtyPeopleFoundUseful + 1
        }))
        submitUsefulPost()
      }
    }
  }

  return (
    <button onClick={()=>toggleLike()} className="svg-btn">
      <div className="post-useful-info">
        <div>{(likesCache.foundUseful) ? 
          <img src={FilledLikeIcon} alt="Button indicating if you found this useful" />
          : <img src={LikeIcon} alt="Button indicating if you found this useful" />}</div>
        <p className="secondary-text">{(likesCache.qtyPeopleFoundUseful > 0 ? 
          (likesCache.qtyPeopleFoundUseful === 1) ? 
            "1 pessoa achou útil" : `${likesCache.qtyPeopleFoundUseful} pessoas acharam útil` 
          : "Esse post foi útil?" ) }</p>
      </div>
    </button>
  )
}
