import React from 'react'
import { max, scaleLinear, scaleBand } from 'd3'
import Marks from './Marks'
import Legend from './Legend'

export default function Plot ({ data, parentWidth }) {

  const width=parentWidth-50
  const height=158+54*data.length
  const margin={
    top: (data.length > 2) ? 54*data.length : 64*data.length,
    right: 30,
    left: 30,
    bottom: 40
  }

  const innerHeight = height - margin.top - margin.bottom
  const innerWidth = width - margin.right - margin.left

  const yValue = d => d.value
  const xValue = d => d.class
 
  const xScale = scaleBand()
    .domain(data.map(xValue))
    .range([0, innerWidth])
    .paddingOuter(0.3)
    .paddingInner(0.4)

  const yScale = scaleLinear()
    .domain([0, max(data, yValue)])
    .range([innerHeight, 0])

  return (
    <div className='plot'>
      <svg width={width} height={height}>
        <rect width={width} height={height} rx="12"/>
        <g transform={`translate(${margin.left}, ${margin.top})`}>
          <Marks data={data} yScale={yScale} xScale={xScale} yValue={yValue} xValue={xValue} innerHeight={innerHeight}/>  
          <line className="plot-line" y1={innerHeight} y2={innerHeight} x2={innerWidth}/>
        </g>

        <g transform={`translate(0, 20)`}>
          <Legend 
            data={data} 
            innerWidth={innerWidth+25} 
            height={27}
            width={125}
          /> 
        </g>
      </svg>
    </div>
  )
}
