import React from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import Button from '../Button'
import AuthContext from '../../context/AuthProvider'
import { ForumContext } from '../../forum/ForumData'
import { postRequestToApi, deleteDataFromApi } from '../../api'

export default function Author({ linkToFollow=false, followedBy, children }) {

  const { loadForumData } = React.useContext(ForumContext)
  const { auth, setAuth, cookies } = React.useContext(AuthContext);

  const [followedCache, setFollowedCache] = React.useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    if (followedBy && followedBy.includes(cookies.get("userId"))) {
      setFollowedCache(true)
    } else {
      setFollowedCache(false)
    }
  }, [])

  async function submitFollowAuthor() {
    try {
      const data = await postRequestToApi({
        "id": +cookies.get("userId"),
        "author_id": +children.author_id
      }, `forum/follow`, auth, cookies)
      //setResponse(data)
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      }))
    } catch (err) {
      console.log("Error loading forum data")
      navigate('/loginrequired')
    } finally {
      loadForumData(true) // true: user is loggedin
    }
  }

  async function deleteFollowAuthor() {
    try {
      const data = await deleteDataFromApi({
        "id": +cookies.get("userId"),
        "author_id": +children.author_id
      }, `forum/follow`, auth, cookies)
      //setResponse(data)
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 
    } catch (err) {
      console.log("Error loading forum data")
      navigate('/loginrequired')
    } finally {
      loadForumData(true) // true: user is loggedin
    }
  }

  function toggleFollow() {
    if (followedCache) {
      setFollowedCache(prev => !prev) 
      deleteFollowAuthor()
    } else {
      setFollowedCache(prev => !prev) 
      submitFollowAuthor()
    }
  }

  let followButton = null
  if (cookies.get("userId")) {
    if (followedCache) {
      followButton = <Button type="tertiary" onClick={toggleFollow}>Deixar de seguir autor</Button>
    } else {
      followButton = <Button type="tertiary" onClick={toggleFollow}>Seguir autor</Button>
    }
  } else {
    followButton = <Button type="tertiary" link={true} to="/loginrequired" stateValue={{ pageOfInterest: location.pathname }}>
      Seguir autor
    </Button>
  }

  const content = (!linkToFollow || children.author_id === cookies.get("userId")) ? 
    <p className="secondary-text bottom-margin-xs">
      {children.first_name + ' ' + children.last_name} &#183; {new Date(children.created_at).toLocaleDateString('en-GB')}</p>
    : 
    <div className="post-stats">
      <p className="body secondary-color">
        {children.first_name + ' ' + children.last_name} &#183; {new Date(children.created_at).toLocaleDateString('en-GB')}</p>
      {followButton}
    </div>

  return content
}
