import React from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import Button from '../components/Button'
import { userLogin } from "../api"
import AuthContext from "../context/AuthProvider"
import HighlightedMessage from './HighlightedMessage'
import Attention from "../images/sun.svg"

export default function LoginBlock({ password=false }) {

  const userRef = React.useRef();

  const location = useLocation()
  
  const navigate = useNavigate()
  
  const { setAuth, cookies } = React.useContext(AuthContext);

  const [loginFormData, setLoginFormData] = React.useState({
    email: "", password: ""
  })
  const [status, setStatus] = React.useState("idle")
  const [error, setError] = React.useState(null)
  const [user, setUser] = React.useState(null)

  React.useEffect(() => {
    userRef.current.focus()

    console.log(location)
    if (location.state && (location.state.pageOfInterest || location.state.search)) {
      if (location.state.pageOfInterest) {
        cookies.set('pageOfInterest', location.state.pageOfInterest, { path: '/' })
      } else if (location.state.search.pageOfInterest) {
        cookies.set('pageOfInterest', location.state.search.pageOfInterest, { path: '/' })
      }
    }

  }, [])

  React.useEffect(()=>{
    if (user) {
      navigate(location.state?.previousPath || 
        "/profile", { replace: true })
    }
  }, [user])

  const handleSubmit = (e) => {
    e.preventDefault()
    setError(null)
    setUser(null)

    async function tryLoggingIn(loginFormData) {
      try {
        setStatus("submitting")
        const data = await userLogin(loginFormData)
        setUser(data)

        const accessToken = data?.access_token
        const refreshToken = data?.refresh_token
        const userId = data?.id
        const userName = data?.first_name
        const userLastName = data?.last_name

        setAuth({ accessToken, refreshToken })
    
        cookies.set("userId", userId, { path: '/' })
        cookies.set("userName", userName, { path: '/' })
        cookies.set("userLastName", userLastName, { path: '/' })
      } catch(err) {
        console.log(err)
        setError(err.message)
      } finally {         
        setStatus("idle")
      }
    }

    if (loginFormData.password === "" || loginFormData.email === "") {
      setError("Preencha os dados de login");
    } else {
      tryLoggingIn(loginFormData)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setLoginFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  return (
    <div className="login-container horizontal-padding-l">

      {(error) ?
        <div className="top-padding-xs">
          <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} />  
        </div>
        : null }

      <form onSubmit={handleSubmit} className="login-form">
        <p className="body top-padding-s">E-mail</p>
        <input
          className="input-text bottom-margin-xs"
          ref={userRef}
          name="email"
          onChange={handleChange}
          type="email"
          placeholder="Digite seu e-mail"
          value={loginFormData.email}
        />
        <p className="body">Senha</p>
        <input
          className="input-text bottom-margin-xs"
          name="password"
          onChange={handleChange}
          type="password"
          placeholder="Digite sua senha"
          value={loginFormData.password}
        />
        
        <div>
          <Button type="primary bottom-margin-l">
            {(status==="idle")? "Entrar em sua conta" : "Verificando..."}
          </Button>
        </div>
      </form>
      
      <div>
        { (password) ?
          <Button type="tertiary bottom-margin-s" link={true} to='/loginrequired' stateValue="sendpassword">Reenviar senha</Button> :
          <Button type="tertiary bottom-margin-s" link={true} to='/loginrequired' stateValue="sendpassword">Esqueci minha senha</Button>
        }
      </div>
      <div>
        { (password) ? null :
          <Button type="secondary bottom-margin-s" link={true} to='/registration'>Cadastre-se</Button>
        }
      </div>
    </div>
  )
}
