import React from 'react'
import { Link } from "react-router-dom"
import LeftArrow2 from "../../images/left-arrow-card-2.svg"
import RightArrow2 from "../../images/right-arrow-card-2.svg"


export default function HealthCard({
  bloodType,
  name,
  dateOfBirth,
  sex,
  susNumber,
  insuranceName,
  insuranceNumber,
  emergencyContact,
  allergies,
  diseases,
  organDonation,
  id,
  onClick=(userId)=>{},
  linkTo='.',
  }) {

  let allergiesText = "-"
  if (allergies) {
    if (allergies.length === 1) {
      allergiesText = allergies.at(0).allergy
    } else if (allergies.length > 1) {
      allergies.forEach((item, index) => {
        if (index==0) {
          allergiesText = item.allergy
        } else {
          allergiesText += `, ${item.allergy}`
        }
      })
    }
  }

  let diseaseText = "-"
  if (diseases) {
    if (diseases.length === 1) {
      diseaseText = diseases.at(0).disease
    } else if (diseases.length > 1) {
      diseases.forEach((item, index) => {
        if (index==0) {
          diseaseText = item.disease
        } else {
          diseaseText += `, ${item.disease}`
        }
      })
    }
  }
 
  const [cardFace, setCardFace] = React.useState(true)

  const front = (
    <div className="container-card">
      <div className="top-margin-l health-card">

        <div className="circle-left"/>
        <div className="circle-right"/>

        <Link className="thumbnail-link" to={linkTo} onClick={()=>onClick(id)}>
        <div className="card-header">
          <img className="card-logo" src={require("../../images/florence-image-text.png")} alt="florence logo"/>
        
          <div className="title-content-column-right-aligned">
            <p className="small-title-2">Tipo Sanguíneo</p>
            <p className="heading4-no-bold inv-text-color">{(bloodType) ? bloodType : '-'}</p>
          </div>
        </div>

        <div className="card-row top-margin-xs">
          <div className="title-content-column">
            <p className="small-title-2">Nome</p>
            <p className="body inv-text-color">{(name) ? name : '-'}</p>
          </div>
        </div>
        
        <div className="card-grid top-margin-xs">
          <div className="title-content-column">
            <p className="small-title-2">Data Nascimento</p>
            <p className="body inv-text-color">{(dateOfBirth) ? dateOfBirth : '-'}</p>
          </div>
          <div className="title-content-column">
            <p className="small-title-2">Sexo</p>
            <p className="body inv-text-color">{(sex) ? sex.charAt(0).toUpperCase() + sex.slice(1) : '-'}</p>
          </div>
        </div>
        
        <div className="card-row top-margin-xs">
          <div className="title-content-column">
            <p className="small-title-2">Cartão SUS</p>
            <p className="body inv-text-color">{(susNumber) ? susNumber : '-'}</p>
          </div>
        </div>
        
        <div className="card-row top-margin-xs">
          <div className="title-content-column">
            <p className="small-title-2">Convênio</p>
            <p className="body inv-text-color">{(insuranceName) ? insuranceName : '-'}</p>
            <p className="body inv-text-color">{(insuranceNumber) ? insuranceNumber : null}</p>
          </div>
        </div>

        <div className="card-row top-margin-xs">
          <div className="title-content-column">
            <p className="small-title-2">Contato de Emergência</p>
            <p className="body inv-text-color">{(emergencyContact.name) ? emergencyContact.name : '-'}</p>
            <p className="body inv-text-color">{(emergencyContact.phone_number) ? emergencyContact.phone_number : null}</p>
          </div>
        </div>
        </Link>
        
        <button className="card-footer-right" onClick={()=>{setCardFace(prev=>!prev)}}>
          <p className="secondary-text inv-text-color">Dados de saúde</p>
          <img className="" src={RightArrow2}/>
        </button>
      </div>
    </div>
  )

  const back = (
    <div className="container-card">
      <div className="top-margin-l health-card">
        
        <div className="circle-left"/>
        <div className="circle-right"/>
        
        <Link className="thumbnail-link" to={linkTo} onClick={()=>onClick(id)}>
        <div className="card-header">
          <img className="card-logo" src={require("../../images/florence-image-text.png")} alt="florence logo"/>
          <div className="title-content-column-right-aligned">
            <p className="small-title-2">Tipo Sanguíneo</p>
            <p className="heading4-no-bold inv-text-color">{(bloodType) ? bloodType : '-'}</p>
          </div>
        </div>

        <div className="card-row top-margin-xs">
          <div className="title-content-column">
            <p className="small-title-2">Nome</p>
            <p className="body inv-text-color">{(name) ? name : '-'}</p>
          </div>
        </div>
        
        <div className="card-grid top-margin-xs">
          <div className="title-content-column">
            <p className="small-title-2">Data Nascimento</p>
            <p className="body inv-text-color">{(dateOfBirth) ? dateOfBirth : '-'}</p>
          </div>
          <div className="title-content-column">
            <p className="small-title-2">Sexo</p>
            <p className="body inv-text-color">{(sex) ? sex.charAt(0).toUpperCase() + sex.slice(1) : '-'}</p>
          </div>
        </div>
        
        <div className="card-row top-margin-xs">
          <div className="title-content-column">
            <p className="small-title-2">Alergias</p>
            <p className="body inv-text-color">{allergiesText}</p>
          </div>
        </div>
        
        <div className="card-row top-margin-xs">
          <div className="title-content-column">
            <p className="small-title-2">Doenças</p>
            <p className="body inv-text-color">{diseaseText}</p>
          </div>
        </div>

        <div className="card-row top-margin-xs">
          <div className="title-content-column">
            <p className="small-title-2">Doação de Orgãos</p>
            <p className="body inv-text-color">{(organDonation) ? organDonation : '-'}</p>
          </div>
        </div>
        </Link>
        
        <button className="card-footer-left" onClick={()=>{setCardFace(prev=>!prev)}}>
          <img className="" src={LeftArrow2}/>
          <p className="secondary-text inv-text-color">Dados SUS/Emergência</p>
        </button>
      </div>
    </div>
  )
  if (cardFace) {
    return front
  } else {
    return back
  }
}
