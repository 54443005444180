import React from 'react'
import { useParams, useLocation, useNavigate } from "react-router-dom"
import { useMediaQuery } from 'react-responsive'
import Return from '../components/Forum/Return'
import PageScroller from '../components/PageScroller'
import { PortalContext } from '../AuthenticatedUser'
import { putExamRequestToApi } from '../api'
import Attention from "../images/sun.svg"
import HighlightedMessage from '../components/HighlightedMessage'
import Button from '../components/Button'

export default function ExamPreview() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const {id} = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const { auth, cookies } = React.useContext(PortalContext)
  const [ exam, setExam ] = React.useState(null)

  async function loadExamFromAPI() {
    
    try {
      const body = {
        "id": id,
      }
      const data = await putExamRequestToApi(body, 'exam', auth, cookies) 
      setExam(data)

    } catch (err) {
      console.log("Could not authenticate user")
      navigate(`/health/${id}`, { state : { previousPath: location.pathname }, replace: true})
    }
  }
  
  async function loadSharedExamFromAPI() {
    
    try {
  
      const data = await putExamRequestToApi(
        {"id": id }, 
        'exam',
        {
          accessToken: cookies.get("sharedAccessToken"),
          refreshToken: cookies.get("sharedRefreshToken")
        },
        cookies) 

      setExam(data)

    } catch (err) {
      console.log("Could not authenticate user")
      navigate(`/sharedhealth`, { state : { previousPath: location.pathname }, replace: true})
    }
  }

  React.useEffect(() => {
    if (!cookies.get("shared")) {
      loadExamFromAPI()
    } else {
      loadSharedExamFromAPI()
    }
  }, [])

  React.useEffect(() => {
    if (!cookies.get("shared")) {
      loadExamFromAPI()
    } else {
      loadSharedExamFromAPI()
    }
  }, [])

  function downloadFile() {
    const url = window.URL.createObjectURL(new Blob([exam]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute(
      'download',
      location.state?.search.file,
    );
    link.download = location.state?.search.file || "exame"
    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

          //<button onClick={()=>downloadFile()}>Download do arquivo</button>
  return (
    (!exam) ? 
    <div className="icon-loading-middle-page">
      <img className="small-logo-nav loading-icon" 
          src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
      <p>Carregando página...</p> 
    </div>
    :
    <>
      <div className={(tabletBreakpoint) ? 'container-simple-tablet' : 'container-simple'}>
        <div className='top-margin-m'>
          <Return to={location.state?.search.previous ? location.state.search.previous : "/health"} backTo='Portal de Saúde'/>
        </div>
      </div>

      <div className="top-margin-m bottom-padding-m">
        <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>

        <div className="top-margin-l">
          {(location.state?.search.message) ?
          <HighlightedMessage icon={Attention} type="warning" header="Importante" 
            body={location.state?.search.message} /> : null }
        </div>
        <div className="editable-card editable-card-max-width top-margin-s bottom-padding-s">

          <div className="top-padding-s horizontal-padding-s">
            <p className="heading5 bottom-margin-s">{location.state?.search.title}</p>

            <div>
              {(location.state?.search?.file) ?
              <Button type="secondary bottom-margin-s" onClick={()=>{downloadFile()}}>
                Download
              </Button> : null
              }
            </div>

              <PageScroller 
                examTitle={location.state?.search.title}
                download={()=>downloadFile()}
                allowDownload={false}>
                {exam}
              </PageScroller>
              
          </div>

          </div>
        </div>
      </div> 

    </>
    
  )
}
