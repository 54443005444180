import React from 'react'
import { Link } from "react-router-dom"
import ArrowLeftIcon from "../../images/arrow-left.svg"

export default function Return({button, onClick, to, backTo, reloadDocument}) {
  
  return (
    (button) ?
      <div>
        <button className="return-link" onClick={onClick}>
          <img src={ArrowLeftIcon} alt="Arrow buttom to go back to previous page" />
          <p className="body">{(backTo) ? backTo : 'Voltar'}</p>
        </button>
      </div>
      :
      <div>
        <Link className="return-link" to={to} preventScrollReset={true} reloadDocument={reloadDocument}>
          <img src={ArrowLeftIcon} alt="Arrow buttom to go back to previous page" />
          <p className="body">{(backTo) ? backTo : 'Voltar'}</p>
        </Link>
      </div>
  )
}
