
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post91() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Olá, boa tarde pessoa. Uma pessoa da minha familia esta com colangiocarcinoma
(Cancer nas vias biliares), e está muito magro e não consegue comer. Ele vai passar pela primeira vez no oncologista semana que vem mas já está bem fraco. Alguém aqui com esse tipo de experiência?</title>
        <meta 
          name="description" 
          content="Olá, boa tarde pessoa. Uma pessoa da minha familia esta com colangiocarcinoma
(Cancer nas vias biliares), e está muito magro e não consegue comer. Ele vai passar pela primeira vez no oncologista semana que vem mas já está bem fraco. Alguém aqui com esse tipo de experiência?"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Elisa Orta &#183; 14/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Olá, boa tarde pessoa. Uma pessoa da minha familia esta com colangiocarcinoma<br/>(Cancer nas vias biliares), e está muito magro e não consegue comer. Ele vai passar pela primeira vez no oncologista semana que vem mas já está bem fraco. Alguém aqui com esse tipo de experiência?
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/91')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  4 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Olá, Elisa, boa tarde.<br/><br/>Sinto muito pela situação que sua família está enfrentando. O colangiocarcinoma pode ser bem desafiador, tanto para o paciente quanto para a família.<br/><br/>Enquanto vocês aguardam a consulta com o oncologista, é importante tentar manter seu familiar o mais confortável possível. Aqui estão algumas dicas que podem ajudar:<br/><br/>1. **Nutrição:** Como ele está tendo dificuldade para comer, alimentos líquidos e pastosos podem ser mais fáceis de ingerir, como sopas, caldos e sucos naturais. Existem também suplementos nutricionais líquidos que podem fornecer calorias e nutrientes essenciais. Seria bom consultar um nutricionista, que pode indicar o que é mais adequado.<br/><br/>2. **Hidratação:** Manter-se hidratado é fundamental. Água, chás de ervas e sucos suaves podem ajudar.<br/><br/>3. **Apoio Emocional:** Estar presente e oferecer apoio emocional pode fazer uma grande diferença. Às vezes, apenas ouvir e estar disponível pode ajudar muito.<br/><br/>Lembre-se de anotar todas as dúvidas que surgirem para perguntar ao oncologista na consulta. Eles vão fornecer orientações específicas e personalizadas para o caso do seu familiar.<br/><br/>Espero ter ajudado um pouco e desejo muita força para vocês nesse momento. Se tiver mais perguntas ou precisar conversar, estamos aqui para apoiar.<br/><br/>Abraço,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 14/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/91')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Dara Costa &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/49-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Vou começar fazer quimioterapia. <br/>Vocês sabem dizer com quantas sessões começa a cair o cabelo?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/49')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Bruna Almeida &#183; 16/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/112-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal boa tarde minha hemoglobina glicada deu 13.5, ta muito alta?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/112')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Tami Costa &#183; 22/07/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/3-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Compartilhando sua vida com uma doença crônica - recomendação de livro  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Há alguns meses, pesquisando livros sobre doenças crônicas, recebi a recomendação do livro Pain Woman Takes Your Keys, and Other Essays from a Nervous System (infelizmente sem versão em português ao meu conhecimento). Diferentemente do que estava procurando, que seria um livro de informações e fatos sobre doenças crônicas, estilo livro acadêmico, Pain Woman é um livro que relata de forma íntima e direta a experiência de desenvolver uma doença crônica, e se deparar que você vai dividir o resto de sua vida com ela. Que o resultado disso é um novo você.<br/><br/>A autora do livro, Sonya Huber, relata através de uma série de textos curtos (ou essays) sua experiência desde a aparição da doença artrite reumatóide, do seu desenvolvimento e de todo impacto que ela teve em sua vida. Ela descreve as dificuldades emocionais e sociais que isso causou, sua revolta e por fim o seu entendimento dessa nova companheira que agora era parte de si mesma.<br/><br/>O livro é bastante pessoal e nos faz conhecer Sonya e nos tornarmos de alguma forma seu amigo, da Sonya pré-artrite reumatóide e pós-artrite reumatóide. Bastante intenso e expressivo, é um dos melhores relatos que conheço do que é viver com uma doença crônica, e recomendo para todos que querem conhecer essa realidade, para lidar com a sua própria ou para melhor empatizar com alguém que está passando por isso a sua volta.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/3')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        Nenhum comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Andressa Barros &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/95-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Estou enjoada de comida. O que fazer pra não ter esse enjôo? Não estou conseguindo comer nada
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/95')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            12 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        3 comentários
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Joana Ling &#183; 06/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/19-diabetes" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Dúvida diabetes  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Pessoal bom dia!<br/><br/>Queria saber se diabetes tipo 2 pode dar câimbras e formigamentos?<br/><br/>Obrigado!!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/19')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
