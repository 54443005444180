import React from 'react'
import chevronLeft from "../images/chevron-left.svg"
import chevronRight from "../images/chevron-right.svg"

import { Document, Page } from 'react-pdf'

import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import { pdfjs } from 'react-pdf';
import Button from './Button'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

// this is necessary for older browsers that don't have Promise.withResolvers() defined
if (typeof Promise.withResolvers === 'undefined') {
  Promise.withResolvers = function () {
    let resolve, reject
    const promise = new Promise((res, rej) => {
      resolve = res
      reject = rej
    })
    return { promise, resolve, reject }
  }
}

export default function PageScroller({ examTitle, download, allowDownload=true, children }) {

  const [numPages, setNumPages] = React.useState()
  const [pageNumber, setPageNumber] = React.useState(1)
  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages)
  }
  function prevPage() {
    if (pageNumber > 1) {
      setPageNumber(prev => prev-1)
    }
  }
  function nextPage() {
    if (pageNumber < numPages) {
      setPageNumber(prev => prev+1)
    }
  }

  return (
    
    <div className="bottom-margin-s">
      { (children.type=='application/pdf') ?
        <>
        <div className="datepicker-top">
          <div className="month-selector">
            <button className="arrow" onClick={()=>{prevPage()}}><i class="material-icons">
              <img src={chevronLeft}/>
            </i></button>
            <span className="month-name">{`Página ${pageNumber} de ${numPages}`}</span>
            <button className="arrow" onClick={()=>{nextPage()}}>
              <img src={chevronRight}/>
            </button>
          </div>
        </div>

        <Document className="exam-preview" file={children} onLoadSuccess={onDocumentLoadSuccess}>
          <Page className="exam-preview" pageNumber={pageNumber}  renderTextLayer={false}/>
        </Document>
        </>
        :
        <>
          <img className="exam-preview" src={URL.createObjectURL(children)}/>
        </>
      }

      { (allowDownload) ? 
        <div className="top-margin-s">
        <Button type="secondary" onClick={()=>download()}>Fazer download</Button>
        </div> : null
      }
    </div>
  )
}
