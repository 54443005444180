
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post83() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoal. Preciso de ajuda. Quando tem moleza no corpo e vontade de dormir o dia todo é porque a diabetes está baixa?
Meu marido tem diabates, e as vezes ele dorme o dia todo.</title>
        <meta 
          name="description" 
          content="Oi pessoal. Preciso de ajuda. Quando tem moleza no corpo e vontade de dormir o dia todo é porque a diabetes está baixa?
Meu marido tem diabates, e as vezes ele dorme o dia todo."/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Claudia Coimbra &#183; 13/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoal. Preciso de ajuda. Quando tem moleza no corpo e vontade de dormir o dia todo é porque a diabetes está baixa?<br/>Meu marido tem diabates, e as vezes ele dorme o dia todo.
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/83')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  5 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi, Claudia!<br/><br/>Entendo sua preocupação com seu marido. A moleza no corpo e a vontade de dormir o dia todo podem ser sinais de várias coisas, inclusive de que a glicose está baixa (hipoglicemia) ou alta (hiperglicemia). No caso do seu marido, é muito importante verificar os níveis de glicose no sangue para entender o que está acontecendo.<br/><br/>Se vocês ainda não têm um equipamento de medição de glicose, seria bom conversar com o médico dele sobre a possibilidade de adquirir um. É essencial monitorar a glicemia regularmente, especialmente se ele estiver se sentindo mal.<br/><br/>Também recomendo que ele consulte o médico para avaliar esses sintomas. Só um profissional de saúde pode fazer um diagnóstico adequado e ajustar o tratamento se necessário.<br/><br/>Até lá, observe se além da moleza ele apresenta outros sintomas como tremores, suor frio, confusão mental, ou visão turva, que são sinais de hipoglicemia, ou muita sede, fome exagerada e urina frequente, que podem indicar hiperglicemia.<br/><br/>Espero ter ajudado, e estou à disposição para qualquer outra pergunta. Abraços para você e melhoras para seu marido!<br/><br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 13/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/83')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Rita Anton &#183; 12/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/79-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, sou hipertensa e hoje minha pressão diastólica não saiu do 10 ou 9 ....<br/>A sistólica fica entre 12 e 13 e a diastólica entre 9 e 10.<br/>Oque vocês acham que eu devo fazer?<br/>Já tomei o remédio da pressão as 18 horas tomo a cada 12 horas, mas tenho ansiedade e não estou nem conseguindo dormir porque a pressão não abaixa.<br/>Já tá me dando até dor de cabeça
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/79')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    João Nogueira &#183; 12/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/77-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Capsula de pata de vaca abaixa diabetes?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/77')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Elisa Orta &#183; 23/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/147-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Alguém tem alergia no inverno que coça muito fica irritada a pele? É mais as pernas
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/147')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Manuela Escobar &#183; 18/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/127-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Alguém sabe oque passar na ferida do pé diabético, pra tirar o mal cheiro? Lavar com oque?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/127')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Martina Coelho &#183; 13/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/86-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal<br/>Fui diagnosticada com câncer de mama há 1 semana, e estou sem chão, parece que o mundo perdeu o sentido…<br/>Confesso que minha vontade é só de ficar deitada na cama, no escuro, sozinha...<br/>Estou desde o dia do diagnóstico sem trabalhar, amanhã terei q voltar, mas só em pensar que terei q encarar os olhos das pessoas me olhando com dó, me já me dá uma ansiedade
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/86')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
