
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post30() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>O que toma para baixar o colesterol?</title>
        <meta 
          name="description" 
          content="O que toma para baixar o colesterol?"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Rita Anton &#183; 07/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            O que toma para baixar o colesterol?
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/30')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  1 pessoa achou útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi! Para ajudar a baixar o colesterol, além de seguir as orientações do seu médico, algumas pessoas fazem mudanças na dieta e no estilo de vida. Aqui vão algumas dicas que costumam ajudar:<br/><br/>1. **Alimentação saudável**: Consumir mais frutas, verduras, legumes, grãos integrais, e peixes. Evitar alimentos ricos em gorduras saturadas, como frituras e alimentos processados.<br/><br/>2. **Exercícios físicos**: Fazer atividades físicas regularmente, como caminhar, andar de bicicleta ou até dançar ajuda bastante.<br/><br/>3. **Parar de fumar**: Se você fuma, parar pode melhorar seus níveis de colesterol e trazer muitos outros benefícios para sua saúde.<br/><br/>4. **Reduzir o consumo de álcool**: Beber menos álcool também pode ajudar a manter o colesterol controlado.<br/><br/>Lembre-se de sempre seguir as recomendações do seu médico e, se ele achar necessário, ele poderá prescrever medicamentos específicos para o seu caso.<br/><br/>Tem mais alguma dúvida sobre o assunto? Estou aqui para ajudar!
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 07/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/30')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    J. Sandel &#183; 06/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/17-diabetes" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Diabetes - Café da manhã  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia sugestões de café da manhã por gentileza
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/17')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Carolina Mattos &#183; 11/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/72-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia pessoal. Estou triste por ser diagnosticado com diabetes tipo 2. Perdi muito peso e estou muito triste, mas tenho fé que vou conseguir superar. Peço ajuda dos amigos pois está sendo muito difícil
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/72')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    João Nogueira &#183; 12/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/77-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Capsula de pata de vaca abaixa diabetes?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/77')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Tomaz Silveira &#183; 13/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/81-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, já fazem suas semanas que meço minha glicose em jejum a após fazer 1 hora de musculação e 50 minutos de caminhada, e quando meço antes do almoço sem comer nada, ela aumenta entre 30 e 40 pontos.<br/><br/>Estou estranhando porque isso nunca aconteceu antes, sempre reduzia cerca de 20 a 30 pontos após os exercícios, mas semana passada de 210 em jejum foi pra 265 após os exercícios e hoje 235 foi  pra 262 após exercícios. <br/><br/>Oque vocês acham?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/81')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Luíza Castro &#183; 17/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/118-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá, o que aconselham a dar de café da manhã para uma criança de 10 anos com diabetes tipo 1?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/118')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
