import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Outlet, NavLink } from "react-router-dom"
import Return from '../components/Forum/Return'
import SearchBar from '../components/Forum/SearchBar'
import { ForumContext } from './ForumData'

export default function Saved({ myPosts=false }) {
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  const { notifications } = React.useContext(ForumContext)
  
  return (
    <div className="top-margin-s">
      <SearchBar tabletBreakpoint={tabletBreakpoint} notifications={notifications}/>
      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet forum-main" : "container-simple forum-main"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum'/>
        </div>
        <p className="heading3 bottom-margin-xs"><strong>
          {(!myPosts) ? "Posts salvos" : "Meus posts"}
        </strong></p>
        { (!myPosts) ? 
        <div className={(tabletBreakpoint) ? 
            "forum-main-horizontal-menu-tablet bottom-margin-s" : 
            "forum-main-horizontal-menu bottom-margin-s"}>
          <NavLink className={({isActive}) => isActive ? "post-link post-link-active" : "post-link"} to='.' end>Salvos para depois</NavLink>
          <NavLink className={({isActive}) => isActive ? "post-link post-link-active" : "post-link"} to='useful'>Úteis</NavLink>
          <NavLink className={({isActive}) => isActive ? "post-link post-link-active" : "post-link"} to='commented'>Comentados</NavLink>
        </div> : null
        }
      </div>

      <Outlet />
    </div>
  )
}
