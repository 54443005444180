import React from 'react'
import CommentIcon from '../../images/comment.svg'

export default function PostComments({ qtyComments }) {
  
  return (
    (qtyComments > 0) ?
      <div className="post-useful-info"> 
        <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
        <p className="secondary-text">
        { (qtyComments === 1) ? "1 comentário" : `${qtyComments} comentários` }
        </p>
      </div> 
    : <div className="post-useful-info"> 
        <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
        <p className="secondary-text">Nenhum comentário</p>
      </div>  
  )
}
