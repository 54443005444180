import React from 'react'
import { useNavigate } from "react-router-dom"
import Button from "../Button"
import { PortalContext } from '../../AuthenticatedUser'
import AuthContext from '../../context/AuthProvider'
import FormProgressBar from '../FormProgressBar';
import { postRequestToApi } from '../../api'

export default function PrescriptionForm({ id }) {

  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  const { health } = React.useContext(PortalContext);

  const [formData, setFormData] = React.useState({
    confirmed: false, created_at: "", data_extracted: true, emission_date: "", expiration_date: "", 
    external_filename: "" })

  const [medicationData, setMedicationData] = React.useState([])
  const [formPage, setFormPage] = React.useState(0)  
  const [status, setStatus] = React.useState('idle')

  const navigate = useNavigate()

  React.useEffect(()=>{
  
    const userId = +cookies.get("userId")

    if (health[userId]?.prescriptions) {
      if (health[userId].prescriptions[+id] !== undefined) {
        setFormData({
          confirmed: true, // it will be after the user press send
          created_at: health[userId].prescriptions[+id].created_at,
          data_extracted: health[userId].prescriptions[+id].data_extracted,
          expiration_date: health[userId].prescriptions[+id].expiration_date,
          emission_date: health[userId].prescriptions[+id].emission_date,
          external_filename: health[userId].prescriptions[+id].external_filename,
          professional: health[userId].prescriptions[+id].professional,
        })
        setMedicationData(health[userId].prescriptions[+id].medication)

      }
    }
  }, [health])

  async function submitPrescriptionData(body) {

    setStatus("submitting")
    try {

      console.log('body')
      console.log(body)
      const data = await postRequestToApi(
      body,
      "prescription", auth, cookies)

      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 
    } catch (err) {
      console.log("Error loading forum data")
      navigate('/health')
    } finally {
      setStatus("idle")
      // navigate to visualization page, where the new data from
      // the prescription should be loaded 
      navigate(`/prescription/${id}`,
        { state: { search: {
          message: "Os dados da prescrição foram confirmados, e seus medicamentos serão atualizados automaticamente.",
          previous: '/health',
          title: '',
          file: ''
        }} })
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (formPage < medicationData.length) {
      setFormPage(prev => prev+1)
    } else {

      const body = {
        id: +id, exam_data: { ...formData, medications: medicationData }
      }

      submitPrescriptionData(body)
    }
  }

  const backToPreviousPage = () => {
    setFormPage(prev => prev-1)
  }

  const handleChange = (e, index) => {
    const { name, value } = e.target
  
    if (index === null) {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }))
    } else {
      let copyMedicationData = medicationData
      copyMedicationData = copyMedicationData.map((item, i) => {
        if (i===index) {
          return {...item, [name]: value}
        } else {
          return item
        }
      })
      setMedicationData(copyMedicationData) 
    }
  }

  let form = null
  if (formPage === 0) {
    form = (
      <div className="right-margin-s">
      <p className="heading5 left-margin-s">Dados do Prontuário</p>
      <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs">
        <p className="body top-padding-xs">Profissional da saúde</p>
        <input
          className="input-text bottom-margin-xs"
          name="professional"
          onChange={(e) => handleChange(e, null)} 
          type="text"
          value={formData.professional}
        />

        <p className="body">Data de Emissão</p>
        <input
          className="input-text bottom-margin-xs"
          name="emission_date"
          onChange={(e) => handleChange(e, null)} 
          type="text"
          value={formData.emission_date}
        />
        
        <p className="body">Data de Validade</p>
        <input
          className="input-text bottom-margin-xs"
          name="expiration_date"
          onChange={(e) => handleChange(e, null)} 
          type="text"
          value={formData.expiration_date}
        />

        <div className="top-margin-xs">
          { (formPage > 0) ?
          <Button buttonType="button" 
              onClick={()=>backToPreviousPage()} type="secondary right-margin-s bottom-margin-xs">
              Anterior
          </Button> : null
          }
          <Button type="primary right-margin-s bottom-margin-s">
            {(formPage === medicationData.length + 1) ? "Confirmar" : "Próxima"}
          </Button>
        </div>
      </div>
      </div>
    )
  } else {
    let index = formPage - 1
    form = (
      <div className="right-margin-s">
      <p className="heading5 top-margin-xs left-padding-s">Medicamentos</p>
      <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs">
        <p className="body top-padding-xs">Nome</p>
        <input
          className="input-text bottom-margin-xs"
          name="name"
          onChange={(e) => handleChange(e, index)} 
          type="text"
          value={medicationData.at(index).name}
          placeholder=""
        />
        <p className="body">Observação</p>
        <textarea 
          className="comment-form" 
          name="observation" 
          onChange={(e) => handleChange(e, index)} 
          type="text" 
          value={medicationData.at(index).observation}
          placeholder=""
        />
        <p className="body top-margin-xs">Frequência</p>
        <input
          className="input-text bottom-margin-xs"
          name="frequency"
            onChange={(e) => handleChange(e, index)} 
          type="text"
          value={medicationData.at(index).frequency}
          placeholder=""
        />
        <p className="body">Quantidade</p>
        <input
          className="input-text bottom-margin-xs"
          name="quantity"
          onChange={(e) => handleChange(e, index)} 
          type="text"
          value={medicationData.at(index).quantity}
          placeholder=""
        />
        <p className="body">Horário</p>
        <input
          className="input-text bottom-margin-xs"
          name="time"
          onChange={(e) => handleChange(e, index)} 
          type="text"
          value={medicationData.at(index).time}
          placeholder=""
        />
        <p className="body">Iniciar uso</p>
        <input
          className="input-text bottom-margin-xs"
          name="begin_medication"
          onChange={(e) => handleChange(e, index)} 
          type="text"
          value={medicationData.at(index).begin_medication}
          placeholder=""
        />
        <p className="body">Uso até</p>
        <input
          className="input-text bottom-margin-xs"
          name="end_medication"
          onChange={(e) => handleChange(e, index)} 
          type="text"
          value={medicationData.at(index).end_medication}
          placeholder=""
        />

        <div className="top-margin-xs">
          { (formPage > 0) ?
          <Button buttonType="button" 
              onClick={()=>backToPreviousPage()} type="secondary right-margin-s bottom-margin-xs">
              Anterior
          </Button> : null
          }
          <Button type="primary right-margin-s bottom-margin-s">
            {(formPage === medicationData.length) ? 
                (status !== "idle") ? "Confirmando..." : "Confirmar" 
                : "Próxima"}
          </Button>
        </div>
      </div>
      </div>
    )
  }

  return (
    <div>
        <div className="horizontal-margin-s">
          <FormProgressBar label="Parte" current={formPage} total={medicationData.length + 1}/>
        </div>
        <form onSubmit={handleSubmit} className="login-form">
          {form}
        </form>
    </div>
  )
}
