import { curveMonotoneX, line } from 'd3'

export default function Marks({ data, xScale, yScale, xValue, yValue, circleRadius, valueCircleRadius }) {
  return (
  <g>
    <path className="time-line" d={
      line().x(d => xScale(xValue(d)))
            .y(d => yScale(yValue(d)))
            .curve(curveMonotoneX)(data)
      }
    />

    { data.map (d => (
        <>
          <circle className="marks-bars-blue" cx={xScale(xValue(d))} cy={yScale(yValue(d))} r={circleRadius}/>
          <circle className="circle-value-transparent" 
            cx={xScale(xValue(d))} 
            cy={yScale(yValue(d))-valueCircleRadius-10} 
            r={valueCircleRadius}/>
          <text className = {`text-plot-small`}
            x={(yValue(d)<100) ? 
              xScale(xValue(d))-valueCircleRadius*0.6 : 
              xScale(xValue(d))-valueCircleRadius*0.8 }
            y={yScale(yValue(d))-valueCircleRadius-6}

        >{yValue(d)}</text>
        </>
      ))
    }
  </g>
  )
}
