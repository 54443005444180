
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post128() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Com quantos anos começaram a tomar remédio de hipertensão?
Quantos remédios tomam por dia?
Tenho 25 anos, e não consigo aceitar que sou</title>
        <meta 
          name="description" 
          content="Com quantos anos começaram a tomar remédio de hipertensão?
Quantos remédios tomam por dia?
Tenho 25 anos, e não consigo aceitar que sou"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Fernando Pessoa &#183; 18/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Com quantos anos começaram a tomar remédio de hipertensão?<br/>Quantos remédios tomam por dia?<br/>Tenho 25 anos, e não consigo aceitar que sou
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/128')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  4 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Olá, Fernando.<br/><br/>Entendo como pode ser difícil aceitar um diagnóstico de pressão alta, especialmente sendo tão jovem. Cada pessoa tem uma experiência diferente com a hipertensão, então as respostas para suas perguntas podem variar bastante.<br/><br/>Descobrir a hipertensão cedo na vida pode ser preocupante, mas o lado positivo é que você pode começar a gerir melhor a sua saúde agora e prevenir problemas futuros. Algumas pessoas começam a tomar medicamentos para hipertensão na sua idade ou até mais jovens. A quantidade de medicamentos que alguém toma por dia também pode variar, dependendo da condição específica e das recomendações do médico.<br/><br/>É super importante seguir as orientações do seu médico, tomar os medicamentos prescritos e fazer mudanças no estilo de vida que podem ajudar a controlar a pressão alta. Praticar exercícios regularmente, ter uma alimentação saudável, reduzir o consumo de sal e evitar o estresse são algumas das coisas que podem fazer uma grande diferença.<br/><br/>Se precisar de mais alguma coisa ou apenas quiser desabafar, estou aqui para ajudar!<br/><br/>Cuide-se bem,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 18/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/128')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Fernanda Salles &#183; 23/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/149-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa tarde, pessoal!<br/>O que fazer pra aumentar as plaquetas?<br/>A minha irmã está em tratamento paliativo faz mais de um ano, e não está conseguindo aumentar as plaquetas de jeito nenhum, e ela precisa disso para poder tomar os remédios da quimioterapia.<br/>Ela já recebeu transfusão de sangue, está tomando ferro há quatro dias, comendo feijão, beterraba e bebendo água de coco, mas sem resultados.<br/>Alguma ideia?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/149')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    T. Estudante &#183; 24/07/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/12-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Frutas boas para quem tem diabetes  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Eu gosto muito de comer frutas, mas as vezes fico um pouco receoso sobre quais devo comer e quais podem me prejudicar devido a diabetes.<br/><br/>Vi esse vídeo muito bom do Dr. Roberto Yano explicando sobre isso, e compartilho aqui:<br/><br/>https://www.youtube.com/watch?v=qHvFfwOT8jw<br/><br/>Vale a pena assistir o vídeo, mas aqui está a lista de frutas que ele recomenda:<br/><br/>1) Abacate.<br/><br/>2) Pera.<br/><br/>3) Maçã.<br/><br/>4) Laranja (ideal é comer a fruta inteira, não só tomar o suco).<br/><br/>5) Cereja.<br/><br/>6) Morango.<br/><br/>7) Kiwi.<br/><br/>8 Pêssego.<br/><br/>Espero que essa lista ajude!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/12')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Dara Costa &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/34-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal! Me falaram que quem tem colesterol alto não pode comer raízes, como batata doce, mandioca, inhame.<br/>É verdade que não pode ?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/34')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marcos Rosebrock &#183; 16/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/110-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Uma dúvida: Pólipos nos pulmões, o maior sendo 8 milímetros. Pode ser metástase?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/110')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariana Zandona &#183; 20/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/137-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Pessoal, eu sou diabética tipo 2 e estou tendo pico, e estou ficando desesperada. A glicose fica entre 250 e 300. Alguém sabe me informar o que eu faço?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/137')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
