import React from 'react';
import Button from './Button'

export default function ProductSection({title, text, img, breakpoint, buttonText, to}) {

  return ( 
    <>
        <h1 className="intra-page-title">{title}</h1>
        <p className="body vertical-margin-s">{text}</p>
        {(to) ?
        <Button type="secondary bottom-margin-s" link={true} to={to}>{buttonText}</Button> : null }
        <img className={ breakpoint ? 
          "product-screenshot img-larger bottom-margin-xl" : "product-screenshot bottom-margin-xl"} 
          src={require(`../images/${img}`)} alt="Screenshot demonstrating functionality of the software"/>
    </>)
}
