import React from 'react'
import { useNavigate } from "react-router-dom"
import Button from '../components/Button'
import { resetPassword } from '../api'
import HighlightedMessage from './HighlightedMessage';
import Attention from "../images/sun.svg"

export default function RedefinePassword() {

  const userRef = React.useRef();

  const navigate = useNavigate()

  const [loginFormData, setLoginFormData] = React.useState({
    email: ""
  })

  const [status, setStatus] = React.useState("idle")
  const [error, setError] = React.useState(null)
  const [apiData, setApiData] = React.useState(null)

  React.useEffect(() => {
    userRef.current.focus()
  }, [])

  React.useEffect(()=>{
    console.log(apiData)
    if (apiData) {
      navigate("/loginrequired", {state: {search: "password"}})
    }
  }, [apiData])

  const handleSubmit = (e) => {
    e.preventDefault()
    setError(null)
    setApiData(null)

    async function tryTalkingToBackend(loginFormData) {
      try {
        setStatus("submitting")
        const data = await resetPassword(loginFormData)
        setApiData(data)
      } catch(err) {
        setError(err.message)
      } finally {         
        setStatus("idle")
      }
    }

    if (loginFormData.email === "") {
      setError("Preencha seu e-mail");
    } else {
      tryTalkingToBackend(loginFormData)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setLoginFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  return (
    <div className="login-container horizontal-padding-l">

      {(error) ?
        <div className="top-padding-xs">
          <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} />  
        </div>
        : null }

      <form onSubmit={handleSubmit} className="login-form">
        <p className="body top-padding-s">E-mail</p>
        <input
          className="input-text bottom-margin-xs"
          ref={userRef}
          name="email"
          onChange={handleChange}
          type="email"
          placeholder="Digite seu e-mail"
          value={loginFormData.email}
        />
        
        <div>
          <Button type="primary bottom-margin-l">
            {(status==="idle")? "Enviar nova senha" : "Verificando..."}
          </Button>
        </div>
      </form>
    </div>
  )
}
