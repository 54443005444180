import React from 'react'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../context/AuthProvider'
import { PortalContext } from '../AuthenticatedUser'
import HealthCard from '../components/Health/HealthCard'

export default function HealthCardPreview() {

  const { cookies } = React.useContext(AuthContext);
  const { health, loadHealthDataFromAPI } = React.useContext(PortalContext);
  
  const [userHealth, setUserHealth] = React.useState({
    name: null,
    dateOfBirth: null,
    sex: null,
    bloodType: null,
    susNumber: null,
    insureanceName: null,
    insureanceNumber: null,
    allergies: null,
    organDonation: null,
    diseases: null
  })
  const navigate = useNavigate()
  
  const id = +cookies.get("userId")
  
  React.useEffect(() => {

    if (!cookies.get("userId")) {
      cookies.set('pageOfInterest', '/health', { path: '/' })
      navigate("/family")
    } else {
      const id = +cookies.get("userId")

      if (!health[id]) {
      //window.scrollTo(0, 0)
        loadHealthDataFromAPI(+cookies.get("userId"))
      }
    }
  }, []);

  React.useEffect(() => {

    if (health[id]) {
      setUserHealth({
        name: `${health[id].first_name} ${health[id].last_name}`,
        dateOfBirth: health[id].birthdate,
        sex: health[id].sex,
        bloodType: health[id].blood_type,
        susNumber: health[id].sus_number,
        insureanceName: (health[id].insurance && health[id].insurance.length > 0) ? health[id].insurance.at(-1).name : null,
        insureanceNumber: (health[id].insurance && health[id].insurance.length > 0) ? health[id].insurance.at(-1).number : null,
        allergies: health[id].allergy,
        organDonation: health[id].organ_donation,
        diseases: health[id].disease,
        emergencyContact: health[id].emergency_contact
      })
    }
  }, [health])

  console.log(health)
  //console.log(userHealth)
  if (!userHealth.name) {
    return (
      <p>Carregando...</p>
    )
  }

  return (
    <HealthCard
      bloodType={userHealth.bloodType}
      name={userHealth.name}
      dateOfBirth={userHealth.dateOfBirth}
      sex={userHealth.sex}
      susNumber={userHealth.susNumber}
      insuranceName={userHealth.insureanceName}
      insuranceNumber={userHealth.insureanceNumber}
      allergies={userHealth.allergies}
      organDonation={userHealth.organDonation}
      diseases={userHealth.diseases}
      emergencyContact={userHealth.emergencyContact}
    />
  )
}
