import React from 'react'
import { useNavigate } from "react-router-dom"
import Author from './Author'
import { ForumContext } from '../../forum/ForumData'
import AuthContext from '../../context/AuthProvider'
import { deleteDataFromApi } from '../../api'
import Button from '../Button'

export default function CommentaryFeed({ comment, onDelete, deleteStatus }) {

  //const { loadForumData } = React.useContext(ForumContext)
  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  //const [status, setStatus] = React.useState("idle")
  const [breakComment, setBreakComment] = React.useState(false)
  const [breakCommentButton, setBreakCommentButton] = React.useState(false)

  //const navigate = useNavigate()

  //async function deletePost() {
  //  setStatus("submitting")
  //  try {
  //    const data = await deleteDataFromApi({ 
  //      id: +cookies.get("userId"),
  //      comment_id: +comment.id 
  //    }, 'forum/comment', auth, cookies)
  //    setAuth(prev => ({
  //      ...prev, 
  //      accessToken: data.tokens.accessToken,
  //      refreshToken: data.tokens.refreshToken
  //    })) 
  //  } catch (err) {
  //    console.log("Error loading forum data")
  //    navigate('/loginrequired')
  //  } finally {
  //    setStatus("idle")
  //   console.log("comment deleted")
  //    loadForumData(true) // true: user is loggedin
  //    window.location.reload();
  //  }
  //}

  function handleDelete() {
    //deletePost()
    onDelete(comment.id)
  }

  React.useEffect(()=>{
    if (comment.content.length > 400) {
      setBreakComment(true)
      setBreakCommentButton(true)
    }
  }, [])

  function breakCommentButtonClicked() {
    setBreakCommentButton(false)
    setBreakComment(false)
  }

  return (
    <div>
      <div className="comment-background">
        <p className="body comment">{(breakComment) ? `${comment.content.slice(0,400)}...` : comment.content }</p>
        {(breakCommentButton) ? 
          <Button type="tertiary comment-background left-margin-xs bottom-margin-xs" onClick={()=>breakCommentButtonClicked()}>
            Continuar lendo
          </Button> : null}
      </div>

      <div className="post-stats">
      <Author>{comment}</Author>
      {(cookies.get("userId") === comment.author && !(comment.notRemove)) ? 
        <Button type="tertiary btn-smaller bottom-margin-xs" onClick={()=>handleDelete()}>
            {(deleteStatus === "idle") ? "Excluir comentário" : "Excluindo..."}
        </Button> : null }
      </div>
    </div>
  )
}
