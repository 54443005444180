import React from 'react';

export default function HighlightedMessage({type, icon, header, body}) {

  return (
    <div className={type === "warning" ? 
      "florence-warning padding-s top-margin-s bottom-margin-xs" :
      "florence-message padding-s top-margin-s bottom-margin-xs"}>
      <div>
          <img className="menu-icon" src={icon}/>
          <div className="top-margin-xs">{header}</div>
      </div>
      <p className="secondary-text top-margin-xs text-wrapping">
        {body}
      </p>
    </div>
  )
}
