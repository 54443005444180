import React from 'react'
import { useMediaQuery } from 'react-responsive'
import PostThumbnail from '../components/Forum/PostThumbnail'
import { ForumContext } from './ForumData'


export default function SavedForLater({type}) {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const { loadUserForumData, authoredData, savedData, usefulData, commentedData, loadingUserData, hasMoreUserData} = React.useContext(ForumContext)

  const [pageNumber, setPageNumber] = React.useState(0)

  const observer = React.useRef()
  const lastPostElementRef = React.useCallback(node =>
    {
      if (loadingUserData) return
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMoreUserData) {
          setPageNumber(prevPageNumber => prevPageNumber + 1)
          console.log('visible!!!')
        }
      })
      if (node) observer.current.observe(node)

    }, [loadingUserData, hasMoreUserData])


  React.useEffect(() => {
    console.log('requesting data')
    console.log(type, pageNumber)
    loadUserForumData(type, pageNumber)
  }, [type, pageNumber])


  //const posts = forumData.posts.filter( post => post[type])
  console.log(hasMoreUserData)

  const visibleItems = []
  const postsToRender = []
  
  let thumbnails = null
  if (type === 'myposts' && authoredData) {

    authoredData.posts.forEach(post => {
      if (visibleItems.includes(post.id)) {
        return null
      } else {
        visibleItems.push(post.id)
        postsToRender.push(post)
      }
    })

    if (postsToRender.length === 0 && loadingUserData) {
      return (
        <div className="icon-loading-partial top-margin-m">
          <img className="small-logo-nav loading-icon" 
              src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
          <p>Carregando post...</p> 
        </div>
      )
    }

    thumbnails = postsToRender.map((post, index) => {
        if (postsToRender.length === index + 1) {
          return (
            <>
            <div ref={lastPostElementRef} key={index}>
              <PostThumbnail key={index} id={post.id} post={post}/>
            </div>
            {(loadingUserData) ? 
              <div className="icon-loading-partial top-margin-m">
                <img className="small-logo-nav loading-icon" 
                    src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
                <p>Carregando post...</p> 
              </div>
              : null
            }
            </>
          )
        } else {
          return <PostThumbnail key={index} id={post.id} post={post}/>
        }
      }
    )
  } else if (type === 'saved_post' && savedData) {
    savedData.posts.forEach(post => {
      if (visibleItems.includes(post.id)) {
        return null
      } else {
        visibleItems.push(post.id)
        postsToRender.push(post)
      }
    })

    if (postsToRender.length === 0 && loadingUserData) {
      return (
        <div className="icon-loading-partial top-margin-m">
          <img className="small-logo-nav loading-icon" 
              src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
          <p>Carregando post...</p> 
        </div>
      )
    }
    thumbnails = postsToRender.map((post, index) => {
        if (postsToRender.length === index + 1) {
          return (
            <>
            <div ref={lastPostElementRef} key={index}>
              <PostThumbnail key={index} id={post.id} post={post}/>
            </div>
            {(loadingUserData) ? 
              <div className="icon-loading-partial top-margin-m">
                <img className="small-logo-nav loading-icon" 
                    src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
                <p>Carregando post...</p> 
              </div>
              : null
            }
            </>
          )
        } else {
          return <PostThumbnail key={index} id={post.id} post={post}/>
        }
      }
    )

  } else if (type === 'found_useful' && usefulData) {

    usefulData.posts.forEach(post => {
      if (visibleItems.includes(post.id)) {
        return null
      } else {
        visibleItems.push(post.id)
        postsToRender.push(post)
      }
    })

    if (postsToRender.length === 0 && loadingUserData) {
      return (
        <div className="icon-loading-partial top-margin-m">
          <img className="small-logo-nav loading-icon" 
              src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
          <p>Carregando post...</p> 
        </div>
      )
    }
    thumbnails = postsToRender.map((post, index) => {
        if (postsToRender.length === index + 1) {
          return (
            <>
            <div ref={lastPostElementRef} key={index}>
              <PostThumbnail key={index} id={post.id} post={post}/>
            </div>
            {(loadingUserData) ? 
              <div className="icon-loading-partial top-margin-m">
                <img className="small-logo-nav loading-icon" 
                    src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
                <p>Carregando post...</p> 
              </div>
              : null
            }
            </>
          )
        } else {
          return <PostThumbnail key={index} id={post.id} post={post}/>
        }
      }
    )
  } else if (type === 'commented' && commentedData) {
    commentedData.posts.forEach(post => {
      if (visibleItems.includes(post.id)) {
        return null
      } else {
        visibleItems.push(post.id)
        postsToRender.push(post)
      }
    })

    if (postsToRender.length === 0 && loadingUserData) {
      return (
        <div className="icon-loading-partial top-margin-m">
          <img className="small-logo-nav loading-icon" 
              src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
          <p>Carregando post...</p> 
        </div>
      )
    }
    thumbnails = postsToRender.map((post, index) => {
        if (postsToRender.length === index + 1) {
          return (
            <>
            <div ref={lastPostElementRef} key={index}>
              <PostThumbnail key={index} id={post.id} post={post}/>
            </div>
            {(loadingUserData) ? 
              <div className="icon-loading-partial top-margin-m">
                <img className="small-logo-nav loading-icon" 
                    src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
                <p>Carregando post...</p> 
              </div>
              : null
            }
            </>
          )
        } else {
          return <PostThumbnail key={index} id={post.id} post={post}/>
        }
      }
    )
  }

  return (
      (!thumbnails || thumbnails.length > 0) ? 
        thumbnails :
        <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
          <p className="body secondary-color top-margin-xs">Nenhum post por encontrado</p>
        </div>
  )
}
