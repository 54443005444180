
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post76() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoa. Uma pergunta por curiosidade. Qual seria a diferença entre diabetes tipo 1 e tipo 2?
</title>
        <meta 
          name="description" 
          content="Oi pessoa. Uma pergunta por curiosidade. Qual seria a diferença entre diabetes tipo 1 e tipo 2?
"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Neila Pereira &#183; 12/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoa. Uma pergunta por curiosidade. Qual seria a diferença entre diabetes tipo 1 e tipo 2?<br/>
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/76')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  3 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi, Neila! Tudo bem?<br/><br/>Essa é uma pergunta super comum e é ótimo que você queira entender melhor. Vamos lá:<br/><br/>**Diabetes Tipo 1:**<br/>- Geralmente aparece na infância ou adolescência, mas pode ser diagnosticada em adultos também.<br/>- É uma condição autoimune, onde o corpo "ataca" as células do pâncreas que produzem insulina, então a pessoa quase não produz insulina.<br/>- Quem tem diabetes tipo 1 precisa tomar insulina diariamente, pois não consegue produzir a quantidade necessária.<br/><br/>**Diabetes Tipo 2:**<br/>- É mais comum em adultos, mas com o aumento da obesidade infantil, tem aparecido cada vez mais em jovens.<br/>- Usualmente está ligada ao estilo de vida, como peso corporal, dieta e atividades físicas, mas também pode haver fatores genéticos.<br/>- O corpo até produz insulina, mas não a usa de forma correta (resistência à insulina) ou não produz o suficiente.<br/>- O tratamento pode incluir mudanças na dieta, exercícios físicos, medicamentos por via oral e, em alguns casos, insulina.<br/><br/>Ambos os tipos necessitam de acompanhamento médico e um cuidado específico com a alimentação e o estilo de vida. Mas, com as devidas precauções e tratamentos, é possível ter uma vida saudável e ativa.<br/><br/>Qualquer dúvida ou se você precisar de mais alguma informação, estou por aqui!<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 12/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/76')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Amanda Flores &#183; 19/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/129-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Retirei o nodulo da mama faz um mês mais ou menos, e ontem saiu o resultado da cirurgia que 4 linfonodos já tinham sido atingidos. Alguém pode me ajudar no que isso significa?<br/><br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/129')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Walter Medeiros &#183; 24/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/151-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá, que vitamina ou suplemento o pré-diabetico pode tomar para engordar?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/151')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Neila Gabler &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/98-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia. Medi a minha glicemia e me assustei, dei 405.<br/>Me digam uma receita Detox para baixar a glicemia rápido?<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/98')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            7 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Neila Gabler &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/62-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Alguém aqui não teve efeito colateral com a sinvastatina? Minha médica me deu receita para 1 ano e disse que terei que tomar o resto da vida, pois tenho histórico de colesterol alto na família. O problema é que só leio coisas ruins sobre esse remédio
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/62')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Camila da Silveira &#183; 17/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/115-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi gente, boa noite !<br/>Minha sobrinha de 12 anos foi diagnosticada diabetes tipo 1.<br/>Hoje ela começou a ficar gripada e não sabemos qual xarope ela pode tomar.<br/>Alguém pode me ajudar <br/>Ainda não conseguimos as consultas pelo SUS.<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/115')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
