
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post149() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Boa tarde, pessoal!
O que fazer pra aumentar as plaquetas?
A minha irmã está em tratamento paliativo faz mais de um ano, e não está conseguindo aumentar as plaquetas de jeito nenhum, e ela precisa disso para poder tomar os remédios da quimioterapia.
Ela já recebeu transfusão de sangue, está tomando ferro há quatro dias, comendo feijão, beterraba e bebendo água de coco, mas sem resultados.
Alguma ideia?</title>
        <meta 
          name="description" 
          content="Boa tarde, pessoal!
O que fazer pra aumentar as plaquetas?
A minha irmã está em tratamento paliativo faz mais de um ano, e não está conseguindo aumentar as plaquetas de jeito nenhum, e ela precisa disso para poder tomar os remédios da quimioterapia.
Ela já recebeu transfusão de sangue, está tomando ferro há quatro dias, comendo feijão, beterraba e bebendo água de coco, mas sem resultados.
Alguma ideia?"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Fernanda Salles &#183; 23/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Boa tarde, pessoal!<br/>O que fazer pra aumentar as plaquetas?<br/>A minha irmã está em tratamento paliativo faz mais de um ano, e não está conseguindo aumentar as plaquetas de jeito nenhum, e ela precisa disso para poder tomar os remédios da quimioterapia.<br/>Ela já recebeu transfusão de sangue, está tomando ferro há quatro dias, comendo feijão, beterraba e bebendo água de coco, mas sem resultados.<br/>Alguma ideia?
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/149')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  1 pessoa achou útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Fernanda!<br/><br/>Primeiro de tudo, sinto muito que a sua irmã esteja passando por isso. Imagino como deve ser difícil para você e para toda a família.<br/><br/>A questão das plaquetas pode ser bem complicada mesmo. O fato de ela já estar tomando ferro, comendo alimentos ricos em ferro como feijão e beterraba, e bebendo água de coco é ótimo. Existe também a possibilidade de algumas outras ações ajudarem:<br/><br/>1. **Proteínas**: Alimentos ricos em proteínas, como ovos, carnes magras e peixes, podem ajudar na produção de células sanguíneas.<br/>2. **Folato**: Esse é um tipo de vitamina B que é importante para a produção de plaquetas. Alimentos como espinafre, brócolis e abacate são boas fontes.<br/>3. **Evitar álcool**: O consumo de álcool pode reduzir o número de plaquetas, então é importante evitar, caso ela consuma.<br/>4. **Hidratação**: Continuar bebendo bastante água é crucial para manter o corpo funcionando bem.<br/><br/>Mas, é super importante conversar com o médico responsável pelo tratamento dela antes de fazer qualquer mudança na dieta ou introduzir novos suplementos. Eles podem fornecer orientações específicas baseadas na condição dela e no tratamento que está recebendo.<br/><br/>Se você sentir que as sugestões aqui não estão ajudando ou se tiver qualquer preocupação maior, não hesite em falar com o médico. Eles têm uma visão completa do quadro clínico e poderão oferecer as melhores orientações.<br/><br/>Estamos aqui na torcida para que sua irmã melhore logo.<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 23/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/149')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Gabrielli Machado &#183; 18/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/124-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia!<br/>Meu exame de Histoquímico tem o seguinte resultado:<br/>Hers 2 negativo escore 0<br/>Mas alguém teve esse resultado e sabe me falar o significado?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/124')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Neila Gabler &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/98-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia. Medi a minha glicemia e me assustei, dei 405.<br/>Me digam uma receita Detox para baixar a glicemia rápido?<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/98')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            7 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marta Cortês &#183; 17/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/117-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    É verdade quem tem diabetes tem tendência a ter miomas?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/117')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Elisa Orta &#183; 23/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/147-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Alguém tem alergia no inverno que coça muito fica irritada a pele? É mais as pernas
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/147')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mario César &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/97-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Estou bastante triste, descobri que estou com catarata por conta da diabete segundo o oftalmologista. Não consigo entender muito bem, mantenho a glicose controlada, jejum 105, prandial deu até menos, 100 glicada 6.4. Estou preocupado.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/97')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            6 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
