import React from 'react';
import { useMediaQuery } from 'react-responsive'
import DataAccessBlock from '../components/DataAccessBlock';
import Header from "../components/Header"

export default function AccessShared() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const sectionTitle = <h1 className="florence-title top-margin-xl">Acessar dados compartilhados</h1>

  return (
    <>
      <div className={(tabletBreakpoint) ? "container-simple-tablet":"container-simple"}>
        <Header tabletBreakpoint={tabletBreakpoint} menuShow={true}/>
      </div>

      <div className="container-simple">

        <section>
          { sectionTitle }
        </section>
        
      </div>

      <div className="top-margin-m bottom-margin-l">
        <div className={(tabletBreakpoint) ? 
          "colorful-background-tablet vertical-padding-m" : "colorful-background vertical-padding-m"}>
          <div className="container-simple">
            <DataAccessBlock />
          </div>
        </div>
      </div>
    </>
  )
}
