
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post12() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Dúvida saúde - geral - Frutas boas para quem tem diabetes</title>
        <meta 
          name="description" 
          content="Eu gosto muito de comer frutas, mas as vezes fico um pouco receoso sobre quais devo comer e quais podem me prejudicar devido a diabetes.

Vi esse vídeo muito bom do Dr. Roberto Yano explicando sobre isso, e compartilho aqui:

https://www.youtube.com/watch?v=qHvFfwOT8jw

Vale a pena assistir o vídeo, mas aqui está a lista de frutas que ele recomenda:

1) Abacate.

2) Pera.

3) Maçã.

4) Laranja (ideal é comer a fruta inteira, não só tomar o suco).

5) Cereja.

6) Morango.

7) Kiwi.

8 Pêssego.

Espero que essa lista ajude!"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            T. Estudante &#183; 24/07/2024
          </p>
        </div>
        <p className="heading4 bottom-margin-s">Frutas boas para quem tem diabetes</p>


        <p className="body content bottom-padding-s">
            Eu gosto muito de comer frutas, mas as vezes fico um pouco receoso sobre quais devo comer e quais podem me prejudicar devido a diabetes.<br/><br/>Vi esse vídeo muito bom do Dr. Roberto Yano explicando sobre isso, e compartilho aqui:<br/><br/>https://www.youtube.com/watch?v=qHvFfwOT8jw<br/><br/>Vale a pena assistir o vídeo, mas aqui está a lista de frutas que ele recomenda:<br/><br/>1) Abacate.<br/><br/>2) Pera.<br/><br/>3) Maçã.<br/><br/>4) Laranja (ideal é comer a fruta inteira, não só tomar o suco).<br/><br/>5) Cereja.<br/><br/>6) Morango.<br/><br/>7) Kiwi.<br/><br/>8 Pêssego.<br/><br/>Espero que essa lista ajude!
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/12')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  3 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi!<br/><br/>Que bacana você compartilhar essa lista e o vídeo do Dr. Roberto Yano. É sempre bom ter informações valiosas para ajudar no manejo do diabetes. Realmente, quando a gente tem diabetes, é importante prestar atenção às frutas que consumimos, pois algumas podem ter mais açúcar do que outras.<br/><br/>A lista que você passou é ótima! Essas frutas geralmente têm um índice glicêmico mais baixo e são cheias de nutrientes que fazem bem para a saúde. Só lembrando que, mesmo com essas frutas, é importante consumir com moderação e sempre monitorar como seu corpo reage.<br/><br/>Gostaria de adicionar algumas dicas para você:<br/><br/>1. **Abacate**: Rico em gorduras boas, ajuda a controlar os níveis de glicose.<br/>2. **Pera e Maçã**: Ótimas fontes de fibras, ajudam a manter a saciedade.<br/>3. **Laranja**: Excelente, mas como você disse, comer a fruta inteira é sempre melhor que tomar o suco.<br/>4. **Morango e Kiwi**: Excelentes antioxidantes, além de deliciosos.<br/>5. **Cereja e Pêssego**: Além de saborosos, são cheios de vitaminas importantes.<br/><br/>Lembre-se sempre de consultar seu nutricionista ou endocrinologista para adaptar as dicas ao seu caso específico.<br/><br/>Espero que essas informações ajudem você a ficar mais tranquilo em relação às frutas!<br/><br/>Qualquer dúvida, estou aqui para ajudar!<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 06/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/12')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    L. Carrol &#183; 23/07/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/7-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Colesterol bom e colesterol ruim  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Uma das primeiras coisas que descobri depois do diagnóstico de colesterol alto foi que existem dois tipos de colesterol: o colesterol ruim (LDL) e o colesterol do bem (HDL). O colesterol total é a soma desses dois, e colesterol alto é ruim porque quando ele está alto, a maior parte dele é composta do colesterol ruim (LDL). <br/><br/>Importante: Pessoas com colesterol alto têm cerca de o dobro do risco de desenvolverem uma doença cardíaca em comparação com pessoas com níveis de colesterol normais.<br/><br/>Assim, para melhorar o colesterol, o que precisamos fazer é tentar REDUZIR nosso colesterol ruim (LDL) enquanto AUMENTAMOS nosso colesterol bom (HDL). <br/><br/>A primeira causa de termos alto colesterol ruim (LDL) é comer comidas que têm gorduras ruins para nós (gorduras trans e gorduras saturadas). O colesterol bom (HDL) ajuda a combater e reduzir o colesterol ruim (LDL), e quantidade muito baixa dele é uma causa de doença cardíaca.<br/><br/>Os três fatores SOB NOSSO controle que influenciam nosso colesterol (aumentam o bom, reduzem o ruim) são:<br/><br/>1) Nosso peso: Obesidade pode aumentar nosso colesterol ruim (LDL) até 50%, ao mesmo tempo que pode reduzir nosso colesterol bom (HDL) em 20%.<br/><br/>2) O que nós comemos: Tem muita coisa para falar aqui, mas o importante é comer saudável: frutas, verduras, fibras, peixes. Evitar comida processada, frituras, carne vermelha.<br/><br/>3) Exercício físico: Exercício é o último fator porque ele influencia os outros dois: pessoas que se exercitam tendem a comer melhor e pesar menos. Exercício também é chave para fortalecer o músculo do coração.<br/><br/>Aprender sobre esses pontos me ajudou muito no início da minha melhora de saúde, e espero que possa servir para vocês também.<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/7')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        Nenhum comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Rita Anton &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/60-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, me deem um conselho. Quantas vezes ao dias vocês medem a glicemia? Vocês chegam a medir com maior frequência quando sentem dores ou formigamento em alguma parte do corpo, pra ver se tem a ver com a diabetes?<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/60')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Carla Chagas &#183; 14/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/89-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá, eu gostaria de saber mais sobre neuropatia
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/89')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Fernanda Aguiar &#183; 19/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/132-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi poderia me explicar como faço a contagem de carboidratos
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/132')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Dara Costa &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/34-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal! Me falaram que quem tem colesterol alto não pode comer raízes, como batata doce, mandioca, inhame.<br/>É verdade que não pode ?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/34')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
