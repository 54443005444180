
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post133() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Algum chá para pressão alta</title>
        <meta 
          name="description" 
          content="Algum chá para pressão alta"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Mariano Oliveira &#183; 19/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Algum chá para pressão alta
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/133')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  4 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Olá, Mariano!<br/><br/>É ótimo ver que você está buscando formas naturais para ajudar a controlar a pressão alta. Existem alguns chás que podem ajudar a relaxar e, consequentemente, podem contribuir para a redução da pressão arterial. Exemplos desses chás são o chá de hibisco e o chá de camomila.<br/><br/>No entanto, é muito importante lembrar que esses chás não substituem o tratamento prescrito pelo seu médico. Eles podem ser um complemento, mas sempre converse com seu médico antes de incluir qualquer novo chá ou suplemento na sua rotina, principalmente se você já está tomando medicamentos para a pressão alta.<br/><br/>Além dos chás, manter uma dieta balanceada, praticar exercícios regulares e reduzir o consumo de sal podem fazer uma grande diferença na gestão da pressão alta.<br/><br/>Se precisar de mais alguma coisa ou tiver outra dúvida, estou aqui para ajudar!<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 19/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/133')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    L. Carrol &#183; 24/07/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/13-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Colesterol Alto: Gorduras boas e ruins  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Na minha trajetória desde que descobri o colesterol alto 3 mudanças foram importantes: perder peso, melhorar minha alimentação e começar a fazer exercício físico. Cada uma delas ajuda a sustentar as outras e a melhorar minha saúde.<br/><br/>Sobre alimentação saudável, em particular, aprendi sobre gorduras boas e ruins para nós.<br/><br/>Gorduras boas: São gorduras mono-saturadas, poli-saturadas, omega-3 e omega-6. Ela diminuem o colesterol ruim (LDL) e aumentam o colesterol bom (HDL). A gente consegue consumir elas em alimentos como nozes, abacates e peixes. Essas são super importantes de comer!<br/><br/>Gorduras para evitar: gordura trans, colesterol e gorduras saturadas.<br/><br/>1) Gordura trans: É a gordura mais perigosa que consumimos. É usada como preservativo para longo tempo de vida útil na prateleira. Deve ser evitada completamente.<br/><br/>2_ Colesterol: Gema de ovo é a principal fonte. Conseguimos evitar comendo somente a clara do ovo.<br/><br/>3) Gordura saturada: Gordura encontrada mais facilmente nas comidas que consumimos. Evitar ao máximo. Ideal buscar comidas com 0g a 0.5g dessa gordura.<br/><br/>O ideal e o que eu tento fazer é evitar e substituir as gorduras ruins por gorduras boas: frutas, vegetais, abacate, nozes, feijão, lentilha e peixe são excelentes opções. É importante utilizar também azeite de oliva ao invés de outros óleos comuns.<br/><br/>Adicionalmente, aprendi os benefícios de evitar carne vermelha, carnes processadas, queijos, manteigas, comidas fritas e que contém muita gordura saturada.<br/><br/>Espero que esse conhecimento seja útil para vocês assim como é para mim!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/13')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Danna Rosa &#183; 06/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/20-diabetes" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Diabetes  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Duas horas após o almoço
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/20')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Joceli Ferreira &#183; 21/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/143-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa noite pessoal. Meu irmão tá com câncer na coluna e no estômago já avançado e perdeu muito peso. Quero ajudar ele, oque eu posso fazer?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/143')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Fernanda Salles &#183; 23/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/149-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa tarde, pessoal!<br/>O que fazer pra aumentar as plaquetas?<br/>A minha irmã está em tratamento paliativo faz mais de um ano, e não está conseguindo aumentar as plaquetas de jeito nenhum, e ela precisa disso para poder tomar os remédios da quimioterapia.<br/>Ela já recebeu transfusão de sangue, está tomando ferro há quatro dias, comendo feijão, beterraba e bebendo água de coco, mas sem resultados.<br/>Alguma ideia?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/149')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Carlos Frantz &#183; 18/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/126-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    O que vocês sabem sobre neuropatia? Estou de cadeira de rodas por isso
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/126')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
