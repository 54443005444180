
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post50() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoal. Eu tenho só 30 anos e descobri que estou com câncer no útero, e o tratamento é quimioterapia e radioterapia. Não tem como fazer a cirurgia. Estou com muito medo de não curar</title>
        <meta 
          name="description" 
          content="Oi pessoal. Eu tenho só 30 anos e descobri que estou com câncer no útero, e o tratamento é quimioterapia e radioterapia. Não tem como fazer a cirurgia. Estou com muito medo de não curar"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Michele Lombardi &#183; 08/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoal. Eu tenho só 30 anos e descobri que estou com câncer no útero, e o tratamento é quimioterapia e radioterapia. Não tem como fazer a cirurgia. Estou com muito medo de não curar
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/50')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  Esse post foi útil?
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi, Michele.<br/><br/>Eu entendo que você deve estar se sentindo muito assustada nesse momento. Receber um diagnóstico de câncer e saber que não pode fazer cirurgia é realmente difícil, especialmente sendo tão jovem.<br/><br/>Primeiro, quero dizer que é normal sentir medo e insegurança. A quimioterapia e a radioterapia são tratamentos poderosos que têm ajudado muitas pessoas a combater o câncer. Cada caso é único, e o seu médico está recomendando esses tratamentos porque acredita que são as melhores opções para você.<br/><br/>Aqui vão algumas dicas que podem ajudar:<br/><br/>1. **Converse com seu médico**: Tire todas as suas dúvidas sobre os tratamentos, como eles funcionam e quais são as chances de sucesso. Entender mais sobre o que está acontecendo pode diminuir um pouco a ansiedade.<br/><br/>2. **Compartilhe seus sentimentos**: Falar com amigos, familiares ou participar de grupos de apoio pode ser muito útil. Você não está sozinha nessa jornada.<br/><br/>3. **Cuide de si mesma**: Siga as orientações do seu médico sobre alimentação, repouso e atividades físicas. Pequenas mudanças no estilo de vida podem fazer uma grande diferença no seu bem-estar durante o tratamento.<br/><br/>4. **Procure apoio emocional**: A terapia com um psicólogo pode ajudar a lidar com o medo e a ansiedade. Muitos hospitais têm profissionais especializados em acompanhar pacientes oncológicos.<br/><br/>Lembre-se, Michele, que a medicina tem avançado muito e há muitas histórias de pessoas que superaram o câncer com os tratamentos que você está prestes a começar.<br/><br/>Se precisar de mais alguma coisa ou quiser conversar, estamos aqui para ajudar você.<br/><br/>Um abraço forte,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 08/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/50')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Claudia Coimbra &#183; 13/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/83-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Preciso de ajuda. Quando tem moleza no corpo e vontade de dormir o dia todo é porque a diabetes está baixa?<br/>Meu marido tem diabates, e as vezes ele dorme o dia todo.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/83')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Paula Farber &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/94-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Alguém com diabetes, que tem fezes moles e diarréia constante?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/94')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Vitor Machado &#183; 13/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/84-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá amigos,<br/>O que usar nas alergias pós químio vermelha? Vocês tem alguma sugestão?<br/>Minha mãe está nas virilhas bem vermelha e com coceira.<br/>O médico não indicou nada , mas já vi outras pessoas com mesmos sintomas que usaram algo para aliviar, mas não sei oque.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/84')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Camila da Silveira &#183; 17/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/115-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi gente, boa noite !<br/>Minha sobrinha de 12 anos foi diagnosticada diabetes tipo 1.<br/>Hoje ela começou a ficar gripada e não sabemos qual xarope ela pode tomar.<br/>Alguém pode me ajudar <br/>Ainda não conseguimos as consultas pelo SUS.<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/115')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Maria de Lourdes &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/40-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal,<br/>Tenho pressão alta e hoje de manhã fiquei nervosa e ela subiu <br/>Tomei Losartana, hidroclorotiazida um diazepam e um anlodipino e um antidepressivo<br/>Agora a pressão está em 12/7 mas os batimentos cardíacos estão em 107<br/>É normal isso?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/40')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
