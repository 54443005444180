import React from 'react'
import { useNavigate } from "react-router-dom"
import Button from "../Button"
import { PortalContext } from '../../AuthenticatedUser'
import AuthContext from '../../context/AuthProvider'
import FormProgressBar from '../FormProgressBar';
import { postRequestToApi } from '../../api'

export default function ExamForm({ id }) {

  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  const { health } = React.useContext(PortalContext);

  const [resultsData, setResultsData] = React.useState([])
  const [formPage, setFormPage] = React.useState(0)  
  const [status, setStatus] = React.useState('idle')

  const navigate = useNavigate()

  React.useEffect(()=>{
  
    const userId = +cookies.get("userId")

    if (health[userId]?.exams) {
      if (health[userId].exams !== undefined) {
        health[userId].exams.forEach(item => {
          if (item.id == id) {
            if (item.results) {
              setResultsData(item.results)
            }
          }
        })
      }
    }
  }, [health])
  

  async function submitExamData(body) {

    setStatus("submitting")
    try {

      console.log('body')
      console.log(body)
      const data = await postRequestToApi(
      body,
      "exam", auth, cookies)

      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 
    } catch (err) {
      console.log("Error loading forum data")
      navigate('/health')
    } finally {
      setStatus("idle")
      // navigate to visualization page, where the new data from
      // the prescription should be loaded 
      navigate(`/exam/${id}`,
        { state: { search: {
          message: "Os dados do exame foram confirmados, e você poderá ver os resultados diretamente em sua página de saúde.",
          previous: '/health',
          title: '',
          file: ''
        }} })
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault()

    const body = {
      id: +cookies.get("userId"),
      exam_data: resultsData 
    }

    submitExamData(body)
    
  }

  const handleChange = (e, index) => {
    const { name, value } = e.target
  
    //let copyData = resultsData
    //copyData[index][name] = value

    //setResultsData(copyData)
      
    let copyData = resultsData
    copyData = copyData.map((item, i) => {
      if (i===index) {
        return {...item, [name]: value}
      } else {
        return item
      }
    })
    setResultsData(copyData) 
  }

  console.log(health)
  console.log(resultsData)

  const form = (
    <div className="right-margin-s">
      <p className="heading5 left-margin-s">Resultados do exame</p>
      <div className="editable-item-for-card top-margin-xs top-padding-xs left-padding-s right-padding-xs">
        <div className="top-margin-xs right-margin-xs">
          <div className="content-two-columns-exam bottom-margin-xs">
            <p className="body">Parâmetro</p>
            <p className="body">Resultado</p>
          </div>
          {
            resultsData.map((item, index) => {
              return (
                <div className="content-two-columns-exam">
                  <input
                    className="input-text-two-columns bottom-margin-xs"
                    name="parameter"
                    onChange={(e) => handleChange(e, index)} 
                    type="text"
                    value={resultsData[index].parameter}
                  />

                  <input
                    className="input-text-two-columns bottom-margin-xs"
                    name="value"
                    onChange={(e) => handleChange(e, index)} 
                    type="text"
                    value={resultsData[index].value}
                  />

                </div>
              )
            })
          }
        </div>

        <Button type="primary right-margin-s bottom-margin-s">
          {(status !== "idle") ? "Confirmando..." : "Confirmar" }
        </Button>
      </div>
    </div>
  )

  return (
    <div>
      <form onSubmit={handleSubmit} className="login-form">
        {form}
      </form>
    </div>
  )
}
