import React from 'react'
import EditableItemForCard2 from './EditableItemForCard2'

export default function UserBmiCard({ bmi, simple }) {

  let userBmiCard = null
  if (bmi && bmi > 10 && bmi < 60) {
    if (bmi < 18.5) {
      userBmiCard = (
        <EditableItemForCard2 colour="red">
          <div>
            <p className="body"><strong>Índice de massa corporal: </strong>{bmi}</p>
            <p className="body">Abaixo do normal</p>
            {(!simple) ? <p className="secondary-text">Procure um médico. Algumas pessoas têm um baixo peso por características do seu organismo e tudo bem. Outras podem estar enfrentando problemas, como a desnutrição. É preciso saber qual é o caso.</p>
            : null }  
          </div>
        </EditableItemForCard2>
        )
    } else if (bmi < 24.9) {
      userBmiCard = ( 
        <EditableItemForCard2 colour="green">
          <div>
            <p className="body"><strong>Índice de massa corporal: </strong>{bmi}</p>
            <p className="body">Normal</p>
            {(!simple) ? <p className="secondary-text">Que bom que você está com o peso normal! E o melhor jeito de continuar assim é mantendo um estilo de vida ativo e uma alimentação equilibrada.</p>
            : null }
          </div>
        </EditableItemForCard2>
        )
    } else if (bmi < 29.9) {
      userBmiCard = ( 
        <EditableItemForCard2 colour="yellow">
          <div>
            <p className="body"><strong>Índice de massa corporal: </strong>{bmi}</p>
            <p className="body">Sobrepeso</p>
            {(!simple) ? <p className="secondary-text">Essa faixa representa pré-obesidade e muitas pessoas nessa faixa já apresentam doenças associadas, como diabetes e hipertensão. Importante rever hábitos e buscar ajuda antes de, por uma série de fatores, entrar na faixa da obesidade pra valer.</p>
            : null }
          </div>
        </EditableItemForCard2>
        )
    } else {
      userBmiCard = ( 
        <EditableItemForCard2 colour="red">
          <div>
            <p className="body"><strong>Índice de massa corporal: </strong>{bmi}</p>
            <p className="body">Obesidade</p>
            {(!simple) ? <p className="secondary-text">Sinal de alerta, chegou na hora de se cuidar. Vamos dar início a mudanças hoje! Cuide de sua alimentação. Você precisa iniciar um acompanhamento com nutricionista e/ou endocrinologista.</p>
            : null }
          </div>
        </EditableItemForCard2>
      )
    }
  }

  return userBmiCard
}
