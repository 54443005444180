
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post97() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoal. Estou bastante triste, descobri que estou com catarata por conta da diabete segundo o oftalmologista. Não consigo entender muito bem, mantenho a glicose controlada, jejum 105, prandial deu até menos, 100 glicada 6.4. Estou preocupado.</title>
        <meta 
          name="description" 
          content="Oi pessoal. Estou bastante triste, descobri que estou com catarata por conta da diabete segundo o oftalmologista. Não consigo entender muito bem, mantenho a glicose controlada, jejum 105, prandial deu até menos, 100 glicada 6.4. Estou preocupado."/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Mario César &#183; 15/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoal. Estou bastante triste, descobri que estou com catarata por conta da diabete segundo o oftalmologista. Não consigo entender muito bem, mantenho a glicose controlada, jejum 105, prandial deu até menos, 100 glicada 6.4. Estou preocupado.
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/97')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  6 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Mario,<br/><br/>Sinto muito que você esteja passando por isso. É natural ficar triste e preocupado com um diagnóstico como esse, mas é importante lembrar que a catarata é uma condição tratável, mesmo que tenha sido causada pelo diabetes.<br/><br/>Os seus níveis de açúcar no sangue parecem estar bem controlados, o que é excelente e muito importante para prevenir outras complicações. Mesmo assim, a catarata pode se desenvolver com o tempo devido a vários fatores, incluindo a diabetes.<br/><br/>O mais importante agora é seguir as orientações do seu oftalmologista e do seu endocrinologista. Eles podem te ajudar a decidir o melhor tratamento. Em muitos casos, a cirurgia de catarata é bem-sucedida e pode melhorar muito a visão.<br/><br/>Continue cuidando da sua saúde e controlando o diabetes como você já tem feito. E não hesite em conversar com os seus médicos sobre suas preocupações e dúvidas. Estamos aqui para te apoiar também!<br/><br/>Fique bem e força!<br/><br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 15/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/97')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Flavia de Albuquerque &#183; 17/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/114-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    A minha hemoglobina clicada deu deu 5,8. Pré diabetes. O que devo fazer para não virar diabética? Existe algum remédio que possa tomar para não virar diabética?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/114')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariana Silva &#183; 12/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/78-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. O médico me receitou sinvastatina 20 mg uma vez por dia.<br/>Será que estou tão mal assim fico preocupada??<br/>Meu exame deu isso<br/>HDL 78.5<br/>NAO-Hdl 180mg<br/>Colesterol total 258.3<br/>Ldl 164<br/>Triglicerides 77<br/>Hemoglobina glicada 4.9%<br/><br/>Obrigado!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/78')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Maria Silva &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/53-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, amanhã é minha última seção de quimioterapita. Peço orações por mim
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/53')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marli Seccon &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/65-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, queria uma opinião. A médica me disse que meu colesterol tá alto e me passou sinvastatina. Meus resultados estão o seguinte: Ldl 131, Colesterol total 218, Triglicerídeos 65, Hdl 71. Oque vcs acham?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/65')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Joana Ling &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/27-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Estou com muita dor nas pernas devido a neuropatia diabética. Eu uso pregabalina, mas ainda continuo com muita dor. Oque é bom para aliviar as dores? Alguém já passou por isso?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/27')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
