
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post59() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Boa tarde pessoal, meu médico me mandou tomar cloridrato clomipramina 25mg pra pressão alta, mas não estou me adaptando. Tomo depois do almoço, mas estou tendo insônia e ele me deixa meio lenta, meio lesada. E não está resolvendo a pressão também. Alguém aqui toma ele?</title>
        <meta 
          name="description" 
          content="Boa tarde pessoal, meu médico me mandou tomar cloridrato clomipramina 25mg pra pressão alta, mas não estou me adaptando. Tomo depois do almoço, mas estou tendo insônia e ele me deixa meio lenta, meio lesada. E não está resolvendo a pressão também. Alguém aqui toma ele?"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Paula Farber &#183; 10/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Boa tarde pessoal, meu médico me mandou tomar cloridrato clomipramina 25mg pra pressão alta, mas não estou me adaptando. Tomo depois do almoço, mas estou tendo insônia e ele me deixa meio lenta, meio lesada. E não está resolvendo a pressão também. Alguém aqui toma ele?
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/59')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  1 pessoa achou útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Paula, boa tarde!<br/><br/>Entendo como pode ser frustrante não se adaptar a um medicamento. O cloridrato de clomipramina geralmente é usado como antidepressivo e para tratar transtornos obsessivo-compulsivos, então é um pouco incomum para tratar pressão alta. É muito importante você conversar com seu médico sobre esses efeitos colaterais que está sentindo e sobre a pressão que ainda não está controlada.<br/><br/>Seu médico pode ajustar a dose, ou talvez mudar o medicamento para algo que funcione melhor para você sem tantos efeitos adversos. E, lembre-se, nunca pare de tomar o medicamento sem conversar antes com o seu médico, ok?<br/><br/>Enquanto isso, talvez você possa anotar essas reações e os horários que ocorrem, para levar essas informações na próxima consulta – isso pode ajudar bastante o médico a entender melhor como te ajudar.<br/><br/>Desejo melhoras rápidas para você! Se precisar de mais alguma coisa, estamos aqui. 😊<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 10/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/59')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Rita Anton &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/30-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    O que toma para baixar o colesterol?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/30')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Paula Farber &#183; 18/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/125-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Pessoal com diabetes tipo 1. Quais os legumes vocês mais usam no dia a dia?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/125')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Rita Anton &#183; 12/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/79-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, sou hipertensa e hoje minha pressão diastólica não saiu do 10 ou 9 ....<br/>A sistólica fica entre 12 e 13 e a diastólica entre 9 e 10.<br/>Oque vocês acham que eu devo fazer?<br/>Já tomei o remédio da pressão as 18 horas tomo a cada 12 horas, mas tenho ansiedade e não estou nem conseguindo dormir porque a pressão não abaixa.<br/>Já tá me dando até dor de cabeça
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/79')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Fernanda Salles &#183; 23/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/149-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa tarde, pessoal!<br/>O que fazer pra aumentar as plaquetas?<br/>A minha irmã está em tratamento paliativo faz mais de um ano, e não está conseguindo aumentar as plaquetas de jeito nenhum, e ela precisa disso para poder tomar os remédios da quimioterapia.<br/>Ela já recebeu transfusão de sangue, está tomando ferro há quatro dias, comendo feijão, beterraba e bebendo água de coco, mas sem resultados.<br/>Alguma ideia?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/149')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Rita Anton &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/60-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, me deem um conselho. Quantas vezes ao dias vocês medem a glicemia? Vocês chegam a medir com maior frequência quando sentem dores ou formigamento em alguma parte do corpo, pra ver se tem a ver com a diabetes?<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/60')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
