import React from 'react';

export default function FormProgressBar({label="Pergunta", current, total}) {

  let progressBar = []
  for (let i = 0; i < total; i++) {
    progressBar.push(
     (i <= current) ?
        <div key={i} className="rectangle full"></div>
        :
        <div key={i} className="rectangle empty"></div>
    )
  }

  return (
    <div>
      <p className="small-title">{`${label} ${current+1} de ${total}`}</p>

      <div className="progress-bar bottom-margin-s">
        {progressBar}
      </div>
    </div>
  )
}
