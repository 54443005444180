
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post143() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Boa noite pessoal. Meu irmão tá com câncer na coluna e no estômago já avançado e perdeu muito peso. Quero ajudar ele, oque eu posso fazer?</title>
        <meta 
          name="description" 
          content="Boa noite pessoal. Meu irmão tá com câncer na coluna e no estômago já avançado e perdeu muito peso. Quero ajudar ele, oque eu posso fazer?"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Joceli Ferreira &#183; 21/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Boa noite pessoal. Meu irmão tá com câncer na coluna e no estômago já avançado e perdeu muito peso. Quero ajudar ele, oque eu posso fazer?
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/143')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  4 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Boa noite, Joceli.<br/><br/>Sinto muito pelo que seu irmão está passando. Esse é um momento muito difícil, mas existem algumas coisas que você pode fazer para ajudar:<br/><br/>1. **Apoio emocional**: Esteja presente e ofereça seu apoio emocional. A companhia e o carinho de um familiar podem fazer uma grande diferença no bem-estar dele.<br/><br/>2. **Alimentação**: Tente conversar com o médico ou nutricionista que acompanha seu irmão para ver se há uma dieta especial ou suplementos que podem ajudar a melhorar a nutrição dele. Às vezes, pequenas refeições ao longo do dia são mais fáceis de digerir.<br/><br/>3. **Cuidados diários**: Se ele tiver dificuldade para se mover ou realizar tarefas diárias, ajude-o com essas atividades. O conforto e a higiene são muito importantes.<br/><br/>4. **Conversa com profissionais de saúde**: Sempre siga as orientações dos médicos que estão cuidando dele. Eles são os mais indicados para adaptar o tratamento e dar recomendações específicas.<br/><br/>5. **Cuidados paliativos**: Em casos avançados, os cuidados paliativos podem proporcionar alívio dos sintomas e melhorar a qualidade de vida. Pergunte ao médico sobre essa opção.<br/><br/>6. **Apoio psicológico e espiritual**: Se for do interesse dele, buscar apoio psicológico ou espiritual pode ajudar a lidar com a situação.<br/><br/>Se você está se sentindo sobrecarregada, não hesite em procurar apoio de outros familiares ou amigos. Cuidar de alguém com uma doença grave pode ser emocionalmente exaustivo.<br/><br/>Espero que essas dicas sejam úteis. Qualquer outra dúvida, estou à disposição.<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 21/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/143')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Danna Rosa &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/45-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, boa tarde. Me tirem uma dúvida.<br/>Diabético pode usar açúcar mascavo no lugar do açúcar normal?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/45')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Rita Anton &#183; 12/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/79-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, sou hipertensa e hoje minha pressão diastólica não saiu do 10 ou 9 ....<br/>A sistólica fica entre 12 e 13 e a diastólica entre 9 e 10.<br/>Oque vocês acham que eu devo fazer?<br/>Já tomei o remédio da pressão as 18 horas tomo a cada 12 horas, mas tenho ansiedade e não estou nem conseguindo dormir porque a pressão não abaixa.<br/>Já tá me dando até dor de cabeça
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/79')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Maria Silva &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/53-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, amanhã é minha última seção de quimioterapita. Peço orações por mim
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/53')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Fernanda Salles &#183; 23/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/149-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa tarde, pessoal!<br/>O que fazer pra aumentar as plaquetas?<br/>A minha irmã está em tratamento paliativo faz mais de um ano, e não está conseguindo aumentar as plaquetas de jeito nenhum, e ela precisa disso para poder tomar os remédios da quimioterapia.<br/>Ela já recebeu transfusão de sangue, está tomando ferro há quatro dias, comendo feijão, beterraba e bebendo água de coco, mas sem resultados.<br/>Alguma ideia?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/149')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marcos Rosebrock &#183; 20/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/138-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi gente, tenho diabetes tipo 2 e tomo xgduo. Alguém que usa esse medicamente teve algum diagnóstico de sangue na urina?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/138')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
