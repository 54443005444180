import React from 'react'
import EditIcon from '../images/edit.svg'
import RemoveIcon from '../images/remove.svg'
import Button from './Button'

export default function EditableItemForCardCustomEdit({ 
  children, 
  edit=false,
  editionForm,
  setEditForm,
  editing
}) {

  return (
    <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs">

      <div className='editable-item-for-card-header'>
        <div>{children}</div>
        <div className="row-svg-icons left-margin-xs">
          {(!editing && edit) ? 
              <button onClick={()=>{setEditForm(prev=>!prev)}} className='carousel-btn'>
                <img className="" src={EditIcon}/>
              </button> : null }
        </div>
      </div>
  
      {(editing) ? 
        <div className={`vertical-margin-xs`}>
          { editionForm } 
        </div>
       : null}


    </div>
  )
}
