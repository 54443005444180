
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post55() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Gente eh verdade que quem tem pressão alta também tem problema no coração?</title>
        <meta 
          name="description" 
          content="Gente eh verdade que quem tem pressão alta também tem problema no coração?"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Maria Schwed &#183; 10/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Gente eh verdade que quem tem pressão alta também tem problema no coração?
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/55')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  2 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Maria, tudo bem?<br/><br/>Sim, a pressão alta, ou hipertensão, pode aumentar o risco de problemas no coração ao longo do tempo. Isso porque o coração tem que trabalhar mais para bombear o sangue pelo corpo, o que pode sobrecarregá-lo e levar a condições como doenças cardíacas, insuficiência cardíaca e até mesmo um acidente vascular cerebral (AVC).<br/><br/>Mas não se preocupe, com o tratamento correto e algumas mudanças no estilo de vida, é possível controlar bem a pressão alta e reduzir esses riscos. Seguir as orientações do seu médico, tomar os medicamentos corretamente, manter uma dieta equilibrada, praticar exercícios físicos e evitar o estresse são passos importantes para cuidar da sua saúde.<br/><br/>Se você tiver alguma dúvida específica ou sentir algo diferente, é sempre bom conversar com seu médico para ele te orientar da melhor forma, tá bom?<br/><br/>Um abraço!
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 10/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/55')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Fernanda Aguiar &#183; 19/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/132-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi poderia me explicar como faço a contagem de carboidratos
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/132')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Rita Anton &#183; 06/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/22-diabetes" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Diabetes dicas  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Gente, boa dia!<br/><br/>Eu fui diagnosticada com diabetes fazem uma semaninhas e comecei uma reeducação alimentar (fui na nutri). Mas não estou acompanhando a glicemia, não tenho o aparelho ainda. Vocês acham que precisa comprar? Ou dá pra fazer na farmácia de vez em quando?<br/><br/>Obrigado!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/22')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Maria Silva &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/53-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, amanhã é minha última seção de quimioterapita. Peço orações por mim
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/53')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mario César &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/31-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Pessoal, meu exame de colesterol deu assim... Vocês sabem se está alterado? Ainda não mostrei para o médico<br/><br/>Colesterol total: 193 mg/dL<br/>Colesterol HDL: 69.7 mg/dL<br/>Triglicerídeos: 89.0
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/31')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Michele Lombardi &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/50-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Eu tenho só 30 anos e descobri que estou com câncer no útero, e o tratamento é quimioterapia e radioterapia. Não tem como fazer a cirurgia. Estou com muito medo de não curar
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/50')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            Esse post foi útil?
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
