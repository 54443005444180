
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post41() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoal!!
Eu ando bem esgotada, qualquer coisa que vou fazer fico super cansada. Não sei se é devido a diabete tipo 2, mas quero melhorar isso. Eu trabalho e tenho 48 anos. Você tem algum dica do que eu possa fazer?</title>
        <meta 
          name="description" 
          content="Oi pessoal!!
Eu ando bem esgotada, qualquer coisa que vou fazer fico super cansada. Não sei se é devido a diabete tipo 2, mas quero melhorar isso. Eu trabalho e tenho 48 anos. Você tem algum dica do que eu possa fazer?"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Maria Schwed &#183; 08/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoal!!<br/>Eu ando bem esgotada, qualquer coisa que vou fazer fico super cansada. Não sei se é devido a diabete tipo 2, mas quero melhorar isso. Eu trabalho e tenho 48 anos. Você tem algum dica do que eu possa fazer?
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/41')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  1 pessoa achou útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi! Sinto muito que você esteja passando por isso. O cansaço pode ser um sintoma de diabetes tipo 2, mas também pode ter várias outras causas. Aqui estão algumas dicas que podem te ajudar:<br/><br/>1. **Controle da glicemia**: Mantenha o controle do seu nível de açúcar no sangue. Verifique como está sua glicemia e siga as orientações do seu médico. Às vezes, altos ou baixos níveis de açúcar no sangue podem causar cansaço.<br/><br/>2. **Alimentação balanceada**: Uma dieta equilibrada pode fazer uma grande diferença. Tente comer alimentos integrais, frutas, verduras e proteínas magras. Evite carboidratos simples e açúcares em excesso.<br/><br/>3. **Hidratação**: Beba bastante água ao longo do dia. A desidratação pode aumentar a sensação de cansaço.<br/><br/>4. **Exercícios físicos**: Atividades físicas leves, como caminhadas, podem ajudar a melhorar seus níveis de energia. Converse com seu médico para saber quais exercícios são mais indicados para você.<br/><br/>5. **Sono**: Tente manter um padrão de sono regular, dormindo o suficiente para se sentir descansada.<br/><br/>6. **Gerenciamento do estresse**: Estresse pode aumentar o cansaço. Práticas como meditação, respiração profunda ou atividades que você goste podem ajudar.<br/><br/>Não se esqueça de conversar com seu médico sobre seu cansaço. Eles poderão investigar se há outras causas e ajustar o seu tratamento se necessário. Cuide-se bem!<br/><br/>Um abraço,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 08/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/41')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Camila da Silveira &#183; 21/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/142-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Hoje fui no meu endocrinologista e ele mudou meu medicamento que já tomava faz tempo cloridrato de pioglitasona 15mg por forxiga 10mg. Reparei que a diferença de preço é grande. Esse Forxiga é muito melhor?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/142')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Martina Coelho &#183; 13/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/86-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal<br/>Fui diagnosticada com câncer de mama há 1 semana, e estou sem chão, parece que o mundo perdeu o sentido…<br/>Confesso que minha vontade é só de ficar deitada na cama, no escuro, sozinha...<br/>Estou desde o dia do diagnóstico sem trabalhar, amanhã terei q voltar, mas só em pensar que terei q encarar os olhos das pessoas me olhando com dó, me já me dá uma ansiedade
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/86')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    João Nogueira &#183; 12/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/77-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Capsula de pata de vaca abaixa diabetes?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/77')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Luíza Castro &#183; 13/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/80-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal! Qual creme vcs usam para a pele? Porque diabetes ressecada bastante a pele
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/80')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    L. Carrol &#183; 23/07/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/7-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Colesterol bom e colesterol ruim  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Uma das primeiras coisas que descobri depois do diagnóstico de colesterol alto foi que existem dois tipos de colesterol: o colesterol ruim (LDL) e o colesterol do bem (HDL). O colesterol total é a soma desses dois, e colesterol alto é ruim porque quando ele está alto, a maior parte dele é composta do colesterol ruim (LDL). <br/><br/>Importante: Pessoas com colesterol alto têm cerca de o dobro do risco de desenvolverem uma doença cardíaca em comparação com pessoas com níveis de colesterol normais.<br/><br/>Assim, para melhorar o colesterol, o que precisamos fazer é tentar REDUZIR nosso colesterol ruim (LDL) enquanto AUMENTAMOS nosso colesterol bom (HDL). <br/><br/>A primeira causa de termos alto colesterol ruim (LDL) é comer comidas que têm gorduras ruins para nós (gorduras trans e gorduras saturadas). O colesterol bom (HDL) ajuda a combater e reduzir o colesterol ruim (LDL), e quantidade muito baixa dele é uma causa de doença cardíaca.<br/><br/>Os três fatores SOB NOSSO controle que influenciam nosso colesterol (aumentam o bom, reduzem o ruim) são:<br/><br/>1) Nosso peso: Obesidade pode aumentar nosso colesterol ruim (LDL) até 50%, ao mesmo tempo que pode reduzir nosso colesterol bom (HDL) em 20%.<br/><br/>2) O que nós comemos: Tem muita coisa para falar aqui, mas o importante é comer saudável: frutas, verduras, fibras, peixes. Evitar comida processada, frituras, carne vermelha.<br/><br/>3) Exercício físico: Exercício é o último fator porque ele influencia os outros dois: pessoas que se exercitam tendem a comer melhor e pesar menos. Exercício também é chave para fortalecer o músculo do coração.<br/><br/>Aprender sobre esses pontos me ajudou muito no início da minha melhora de saúde, e espero que possa servir para vocês também.<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/7')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        Nenhum comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
