import React from 'react'
import HighlightedMessage from "../HighlightedMessage"
import Button from "../Button"
import Attention from "../../images/sun.svg"

export default function InsuranceForm({ setAddInsurance, submitNewItem }) {

  const [formData, setFormData] = React.useState({
    name: "", number: ""})

  const [error, setError] = React.useState(null)

  const userRef = React.useRef()

  // go to the top of the page
  React.useEffect(() => {
    if (userRef.current) {
      userRef.current.focus()
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault()

    if (formData.name !== "" && formData.number !== "") {
      setError(null)

      const newInsurance = {
        name: formData.name,
        number: formData.number
      }

      submitNewItem('insurance', newInsurance)
      setAddInsurance() // close add card
    } else {
      setError("Por favor, preencha todos os dados para adicionar o novo convênio.")
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  return (
    <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs">
      {(error) ?
        <div className="">
          <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} />  
        </div>
        : null }
      <form onSubmit={handleSubmit} className="login-form">
        <p className="body top-padding-xs">Nome do plano/convênio</p>
        <input
          className="input-text bottom-margin-xs"
          ref = {userRef}
          name="name"
          onChange={handleChange}
          type="text"
          value={formData.name}
        />
        <p className="body top-padding-xs">Número de registro/carteirinha</p>
        <input
          className="input-text bottom-margin-xs"
          name="number"
          onChange={handleChange}
          type="text"
          value={formData.number}
        />

        <div className="top-margin-xs">
          <Button type="primary right-margin-s bottom-margin-s">
            Criar
          </Button>
          <Button buttonType="button" onClick={()=>setAddInsurance()} type="tertiary right-margin-s bottom-margin-s">
            Cancelar
          </Button>
        </div>
      </form>
    </div>
  )
}
