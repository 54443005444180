
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post52() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Boa noite pessoal. 
Alguém que está fazendo quimioterapia sente os pés dormentes e as pontas dos dedos das mãos? 
Meus pés estão muito dormentes, e está aumentando. Estou na quinta sessão de quimio</title>
        <meta 
          name="description" 
          content="Boa noite pessoal. 
Alguém que está fazendo quimioterapia sente os pés dormentes e as pontas dos dedos das mãos? 
Meus pés estão muito dormentes, e está aumentando. Estou na quinta sessão de quimio"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Carla Esteves &#183; 08/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Boa noite pessoal. <br/>Alguém que está fazendo quimioterapia sente os pés dormentes e as pontas dos dedos das mãos? <br/>Meus pés estão muito dormentes, e está aumentando. Estou na quinta sessão de quimio
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/52')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  1 pessoa achou útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Boa noite, Carla.<br/><br/>Sinto muito em saber que você está passando por isso. A dormência nos pés e nas pontas dos dedos das mãos é um efeito colateral relativamente comum em algumas quimioterapias. Esse sintoma é conhecido como neuropatia periférica, e muitas pessoas relatam essa sensação durante o tratamento.<br/><br/>Importante é você comunicar esse sintoma ao seu oncologista, pois ele ou ela pode ajustar sua medicação ou oferecer outros métodos para aliviar esses sintomas. Não tente suportar a dor sozinha; é fundamental que seu médico saiba exatamente o que você está sentindo.<br/><br/>Enquanto isso, algumas pessoas encontram alívio em atividades como massagem nos pés, exercícios suaves, ou usando meias acolchoadas. Mas, novamente, sempre fale com seu médico antes de tentar qualquer coisa nova.<br/><br/>Mantenha-se forte, e lembre-se que você não está sozinha nessa luta. Se precisar conversar mais, estamos todos aqui para apoiar você.<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 08/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/52')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marli Seccon &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/37-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Pessoal, minha pressão tá 19/10. Tomei losartana 20 mg, faz 2 horas e não abaixa a pressão
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/37')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Maria Schwed &#183; 11/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/74-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Qual é o nível do colesterol normal?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/74')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Carla Chagas &#183; 20/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/135-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Glicada 6,9 e glicemia de jejum 149 é muito ruim?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/135')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marlene Techini &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/100-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, bom dia. Eu faço uso somente da insulina NPH e simplesmente não abaixa nada. Tomo ela faz um tempo e após 2hrs da refeição continua alta e em jejum tbm. Será que tenho que trocar essa insulina? Ela é fraca? <br/><br/>Aprendi a fazer contagem de carboidratos tbm e não está resolvendo. Alguém que entenda sobre essa insulina poderia me explicar o que ocorre?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/100')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Walter Medeiros &#183; 24/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/151-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá, que vitamina ou suplemento o pré-diabetico pode tomar para engordar?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/151')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
