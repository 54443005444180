
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post140() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Alguém aqui toma rybelsus para controle da glicemia?
Eu sinto muito enjoo e não tenho apetite, mas mesmo assim a glicose continua alta</title>
        <meta 
          name="description" 
          content="Alguém aqui toma rybelsus para controle da glicemia?
Eu sinto muito enjoo e não tenho apetite, mas mesmo assim a glicose continua alta"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Vitor Machado &#183; 21/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Alguém aqui toma rybelsus para controle da glicemia?<br/>Eu sinto muito enjoo e não tenho apetite, mas mesmo assim a glicose continua alta
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/140')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  4 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Vitor,<br/><br/>Eu entendo como pode ser frustrante lidar com esses sintomas e ainda ver a glicose alta. O enjoo e a falta de apetite são efeitos colaterais comuns do Rybelsus (semaglutida). O importante é não desistir e sempre conversar com seu médico sobre o que está sentindo.<br/><br/>Tem algumas coisas que podem ajudar a reduzir o enjoo, como comer pequenas refeições ao longo do dia e evitar alimentos gordurosos ou muito pesados. Beber bastante água também pode ajudar. É sempre bom lembrar que cada pessoa reage de uma forma diferente aos medicamentos, e às vezes pode ser necessário ajustar a dose ou até mesmo considerar outra medicação.<br/><br/>Se você continuar sentindo esses efeitos colaterais ou se a glicose não estiver sob controle, por favor, entre em contato com o seu médico. Ele ou ela pode avaliar a sua situação e encontrar a melhor solução para você.<br/><br/>Fique tranquilo e se cuide!<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 21/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/140')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marcela Prattes &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/51-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa noite pessoal<br/>Descobri um câncer no rim e decidi que não vou cuidar .<br/>Não quero cuidar estimativa de vida 5 anos
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/51')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            Esse post foi útil?
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    T. Estudante &#183; 24/07/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/12-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Frutas boas para quem tem diabetes  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Eu gosto muito de comer frutas, mas as vezes fico um pouco receoso sobre quais devo comer e quais podem me prejudicar devido a diabetes.<br/><br/>Vi esse vídeo muito bom do Dr. Roberto Yano explicando sobre isso, e compartilho aqui:<br/><br/>https://www.youtube.com/watch?v=qHvFfwOT8jw<br/><br/>Vale a pena assistir o vídeo, mas aqui está a lista de frutas que ele recomenda:<br/><br/>1) Abacate.<br/><br/>2) Pera.<br/><br/>3) Maçã.<br/><br/>4) Laranja (ideal é comer a fruta inteira, não só tomar o suco).<br/><br/>5) Cereja.<br/><br/>6) Morango.<br/><br/>7) Kiwi.<br/><br/>8 Pêssego.<br/><br/>Espero que essa lista ajude!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/12')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Karla Geise &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/67-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal<br/>Alguém que teve câncer de mama com metástase no pulmão?<br/>Poderiam me contar um pouco<br/>Teve sintomas?<br/>Conseguiu cura com alguma medicação? Qual tratamento fez? Operou?<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/67')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Joana Costa &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/54-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Essa semana chegou a última quimioterapia, e vou na médica também pra ver resultado dos exames<br/>Até aqui o senhor me acompanhou e mim ajudou.<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/54')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        2 comentários
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Joana Ling &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/56-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Quem tem diabetes pode comer cenoura?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/56')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
