import React from 'react';
import { useLocation } from "react-router-dom"
import { useMediaQuery } from 'react-responsive'
import Header from "../components/Header"
import LargeAvatar from "../components/LargeAvatar"
import Button from '../components/Button';
import Attention from "../images/sun.svg"
import HighlightedMessage from '../components/HighlightedMessage';
import { PortalContext } from '../AuthenticatedUser'
import HealthCard from '../components/Health/HealthCard';

export default function Family() {

  const { family, setReload, cookies, health, loadHealthDataFromAPI } = React.useContext(PortalContext);

  const [ load, setLoad ] = React.useState(false)

  const location = useLocation()


  React.useEffect(() => {
    if (location.state?.message) {
       setReload(prev => !prev)
    }
  }, [])
 
  React.useEffect(() => {

    if (family) {
      family.forEach(familyMember => {
        console.log(familyMember)
        if (!health[familyMember.id]) {
          loadHealthDataFromAPI(familyMember.id)
        }
      })
    }
  }, [family])

  React.useEffect(() => {
    setLoad(false)
    if (family) {
      let hasFamilyMember = true
      family.forEach(member => {
        if (!health || !health[member.id]) {
          hasFamilyMember = false
        }
      })
      if (hasFamilyMember) {
        setLoad(true)
      }
    }
  }, [health])

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  const desktopBreakpoint = useMediaQuery({ query: '(min-width: 1280px)' })

  const selectingUser = (userId) => {
    cookies.set("userId", userId, { path: '/' })
    cookies.set("userName", health[userId].first_name, { path: '/' })
    cookies.set("userLastName", health[userId].last_name, { path: '/' })
  }

  console.log(family)
  const familyMembersAvatars = (family) ? family.map(({id, name, last_name}) => { 
    cookies.set(id, name, { path: '/' })
  
    if (health && health[id]) {
      return <HealthCard
        bloodType={health[id].blood_type}
        name={`${health[id].first_name} ${health[id].last_name}`}
        dateOfBirth={health[id].birthdate}
        sex={health[id].sex}
        susNumber={health[id].sus_number}
        insuranceName={(health[id].insurance && health[id].insurance.length > 0) ? health[id].insurance.at(-1).name : null}
        insuranceNumber={(health[id].insurance && health[id].insurance.length > 0) ? health[id].insurance.at(-1).number : null}
        allergies={health[id].allergy}
        organDonation={health[id].organ_donation}
        diseases={health[id].disease}
        emergencyContact={health[id].emergency_contact}
        key={id}
        id={id}
        onClick={(userId)=>selectingUser(userId)}
        linkTo={(cookies.get("pageOfInterest")) ? cookies.get("pageOfInterest") : `/profile`} 
      />
    } else {
      return null
    }
    //return (
    //  <LargeAvatar key={id} 
    //    to={(cookies.get("pageOfInterest")) ? cookies.get("pageOfInterest") : `/profile`} 
    //    id={id} user={name} lastName={last_name} stateValue={{name: name}}/>
    //)
  }) : null

  const content = (
      <section>
        <h1 className="florence-title top-margin-xl">Família</h1>
        <p className="body secondary-color bottom-margin-xs">Selecione seu usuário</p>
        {(location.state?.message) ?
          <div className="bottom-margin-m">
            <HighlightedMessage type="message" icon={Attention} header="Mensagem" body={location.state?.message}/>
          </div>
        : null}

        <div className={ 
        (familyMembersAvatars && familyMembersAvatars.length === 1) ? "avatar-container-single" : 
          (desktopBreakpoint ? "avatar-container avatar-container-large" : "avatar-container")}>
          {familyMembersAvatars}
        </div>
        <Button type="secondary top-margin-xl" link={true} to="add">Adicionar familiar</Button>
      </section>
  )

  //<div className={ desktopBreakpoint ? 
  //      "container container-wider bottom-padding-xxl" : "container-simple bottom-padding-xxl" }>
  return (
    (!load)?
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando página...</p> 
      </div>
      :
    <>
      <div className={ tabletBreakpoint ? 
        "container-simple-tablet bottom-padding-xxl" : "container-simple bottom-padding-xxl" }>
        <Header tabletBreakpoint={tabletBreakpoint} menuItems="family" menuShow={true}/>
        {content}
      </div>
    </>
  )
}
