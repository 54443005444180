
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post63() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoal. O resultado do meu exame de sangue deu 175 de triglicerideos. Me assustou um pouco, oque me aconselham a fazer? Alguma dica?
</title>
        <meta 
          name="description" 
          content="Oi pessoal. O resultado do meu exame de sangue deu 175 de triglicerideos. Me assustou um pouco, oque me aconselham a fazer? Alguma dica?
"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Maria Meyer &#183; 10/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoal. O resultado do meu exame de sangue deu 175 de triglicerideos. Me assustou um pouco, oque me aconselham a fazer? Alguma dica?<br/>
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/63')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  2 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Maria!<br/><br/>Entendo sua preocupação. Os triglicerídeos são um tipo de gordura presente no sangue, e níveis elevados podem aumentar o risco de problemas cardíacos.<br/><br/>Aqui estão algumas dicas que podem te ajudar a controlar os triglicerídeos:<br/><br/>1. **Alimentação Equilibrada:** Tente reduzir o consumo de alimentos ricos em açúcar e gorduras saturadas. Prefira frutas, verduras, grãos integrais e proteínas magras.<br/><br/>2. **Atividade Física:** Procure fazer exercícios regularmente. Pode ser uma caminhada, andar de bicicleta, ou qualquer atividade que você curta. O importante é se mexer.<br/><br/>3. **Evite Álcool:** O consumo de bebidas alcoólicas pode aumentar os níveis de triglicerídeos, então é melhor limitar ou evitar.<br/><br/>4. **Pare de Fumar:** Se você fuma, parar pode fazer uma grande diferença na sua saúde geral, incluindo os níveis de triglicerídeos.<br/><br/>Lembre-se de conversar com o seu médico sobre essas mudanças e seguir as orientações que ele te deu. Cada pessoa é diferente e o seu médico poderá te dar as recomendações mais adequadas para o seu caso.<br/><br/>Espero ter ajudado! Estou aqui para qualquer outra dúvida.<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 10/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/63')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mario César &#183; 06/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/23-diabetes" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Chá para diabético  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Nao estou tomando medicamento<br/>O médico disse que posso controlar com dieta só<br/>Vocês tem chá bom pra diabetes?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/23')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marcela Prattes &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/66-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Poderiam me indicar alguma dieta pra café da manhã, almoço e janta. Estou com trigliceridios de 170
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/66')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Leana de Sá &#183; 22/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/146-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa noite. Alguém tem coceiras nas pernas provocadas pelo diabetes? Que remédio usam para tratar?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/146')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    B. Franklin &#183; 23/07/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/4-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                     O Reino Invisível das Doenças Crônicas  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Estava lendo o livro The Invisible Kingdom (Meghan O'Rourke), com o intuito de conhecer a experiência de outras pessoas que vivem com doenças crônicas, e ele traz muitos pontos que valem a atenção.<br/><br/>A escritora conta sua experiência com o surgimento de uma doença de difícil diagnóstico, onde devido de seus exames darem resultados normais ou sem um diagnóstico definido, os médicos tendiam a falar que sua doença era psicológica, como ansiedade ou depressão, ou simplesmente que não havia nada de errado com ela. Mas com certeza, pelo que estava sentindo, ela sabia que alguma coisa não estava certa.<br/><br/>Durante toda a trajetória que é contada até ela chegar a um diagnóstico tardio da doença de Lyme, que é transmitida por picada de carrapato, o livro traz muitos pontos importantes:<br/><br/>A dificuldade que médicos têm em lidar com pacientes com doenças crônicas, ou quando não tem certeza do diagnóstico do paciente, e a tendência de tentar uma saída fácil, diagnosticado como doença mental seja lá o que o paciente tenha. E por fim, a falta de empatia e de escuta ativa do sistema de saúde, e o quão sozinho esses pacientes se sentem.<br/><br/>Adicionalmente, a escritora retrata os problemas que o que ela medicina em silos gera para a experiência desses pacientes, que ficam sendo encaminhados de um especialista para o outro, onde nenhum tenta ter a visão do todo, ou do paciente como uma pessoa completa, se atendo somente a sua área de especialidade.<br/><br/>O livro é bastante longo, porém com muitas informações úteis e que podem ajudar ou servir de alento para pessoas na mesma situação.<br/><br/>Quem convive com doenças crônicas por períodos longos e sem um diagnóstico definido se sente sozinho e muitas vezes se culpa pela sua doença. Mas vocês não estão sozinhos, muitas outras pessoas estão com vocês e passando pela mesma experiência.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/4')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        Nenhum comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Flavia de Albuquerque &#183; 17/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/114-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    A minha hemoglobina clicada deu deu 5,8. Pré diabetes. O que devo fazer para não virar diabética? Existe algum remédio que possa tomar para não virar diabética?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/114')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
