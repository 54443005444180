
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post74() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Qual é o nível do colesterol normal?</title>
        <meta 
          name="description" 
          content="Qual é o nível do colesterol normal?"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Maria Schwed &#183; 11/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Qual é o nível do colesterol normal?
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/74')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  2 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi, Maria!<br/><br/>Que bom ver você por aqui cuidando da saúde. 😊<br/><br/>Os níveis de colesterol podem variar um pouco, mas aqui estão algumas referências gerais:<br/><br/>- **Colesterol total**: Menos de 200 mg/dL é considerado desejável.<br/>- **Colesterol LDL (o "ruim")**: Menos de 100 mg/dL é ideal. Para quem tem risco alto de doenças cardíacas, menos de 70 mg/dL é ainda melhor.<br/>- **Colesterol HDL (o "bom")**: Em geral, acima de 60 mg/dL é ótimo.<br/>- **Triglicerídeos**: Menos de 150 mg/dL é considerado normal.<br/><br/>Lembre-se que essas são diretrizes gerais, e seu médico pode ter metas específicas para você, dependendo do seu histórico de saúde.<br/><br/>Se está com dúvidas sobre seus níveis de colesterol ou se já fez algum exame e quer entender melhor os resultados, o ideal é conversar com seu médico. Ele vai poder te orientar da melhor forma e, se necessário, propor mudanças no estilo de vida ou medicação.<br/><br/>Qualquer outra dúvida, estou aqui para ajudar. 😊<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 11/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/74')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Paula Farber &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/59-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa tarde pessoal, meu médico me mandou tomar cloridrato clomipramina 25mg pra pressão alta, mas não estou me adaptando. Tomo depois do almoço, mas estou tendo insônia e ele me deixa meio lenta, meio lesada. E não está resolvendo a pressão também. Alguém aqui toma ele?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/59')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Walter Medeiros &#183; 24/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/151-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá, que vitamina ou suplemento o pré-diabetico pode tomar para engordar?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/151')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Neila Gabler &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/98-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia. Medi a minha glicemia e me assustei, dei 405.<br/>Me digam uma receita Detox para baixar a glicemia rápido?<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/98')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            7 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Fernanda Aguiar &#183; 19/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/132-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi poderia me explicar como faço a contagem de carboidratos
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/132')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Elisa Orta &#183; 23/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/147-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Alguém tem alergia no inverno que coça muito fica irritada a pele? É mais as pernas
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/147')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
