import React from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import Button from '../Button'
import AuthContext from '../../context/AuthProvider'
import { ForumContext } from '../../forum/ForumData'
import { postRequestToApi, deleteDataFromApi } from '../../api'

export default function SavedPost({ postId, savedBy }) {
  
  const { loadForumData } = React.useContext(ForumContext)
  const { auth, setAuth, cookies } = React.useContext(AuthContext);

  const [savedCache, setSavedCache] = React.useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    if (savedBy && savedBy.includes(cookies.get("userId"))) {
      setSavedCache(true)
    } else {
      setSavedCache(false)
    }
  }, [])

  async function submitSavedPost() {
    try {
      const data = await postRequestToApi({
        "id": +cookies.get("userId"),       
      }, `forum/${postId}/save`, auth, cookies)
      //setResponse(data)
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      }))
    } catch (err) {
      console.log("Error loading forum data")
      navigate('/loginrequired')
    } finally {
      loadForumData(true) // true: user is loggedin
    }
  }

  async function deleteSavedPost() {
    try {
      const data = await deleteDataFromApi({
        "id": +cookies.get("userId"),       
      }, `forum/${postId}/save`, auth, cookies)
      //setResponse(data)
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 
    } catch (err) {
      console.log("Error loading forum data")
      navigate('/loginrequired')
    } finally {
      loadForumData(true) // true: user is loggedin
    }
  }

  function toggleSaved() {
    if (savedCache) {
      setSavedCache(prev => !prev) 
      deleteSavedPost()
    } else {
      setSavedCache(prev => !prev)
      submitSavedPost()
    }
  }

  let content = null
  if (cookies.get("userId")) {
    if (savedCache) {
      content = <Button type="tertiary" onClick={toggleSaved}>Post salvo para depois</Button>
    } else {
      content = <Button type="tertiary" onClick={toggleSaved}>Salvar post para depois</Button>
    }
  } else {
    content = <Button type="tertiary" link={true} to="/loginrequired" stateValue={{ pageOfInterest: location.pathname }}>
      Salvar post para depois
    </Button>
  }

  return (
    <div>
      {content}
    </div>
  )
}
