
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post100() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoal, bom dia. Eu faço uso somente da insulina NPH e simplesmente não abaixa nada. Tomo ela faz um tempo e após 2hrs da refeição continua alta e em jejum tbm. Será que tenho que trocar essa insulina? Ela é fraca? 

Aprendi a fazer contagem de carboidratos tbm e não está resolvendo. Alguém que entenda sobre essa insulina poderia me explicar o que ocorre?</title>
        <meta 
          name="description" 
          content="Oi pessoal, bom dia. Eu faço uso somente da insulina NPH e simplesmente não abaixa nada. Tomo ela faz um tempo e após 2hrs da refeição continua alta e em jejum tbm. Será que tenho que trocar essa insulina? Ela é fraca? 

Aprendi a fazer contagem de carboidratos tbm e não está resolvendo. Alguém que entenda sobre essa insulina poderia me explicar o que ocorre?"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Marlene Techini &#183; 15/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoal, bom dia. Eu faço uso somente da insulina NPH e simplesmente não abaixa nada. Tomo ela faz um tempo e após 2hrs da refeição continua alta e em jejum tbm. Será que tenho que trocar essa insulina? Ela é fraca? <br/><br/>Aprendi a fazer contagem de carboidratos tbm e não está resolvendo. Alguém que entenda sobre essa insulina poderia me explicar o que ocorre?
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/100')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  5 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi, Marlene! Bom dia.<br/><br/>Entendo sua frustração, deve ser bem complicado lidar com isso. Primeiro, é importante que você nunca mude ou pare de usar a sua insulina sem consultar seu médico. Somente ele pode te orientar sobre a necessidade de mudar o tipo de insulina ou ajustar a dosagem.<br/><br/>A insulina NPH é uma insulina de ação intermediária e funciona de uma maneira específica no seu corpo. Existem vários fatores que podem influenciar os níveis de glicose no sangue, incluindo a alimentação, o nível de atividade física, e até mesmo o nível de estresse.<br/><br/>O fato de você ter aprendido a contar carboidratos é muito positivo, mas é um processo que pode levar algum tempo para funcionar perfeitamente para cada pessoa. Às vezes, pode ser necessário ajustar sua dieta ou a maneira como você está contando os carboidratos.<br/><br/>Eu recomendo que você anote todos os seus níveis de glicose no sangue, o que está comendo, quanta insulina está tomando e outros fatores como exercícios físicos. Leve essas anotações ao seu médico para que ele possa entender melhor o que está acontecendo e ajustar o tratamento se necessário.<br/><br/>Enquanto isso, continue monitorando com cuidado seus níveis de glicose e mantenha contato com seu médico regularmente. Também é importante manter uma alimentação balanceada e seguir as orientações médicas.<br/><br/>Espero ter ajudado, e desejo muita saúde para você!<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 15/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/100')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Elisa Orta &#183; 14/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/91-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá, boa tarde pessoa. Uma pessoa da minha familia esta com colangiocarcinoma<br/>(Cancer nas vias biliares), e está muito magro e não consegue comer. Ele vai passar pela primeira vez no oncologista semana que vem mas já está bem fraco. Alguém aqui com esse tipo de experiência?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/91')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Carla Chagas &#183; 20/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/135-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Glicada 6,9 e glicemia de jejum 149 é muito ruim?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/135')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marcela Prattes &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/66-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Poderiam me indicar alguma dieta pra café da manhã, almoço e janta. Estou com trigliceridios de 170
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/66')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mario César &#183; 06/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/23-diabetes" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Chá para diabético  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Nao estou tomando medicamento<br/>O médico disse que posso controlar com dieta só<br/>Vocês tem chá bom pra diabetes?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/23')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Maria Schwed &#183; 06/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/18-diabetes" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Diabetes de jejum  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia boa semana a todos
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/18')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
