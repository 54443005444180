import React from 'react'
import VerticalBarPlot from '../components/VerticalBarPlot/Plot'
import TimeLinePlot from '../components/TimeLinePlot/Plot'

export default function PlotCard({ type, title, data }) {

  const [width, setWidth] = React.useState(50)
  const plotCardRef = React.useRef()


  React.useEffect(() => {
    if (plotCardRef.current) {
      setWidth(plotCardRef.current.getBoundingClientRect().width)
    }
  },[])
 
  if (plotCardRef.current) {
    window.addEventListener('resize', 
      ()=>{
        if (plotCardRef.current) {
          setWidth(plotCardRef.current.getBoundingClientRect().width)
        }
      }
    )
  }

  if (type === "bar") {
    return (
      <div className="bottom-margin-l">
        <div id="eating-bar-plot" className="plot-card horizontal-padding-s" ref={plotCardRef}>
          <div className="vertical-margin-m">
            <p className="heading5 bottom-margin-s">{title}</p>
            <VerticalBarPlot data={data} parentWidth={width}/>
          </div>
        </div>
      </div>
    )
  }
  if (type === "timeline") {
    return (
      <div className="bottom-padding-l">
        <div id="eating-bar-plot" className="plot-card horizontal-padding-s" ref={plotCardRef}>
            <div className="vertical-margin-m">
              <p className="heading5 bottom-margin-s">{title}</p>
              <TimeLinePlot data={data} parentWidth={width}/>
            </div>
        </div>
      </div>
    )
  }

  return null
}
