import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { differenceInDays, isAfter } from "date-fns"
import Header from "../components/Header"
import ExamCard from "../components/ExamCard"
import UploadDocumentCard from "../components/UploadDocumentCard"
import EditableItemsCard2 from "../components/EditableItemsCard2"
import EditableItemForCard2 from "../components/EditableItemForCard2"
import MedicationForm from "../components/Health/MedicationForm"
import UserBmiCard from '../components/UserBmiCard'
import HighlightedMessage from '../components/HighlightedMessage'
import Attention from "../images/sun.svg"
import PrescriptionCard from '../components/PrescriptionCard'
import MedicationCard from '../components/MedicationCard'
import VaccineCard from '../components/VaccineCard'
import InsuranceForm from '../components/Health/InsuranceForm'
import EditableItemForCardEditSelection from '../components/EditableItemForCardEditSelection'
import EditableItemForCardCustomEdit from '../components/EditableItemForCardCustomEdit'
import EmergencyContactForm from '../components/Health/EmergencyContactForm'
import Button from '../components/Button'

import AuthContext from '../context/AuthProvider'
import { postRequestToApi } from '../api'

export default function SharedHealth() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const { cookies } = React.useContext(AuthContext);
  
  const location = useLocation()  
  const navigate = useNavigate()

  const [showExams, setShowExams] = React.useState(false)
  const [showVaccines, setShowVaccines] = React.useState(false)
  const [showPrescriptions, setShowPrescriptions] = React.useState(false)
  const [showMedications, setShowMedications] = React.useState(false)
  const [showBodyMeasures, setShowBodyMeasures] = React.useState(false)
  const [showHealthHistory, setShowHealthHistory] = React.useState(false)
  const [showFamilyHealthHistory, setShowFamilyHealthHistory] = React.useState(false)

  const [userData, setUserData] = React.useState(null)
  const [userHealth, setUserHealth] = React.useState({
    name: null,
    age: null,
    smoking: "Não informado"
  })
  //console.log(location)

  async function requestSharedData() {
    try {

      const response = await postRequestToApi({
          'first_name': cookies.get('sharedName'),
          'code': `${cookies.get('sharedCode')}`
        }, 
        'access_shared', { refreshToken: 'noneed', accessToken: 'noneed' })

      setUserData(response)

    } catch(err) {

      console.log(err)
      navigate('/accessshared', { state : { previousPath: location.pathname }, replace: true})

    }
  }

  React.useEffect(() => {

    if (location.state?.health) {
      const health = location.state.health
      setUserHealth({
        userId: health.user_id,
        name: `${health.first_name} ${health.last_name}`,
        age: `${health.age}`,
        smoking: health.smoking_status ? `${health.smoking_status}` : 'Não informado',
        organ_donation: health.organ_donation ? health.organ_donation : 'Não declarado',
        blood_type: health.organ_donation ? health.blood_type : 'Não informado',
        sus_number: health.sus_number ? health.sus_number : 'Não informado',
        emergency_contact: (health.emergency_contact.name)
          ? health.emergency_contact : {name: "Não informado", phone_number: null},
        insurance: health.insurance ? health.insurance : [],
        medication: (health.medication || health.medication.length===0 ) ? health.medication : [],
        allergy: (health.allergy || health.allergy.length===0 ) ? health.allergy : [],
        exam: (health.exams || health.disease.exams===0 ) ? 
          health.exams.map(exam => ({...exam, show: false}))  
          : [],
        prescription: (health.prescriptions) ? 
          health.prescriptions : {},
        vaccine: (health.vaccines) ? 
          health.vaccines : [],
        disease: (health.disease || health.disease.length===0 ) ? health.disease : [],
        nextAppointment: health.next_appointment ? `${health.next_appointment}` : 'Não informado',
        height: health.height ? `${health.height}` : 'Não informado',
        weight: health.weight ? `${health.weight}` : 'Não informado',
        bmi: health.bmi ? `${health.bmi}` : null,
        healthHistory: (health.health_history || health.health_history.length===0 ) ? health.health_history : [],
        familyHealthHistory: (health.family_health_history || health.family_health_history.length===0 ) ? 
          health.family_health_history : [],
        medicationAdherence: (health.medication_adherence) ? 
          health.medication_adherence.map(item => ({
            id: item.id, date: new Date(item.date), frequency: item.frequency, medication: item.medication, status: item.status
        })) : []
      })

      cookies.set("shared", true, { path: '/' })
      cookies.set("sharedRefreshToken", health.access_token, { path: '/' })
      cookies.set("sharedAccessToken", health.access_token, { path: '/' })
      cookies.set("sharedUserId", health.access_token, { path: '/' })
    } else {
      requestSharedData()
    }
  }, [])

  React.useEffect(() => {

    if (userData) {
      setUserHealth({
        userId: userData.user_id,
        name: `${userData.first_name} ${userData.last_name}`,
        age: `${userData.age}`,
        smoking: userData.smoking_status ? `${userData.smoking_status}` : 'Não informado',
        organ_donation: userData.organ_donation ? userData.organ_donation : 'Não declarado',
        blood_type: userData.organ_donation ? userData.blood_type : 'Não informado',
        sus_number: userData.sus_number ? userData.sus_number : 'Não informado',
        emergency_contact: (userData.emergency_contact.name)
          ? userData.emergency_contact : {name: "Não informado", phone_number: null},
        insurance: userData.insurance ? userData.insurance : [],
        medication: (userData.medication || userData.medication.length===0 ) ? userData.medication : [],
        allergy: (userData.allergy || userData.allergy.length===0 ) ? userData.allergy : [],
        exam: (userData.exams || userData.disease.exams===0 ) ? 
          userData.exams.map(exam => ({...exam, show: false}))  
          : [],
        prescription: (userData.prescriptions) ? 
          userData.prescriptions : {},
        vaccine: (userData.vaccines) ? 
          userData.vaccines : [],
        disease: (userData.disease || userData.disease.length===0 ) ? userData.disease : [],
        nextAppointment: userData.next_appointment ? `${userData.next_appointment}` : 'Não informado',
        height: userData.height ? `${userData.height}` : 'Não informado',
        weight: userData.weight ? `${userData.weight}` : 'Não informado',
        bmi: userData.bmi ? `${userData.bmi}` : null,
        healthHistory: (userData.health_history || userData.health_history.length===0 ) ? userData.health_history : [],
        familyHealthHistory: (userData.family_health_history || userData.family_health_history.length===0 ) ? 
          userData.family_health_history : [],
        medicationAdherence: (userData.medication_adherence) ? 
          userData.medication_adherence.map(item => ({
            id: item.id, date: new Date(item.date), frequency: item.frequency, medication: item.medication, status: item.status
        })) : []
      })

      cookies.set("shared", true, { path: '/' })
      cookies.set("sharedRefreshToken", userData.access_token, { path: '/' })
      cookies.set("sharedAccessToken", userData.access_token, { path: '/' })
      cookies.set("sharedUserId", userData.access_token, { path: '/' })
    }

  }, [userData])

  console.log(userHealth)

  const ToggleExamShow = (id) => {
    // create a show tag for the exams
    if (userHealth.exam) {
      setUserHealth(prev => {
        const newArray = userHealth.exam

        newArray[id].show = !userHealth.exam[id].show
        return {...prev, exam: newArray}
      })
    }
  }

  const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' }

  const userBmiCard = <UserBmiCard bmi={userHealth.bmi} simple={true}/>


  let vaccines = []
  if (userHealth.vaccine) {
    vaccines = userHealth.vaccine.map((item, index) => {
      return (
        <VaccineCard
          vaccine={item} 
          listId={index}
          shared={true}
        />
      )
    })
  }

  let prescriptionToVerify = false
  let prescriptions = []
  let medications = []
  if (userHealth.prescription) {
    Object.keys(userHealth.prescription).forEach((key, index) => {
      
      if (key !== 'no_prescription') {
        if (userHealth.prescription[key].confirmed === false && userHealth.prescription[key].data_extracted === true) {
          prescriptionToVerify = true
        }
        prescriptions.push(
          <PrescriptionCard 
            prescription={userHealth.prescription[key]}
            listId={index}
            id={key}
            shared={true}
          />
        )
        
        if (userHealth.prescription[key].confirmed && userHealth.prescription[key].medication.length > 0) {
          userHealth.prescription[key].medication.forEach((element, index) => {
              medications.push(
                <MedicationCard
                  medication={element}
                  medicationIndex={index}
                  prescriptionId={key}
                  prescriptionExternalFile={userHealth.prescription[key].external_filename}
                  shared={true}
                />
              )
            }
          )
        }
      } else {
        userHealth.prescription[key].forEach((element, index) => {
          medications.push(
            <MedicationCard
              medication={element}
              medicationIndex={index}
              prescriptionId={null}
              shared={true}
            />
          )
        })
      }
    })
  }

  return (
    (!userHealth.name) ? 
    <div className="icon-loading-middle-page">
      <img className="small-logo-nav loading-icon" 
          src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
      <p>Carregando página...</p> 
    </div>
    :  
    <>
      <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
        <Header tabletBreakpoint={tabletBreakpoint} menuItems="shared" menuShow={true}/>
      </div>
      <>
      <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>


        <p className="florence-title top-margin-xl bottom-margin-xl">Dados compartilhados</p>

        <div className="bottom-margin-m">
            <div className="bottom-margin-m">
              <HighlightedMessage icon={Attention} type="warning" header="Atenção" body="Credenciais para acessar dados compartilhados expiram 24 horas após sua criação" />
            </div>
        </div>

        <EditableItemsCard2 
          clsName="bottom-margin-l"
          title="Dados gerais"
          icons={false}
        >
          <EditableItemForCard2>
            <p className="body"><strong>Nome: </strong>{userHealth.name}</p>
          </EditableItemForCard2>
          <EditableItemForCard2>
            <p className="body"><strong>Idade: </strong>{userHealth.age} anos</p>
          </EditableItemForCard2>

          <EditableItemForCard2 edit={false} >
            <p className="body"><strong>Fumante: </strong>{userHealth.smoking}</p>
          </EditableItemForCard2>
        </EditableItemsCard2>


        <EditableItemsCard2 
          clsName="bottom-margin-l"
          title="Emergência"
          icons={false}
        >
          <EditableItemForCardEditSelection 
                edit={false} >
            <p className="body"><strong>Tipo sanguíneo: </strong>{userHealth.blood_type}</p>
          </EditableItemForCardEditSelection>

          <EditableItemForCardEditSelection 
                edit={false} >
            <p className="body"><strong>Doador de orgãos: </strong>{userHealth.organ_donation}</p>
          </EditableItemForCardEditSelection>

          <EditableItemForCardCustomEdit
              edit={false}>
            <div>
              <p className="body"><strong>Contato de emergência: </strong>{userHealth.emergency_contact.name}</p>
              {(userHealth.emergency_contact.phone_number !== null) ?
              <p className="secondary-text">Telefone: {userHealth.emergency_contact.phone_number}</p> : null
              }
            </div>
          </EditableItemForCardCustomEdit>
        </EditableItemsCard2>


        <EditableItemsCard2 
          clsName="bottom-margin-l"
          title="Planos de saúde"
          icons={false}
        >
          <EditableItemForCard2 edit={false} >
            <p className="body"><strong>Cartão SUS: </strong>{userHealth.sus_number}</p>
          </EditableItemForCard2>
              
          {(userHealth.insurance) ? 
            userHealth.insurance.map((item, i) => ([
              <EditableItemForCard2 key={i} edit={false} >
                <div key={i}>
                  <p className="body">{item.name}</p> 
                  <p className="secondary-text">{
                    `Número de registro: ${item.number}`
                  }
                  </p>
                </div>
              </EditableItemForCard2>
            ])) : null 
          }

        </EditableItemsCard2>

        <EditableItemsCard2 
          clsName="bottom-margin-l" 
          title="Alergias"
          icons={false}
        >
          <div>
            {
            (userHealth.allergy) ? userHealth.allergy.map((allergy, i) => ([
                <EditableItemForCard2 key={i} edit={false} >
                  <div key={i}>
                    <p className="body">{allergy.allergy}</p> 
                  </div>
                </EditableItemForCard2>
              ])) : null 
            }
          </div>
        </EditableItemsCard2>

        <EditableItemsCard2 
          clsName="bottom-margin-l" 
          title="Doenças"
          icons={false}
        >
          <div>
            {
            (userHealth.disease) ? userHealth.disease.map((disease, i) => ([
                <EditableItemForCard2 key={i} edit={false} >
                  <div key={i}>
                    <p className="body">{disease.disease}</p> 
                  </div>
                </EditableItemForCard2>
              ])) : null 
            }
          </div>
        </EditableItemsCard2>

        <EditableItemsCard2 
          clsName="bottom-margin-l"
          title="Prescrições Médicas"
          icons={false}
        >
          <div>
            {
              (userHealth.prescription) ?
                    prescriptions
                    : null
            }
          </div>
        </EditableItemsCard2>

        <EditableItemsCard2 
          clsName="bottom-margin-l"
          title="Medicamentos"
          icons={false}
        >
          <div>
            {
              (userHealth.medication) ? medications : null 
            }
          </div>
        </EditableItemsCard2>

          <EditableItemsCard2 
            clsName="bottom-margin-l"
            title="Medidas corporais"
            icons={false}
          >
            <EditableItemForCard2 edit={false} >
              <p className="body"><strong>Altura (m): </strong>{userHealth.height}</p>
            </EditableItemForCard2>
            <EditableItemForCard2 edit={false} >
              <p className="body"><strong>Peso (kg): </strong>{userHealth.weight}</p>
            </EditableItemForCard2>
            {userBmiCard}
          </EditableItemsCard2>

          <EditableItemsCard2 
            clsName="bottom-margin-l"
            title="Exames Médicos"
            icons={false}
          >
            <div>
              {
                (userHealth.exam) ?
                userHealth.exam.map((exam, i) => (
                  <ExamCard 
                      key={i} 
                      exam={exam} 
                      toggleShowExam={ToggleExamShow} 
                      listId={i} 
                      id={exam.id} 
                      patientId={null}
                      shared={true}
                    />
                )) : null
              }
            </div>
          </EditableItemsCard2>

          <EditableItemsCard2 
            clsName="bottom-margin-l"
            title="Vacinação"
            icons={false}
          >
            <div>
              { vaccines }
            </div>
          </EditableItemsCard2>

          <EditableItemsCard2 
            clsName="bottom-margin-l"
            title="Histórico de Saúde"
            icons={false}
          >
            <div>
              {
              (userHealth.healthHistory) ? userHealth.healthHistory.map((item, i) => ([
                  <EditableItemForCard2 key={i} edit={false}>
                    <div key={i}>
                      <p className="body">{item.description}</p> 
                      <p className="secondary-text">{
                        `Adicionado em ${new Date(item.created).toLocaleDateString('pt-BR', dateOptions)}`
                      }
                      </p>
                    </div>
                  </EditableItemForCard2>
                ])) : null 
              }
            </div>
          </EditableItemsCard2>

          <EditableItemsCard2 
            clsName="bottom-margin-l"
            title="Histórico Familiar"
            icons={false}
          >
            <div>
              {
              (userHealth.familyHealthHistory) ? userHealth.familyHealthHistory.map((item, i) => ([
                  <EditableItemForCard2 key={i} edit={false} >
                    <div key={i}>
                      <p className="body">{item.description}</p> 
                      <p className="secondary-text">{
                        `Adicionado em ${new Date(item.created).toLocaleDateString('pt-BR', dateOptions)}`
                      }
                      </p>
                    </div>
                  </EditableItemForCard2>
                ])) : null 
              }
            </div>
          </EditableItemsCard2>

      </div>
      </>
    </>
  )
}
