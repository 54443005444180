
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post10() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Dúvida saúde - diabetes - O tratamento de diabetes é diferente para cada pessoa</title>
        <meta 
          name="description" 
          content="Conversando com outras pessoas que também vivem com diabetes, uma coisa que fui notando é que o tratamento dessa doença é bastante individual para cada pessoa: a dieta que funciona para cada um é diferente, e o mesmo vale para a rotina de exercícios e regime de medicamentos.

Mas uma coisa que é igual para todos e que não muda é que na diabetes nós (o paciente, a pessoa com diabetes) precisa participar ativamente do tratamento. Eu acredito que é importantíssimo tomar o controle, aprender tudo que você pode sobre diabetes, e aprender também quais dietas e exercícios funcionam melhor para você."/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            T. Estudante &#183; 24/07/2024
          </p>
        </div>
        <p className="heading4 bottom-margin-s">O tratamento de diabetes é diferente para cada pessoa</p>


        <p className="body content bottom-padding-s">
            Conversando com outras pessoas que também vivem com diabetes, uma coisa que fui notando é que o tratamento dessa doença é bastante individual para cada pessoa: a dieta que funciona para cada um é diferente, e o mesmo vale para a rotina de exercícios e regime de medicamentos.<br/><br/>Mas uma coisa que é igual para todos e que não muda é que na diabetes nós (o paciente, a pessoa com diabetes) precisa participar ativamente do tratamento. Eu acredito que é importantíssimo tomar o controle, aprender tudo que você pode sobre diabetes, e aprender também quais dietas e exercícios funcionam melhor para você.
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/10')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  1 pessoa achou útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi! Você está absolutamente certo. O diabetes é uma condição que varia muito de pessoa para pessoa, e o que funciona para um pode não funcionar para outro. Isso faz com que seja essencial um acompanhamento individualizado.<br/><br/>Participar ativamente do tratamento faz toda diferença! Conhecer melhor a própria condição, como seu corpo reage aos alimentos e ao exercício, e entender seus medicamentos pode melhorar muito sua qualidade de vida. Além disso, seguir as orientações médicas é fundamental para o sucesso do tratamento.<br/><br/>Parabéns por compartilhar essa mensagem positiva e de incentivo! Abraços!
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 06/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/10')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariana Zandona &#183; 24/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/150-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi gente. Depois que comecei a tomar glifage meu cabelo começou a cair muito, sera que é o medicamento?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/150')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Bruna Almeida &#183; 16/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/112-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal boa tarde minha hemoglobina glicada deu 13.5, ta muito alta?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/112')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Elisa Orta &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/47-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, boa tarde<br/><br/>Gostaria de saber se 5 seções de rádioterapia são capazes de reduzir as dores no corpo causadas pelo câncer ?<br/><br/>Muito obrigado!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/47')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariano Oliveira &#183; 19/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/133-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Algum chá para pressão alta
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/133')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Paula Farber &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/28-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, tenho diabetes tipo 2 e dor nas pernas, muita ardência a noite. Vocês já tiveram isso? Já fizeram uso de vitamina B12 e D e ajudou?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/28')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        2 comentários
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
