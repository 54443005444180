
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post145() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi gente
Fui a minha primeira consulta com mastologista oncológico, meu tipo é triplo negativo.
Vou começar o tratamento com quimioterapia, alguém com esse mesmo tipo? Como tem sido o tratamento? Obrigado.</title>
        <meta 
          name="description" 
          content="Oi gente
Fui a minha primeira consulta com mastologista oncológico, meu tipo é triplo negativo.
Vou começar o tratamento com quimioterapia, alguém com esse mesmo tipo? Como tem sido o tratamento? Obrigado."/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Francisca Duarte &#183; 22/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi gente<br/>Fui a minha primeira consulta com mastologista oncológico, meu tipo é triplo negativo.<br/>Vou começar o tratamento com quimioterapia, alguém com esse mesmo tipo? Como tem sido o tratamento? Obrigado.
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/145')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  3 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Francisca,<br/><br/>Primeiramente, quero te parabenizar por ter dado o primeiro passo em ir à consulta e buscar entender mais sobre seu tratamento. O câncer de mama triplo negativo pode realmente parecer assustador, mas lembre-se que você não está sozinha nessa.<br/><br/>Cada pessoa reage de uma forma diferente ao tratamento com quimioterapia, mas vou compartilhar algumas experiências comuns que outras pacientes frequentemente mencionam. Muitas vezes, os sintomas incluem náuseas, fadiga e queda de cabelo. No entanto, cada corpo é único, e seu médico irá personalizar o seu tratamento para atender melhor as suas necessidades.<br/><br/>Uma coisa que pode ajudar é manter uma rotina de hidratação e, se possível, uma alimentação saudável e balanceada. Não hesite em compartilhar qualquer desconforto ou efeitos colaterais com seu médico, pois eles têm formas de amenizar esses sintomas e ajudar você a se sentir melhor.<br/><br/>Também é muito importante contar com a rede de apoio, seja ela família, amigos ou grupos de apoio como esse. Conversar com pessoas que estão passando ou já passaram pelo mesmo pode ser muito reconfortante.<br/><br/>Vou deixar essa conversa aberta para que outras pessoas possam compartilhar suas experiências com você. Assim, você terá uma visão mais abrangente de como pode ser o seu caminho.<br/><br/>Se precisar de algo mais ou tiver outras dúvidas, estou aqui para ajudar.<br/><br/>Um grande abraço e força nesse novo capítulo da sua vida!<br/><br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 22/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/145')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Tami Costa &#183; 22/07/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/3-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Compartilhando sua vida com uma doença crônica - recomendação de livro  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Há alguns meses, pesquisando livros sobre doenças crônicas, recebi a recomendação do livro Pain Woman Takes Your Keys, and Other Essays from a Nervous System (infelizmente sem versão em português ao meu conhecimento). Diferentemente do que estava procurando, que seria um livro de informações e fatos sobre doenças crônicas, estilo livro acadêmico, Pain Woman é um livro que relata de forma íntima e direta a experiência de desenvolver uma doença crônica, e se deparar que você vai dividir o resto de sua vida com ela. Que o resultado disso é um novo você.<br/><br/>A autora do livro, Sonya Huber, relata através de uma série de textos curtos (ou essays) sua experiência desde a aparição da doença artrite reumatóide, do seu desenvolvimento e de todo impacto que ela teve em sua vida. Ela descreve as dificuldades emocionais e sociais que isso causou, sua revolta e por fim o seu entendimento dessa nova companheira que agora era parte de si mesma.<br/><br/>O livro é bastante pessoal e nos faz conhecer Sonya e nos tornarmos de alguma forma seu amigo, da Sonya pré-artrite reumatóide e pós-artrite reumatóide. Bastante intenso e expressivo, é um dos melhores relatos que conheço do que é viver com uma doença crônica, e recomendo para todos que querem conhecer essa realidade, para lidar com a sua própria ou para melhor empatizar com alguém que está passando por isso a sua volta.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/3')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        Nenhum comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariano Oliveira &#183; 13/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/82-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, bom dia! Alguém aqui já viu nos exames o termo opacidade nodular? Pode me ajudar com oque significa.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/82')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Leana de Sá &#183; 22/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/146-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa noite. Alguém tem coceiras nas pernas provocadas pelo diabetes? Que remédio usam para tratar?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/146')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Luíza Castro &#183; 17/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/118-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá, o que aconselham a dar de café da manhã para uma criança de 10 anos com diabetes tipo 1?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/118')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Maria de Lourdes &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/40-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal,<br/>Tenho pressão alta e hoje de manhã fiquei nervosa e ela subiu <br/>Tomei Losartana, hidroclorotiazida um diazepam e um anlodipino e um antidepressivo<br/>Agora a pressão está em 12/7 mas os batimentos cardíacos estão em 107<br/>É normal isso?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/40')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
