
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post84() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Olá amigos,
O que usar nas alergias pós químio vermelha? Vocês tem alguma sugestão?
Minha mãe está nas virilhas bem vermelha e com coceira.
O médico não indicou nada , mas já vi outras pessoas com mesmos sintomas que usaram algo para aliviar, mas não sei oque.</title>
        <meta 
          name="description" 
          content="Olá amigos,
O que usar nas alergias pós químio vermelha? Vocês tem alguma sugestão?
Minha mãe está nas virilhas bem vermelha e com coceira.
O médico não indicou nada , mas já vi outras pessoas com mesmos sintomas que usaram algo para aliviar, mas não sei oque."/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Vitor Machado &#183; 13/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Olá amigos,<br/>O que usar nas alergias pós químio vermelha? Vocês tem alguma sugestão?<br/>Minha mãe está nas virilhas bem vermelha e com coceira.<br/>O médico não indicou nada , mas já vi outras pessoas com mesmos sintomas que usaram algo para aliviar, mas não sei oque.
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/84')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  4 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Olá Vitor,<br/><br/>Sinto muito saber que sua mãe está passando por isso. As alergias e irritações na pele após a quimioterapia podem ser bem incomodativas. Mesmo que outras pessoas possam ter usado algo para aliviar, é essencial não aplicar medicamentos sem a orientação do médico, especialmente com a pele tão sensível nesses casos.<br/><br/>Uma coisa que pode ajudar e é segura é manter a área bem limpa e seca. Você pode usar um sabão neutro e evitar roupas apertadas ou tecidos que irritem ainda mais a pele. Também pode ajudar aplicar compressas frias (um pano molhado em água fria, por exemplo) para amenizar a coceira e a vermelhidão.<br/><br/>Entretanto, é muito importante comunicar ao médico sobre essa reação. Mesmo que ele não tenha indicado nada inicialmente, ele precisa estar ciente de como ela está reagindo ao tratamento para poder orientá-los corretamente.<br/><br/>Desejo muita força para você e sua mãe.<br/><br/>Abraço,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 13/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/84')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marcos Rosebrock &#183; 16/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/110-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Uma dúvida: Pólipos nos pulmões, o maior sendo 8 milímetros. Pode ser metástase?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/110')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Joana Costa &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/54-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Essa semana chegou a última quimioterapia, e vou na médica também pra ver resultado dos exames<br/>Até aqui o senhor me acompanhou e mim ajudou.<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/54')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        2 comentários
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Manuela Escobar &#183; 23/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/148-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom tarde<br/>Gostaria de tirar uma dúvida, caneta pra medir glicemia pode ser usada várias vezes?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/148')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marília Dornelles &#183; 13/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/87-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia pessoal. Estou com diabetes gestacional. Como posso abaixar a diabetes através da alimentação?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/87')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Tomaz Silveira &#183; 13/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/81-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, já fazem suas semanas que meço minha glicose em jejum a após fazer 1 hora de musculação e 50 minutos de caminhada, e quando meço antes do almoço sem comer nada, ela aumenta entre 30 e 40 pontos.<br/><br/>Estou estranhando porque isso nunca aconteceu antes, sempre reduzia cerca de 20 a 30 pontos após os exercícios, mas semana passada de 210 em jejum foi pra 265 após os exercícios e hoje 235 foi  pra 262 após exercícios. <br/><br/>Oque vocês acham?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/81')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
