
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post62() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Alguém aqui não teve efeito colateral com a sinvastatina? Minha médica me deu receita para 1 ano e disse que terei que tomar o resto da vida, pois tenho histórico de colesterol alto na família. O problema é que só leio coisas ruins sobre esse remédio</title>
        <meta 
          name="description" 
          content="Alguém aqui não teve efeito colateral com a sinvastatina? Minha médica me deu receita para 1 ano e disse que terei que tomar o resto da vida, pois tenho histórico de colesterol alto na família. O problema é que só leio coisas ruins sobre esse remédio"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Neila Gabler &#183; 10/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Alguém aqui não teve efeito colateral com a sinvastatina? Minha médica me deu receita para 1 ano e disse que terei que tomar o resto da vida, pois tenho histórico de colesterol alto na família. O problema é que só leio coisas ruins sobre esse remédio
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/62')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  1 pessoa achou útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Olá, Neila!<br/><br/>Entendo suas preocupações em relação à sinvastatina, especialmente ao ler sobre possíveis efeitos colaterais. É importante lembrar que cada pessoa reage de maneira diferente aos medicamentos. Muitos pacientes tomam sinvastatina sem sofrerem efeitos colaterais significativos.<br/><br/>A sinvastatina é um medicamento bastante prescrito para ajudar a controlar o colesterol e reduzir o risco de doenças cardíacas, especialmente para quem tem histórico familiar como você mencionou. Os benefícios de manter o colesterol sob controle geralmente superam os possíveis efeitos colaterais.<br/><br/>Se você estiver preocupada, sugiro conversar novamente com sua médica e discutir essas preocupações. Ela pode ajudar você a entender os riscos e benefícios do tratamento e, se necessário, pode ajustar a dose ou considerar outras opções.<br/><br/>Enquanto isso, mantenha hábitos saudáveis, como uma alimentação equilibrada e exercícios regulares, pois eles também são fundamentais para o controle do colesterol.<br/><br/>Fique tranquila e sempre que precisar, estamos aqui para ajudar!<br/><br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 10/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/62')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Carla Esteves &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/52-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa noite pessoal. <br/>Alguém que está fazendo quimioterapia sente os pés dormentes e as pontas dos dedos das mãos? <br/>Meus pés estão muito dormentes, e está aumentando. Estou na quinta sessão de quimio
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/52')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Neila Gabler &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/32-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    E a visão pessoal, sentiram que piorou com o aparecimento do colesterol?? Eu estou passando por isso infelizmente!! Oque vocês fizeram?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/32')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Bruna Almeida &#183; 16/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/112-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal boa tarde minha hemoglobina glicada deu 13.5, ta muito alta?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/112')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Francisca Duarte &#183; 22/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/145-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi gente<br/>Fui a minha primeira consulta com mastologista oncológico, meu tipo é triplo negativo.<br/>Vou começar o tratamento com quimioterapia, alguém com esse mesmo tipo? Como tem sido o tratamento? Obrigado.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/145')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Gabrielli Machado &#183; 11/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/70-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Alguém pra indicar um adoçante que seja melhor pra quem tem diabetes?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/70')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
