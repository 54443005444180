import React from 'react'
import { useNavigate } from "react-router-dom"
import { postRequestToApi } from '../api';
import Button from '../components/Button'
import HighlightedMessage from '../components/HighlightedMessage';
import Attention from "../images/sun.svg"

import AuthContext from '../context/AuthProvider'

export default function DataAccessBlock() {
  
  const { cookies } = React.useContext(AuthContext);

  const userRef = React.useRef();
  const navigate = useNavigate()

  const [loginFormData, setLoginFormData] = React.useState({
    name: "", code: ""
  })
  const [userData, setUserData] = React.useState(null)
  const [status, setStatus] = React.useState("idle")
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    if (userData) {
      cookies.set("sharedName", loginFormData.name, { path: '/' })
      cookies.set("sharedCode", loginFormData.code, { path: '/' })
      // navigate to data access page
      navigate("/sharedhealth", {state: {health: userData}})
    }
  }, [userData])
  
  async function requestSharedData() {
    try {
      setStatus("submitting")
      const response = await postRequestToApi({
          'first_name': loginFormData.name,
          'code': loginFormData.code,
        }, 
        'access_shared', { refreshToken: 'noneed', accessToken: 'noneed' })
      setUserData(response)

    } catch(err) {
      console.log(err)
      setError("Credenciais inválidas ou expiradas")
      setStatus("idle")
    } finally {
      setStatus("idle")
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setError(null)

    console.log(loginFormData)
    if (loginFormData.name === "" || loginFormData.code === "") {
      setError("Por favor, preencha todos os campos do formulário")
      return
    }

    requestSharedData()
  }

  React.useEffect(() => {
    userRef.current.focus()
    
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target

    setLoginFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }


  return (
    <div className="login-container horizontal-padding-l">

      {(error) ?
        <div className="top-padding-xs">
          <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} />  
        </div>
        : null }

      <form onSubmit={handleSubmit} className="login-form">
        <p className="body top-padding-s">Primeiro nome</p>
        <input
          className="input-text bottom-margin-xs"
          ref={userRef}
          name="name"
          onChange={handleChange}
          type="text"
          placeholder="Digite o nome do usuário"
          value={loginFormData.email}
        />
        <p className="body">Código</p>
        <input
          className="input-text bottom-margin-xs"
          name="code"
          onChange={handleChange}
          type="text"
          placeholder="Digite o código de acesso"
          value={loginFormData.password}
        />
        
        <div>
          {(status)==="idle" ? 
            <Button type="primary bottom-margin-l">Acessar dados</Button> :
            <Button type="primary bottom-margin-l">Acessando...</Button> }
        </div>
      </form>
      
    </div>
  )
}
