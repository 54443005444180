
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post142() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Hoje fui no meu endocrinologista e ele mudou meu medicamento que já tomava faz tempo cloridrato de pioglitasona 15mg por forxiga 10mg. Reparei que a diferença de preço é grande. Esse Forxiga é muito melhor?</title>
        <meta 
          name="description" 
          content="Hoje fui no meu endocrinologista e ele mudou meu medicamento que já tomava faz tempo cloridrato de pioglitasona 15mg por forxiga 10mg. Reparei que a diferença de preço é grande. Esse Forxiga é muito melhor?"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Camila da Silveira &#183; 21/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Hoje fui no meu endocrinologista e ele mudou meu medicamento que já tomava faz tempo cloridrato de pioglitasona 15mg por forxiga 10mg. Reparei que a diferença de preço é grande. Esse Forxiga é muito melhor?
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/142')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  3 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi, Camila!<br/><br/>Entendo a sua preocupação com a diferença de preço. A troca de medicamentos pode ser um pouco assustadora, especialmente quando estamos acostumados com o que já tomamos por bastante tempo. O Forxiga (dapagliflozina) é um tipo diferente de medicamento e age de forma distinta no controle do diabetes. Ele ajuda a eliminar glicose pela urina, o que pode ser benéfico para algumas pessoas.<br/><br/>Cada pessoa responde de maneira diferente aos medicamentos, então o seu endocrinologista provavelmente considerou vários fatores antes de fazer essa mudança, como sua saúde geral, resultados de exames e até possíveis efeitos colaterais. O que é mais importante é que você se sinta bem e que seu diabetes esteja bem controlado.<br/><br/>Se o custo é uma preocupação significativa, converse com seu médico sobre isso. Existem programas de assistência e genéricos que podem ajudar a reduzir os custos. Além disso, às vezes é possível encontrar descontos ou até participação em programas de apoio ao paciente oferecidos pelo fabricante.<br/><br/>Continue se observando e mantenha seu médico informado sobre como você está se sentindo com o novo medicamento. Qualquer dúvida, não hesite em perguntar! Estamos aqui para ajudar 😊<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 21/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/142')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marlene Techini &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/35-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal<br/>Alguém trocou propranolol por metoprolol?<br/>Tem alguma diferença significativa?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/35')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mario César &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/97-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Estou bastante triste, descobri que estou com catarata por conta da diabete segundo o oftalmologista. Não consigo entender muito bem, mantenho a glicose controlada, jejum 105, prandial deu até menos, 100 glicada 6.4. Estou preocupado.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/97')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            6 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Fernando Pessoa &#183; 18/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/128-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Com quantos anos começaram a tomar remédio de hipertensão?<br/>Quantos remédios tomam por dia?<br/>Tenho 25 anos, e não consigo aceitar que sou
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/128')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marli Seccon &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/65-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, queria uma opinião. A médica me disse que meu colesterol tá alto e me passou sinvastatina. Meus resultados estão o seguinte: Ldl 131, Colesterol total 218, Triglicerídeos 65, Hdl 71. Oque vcs acham?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/65')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marcela Prattes &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/66-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Poderiam me indicar alguma dieta pra café da manhã, almoço e janta. Estou com trigliceridios de 170
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/66')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
