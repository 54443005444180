
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post60() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoal, me deem um conselho. Quantas vezes ao dias vocês medem a glicemia? Vocês chegam a medir com maior frequência quando sentem dores ou formigamento em alguma parte do corpo, pra ver se tem a ver com a diabetes?
</title>
        <meta 
          name="description" 
          content="Oi pessoal, me deem um conselho. Quantas vezes ao dias vocês medem a glicemia? Vocês chegam a medir com maior frequência quando sentem dores ou formigamento em alguma parte do corpo, pra ver se tem a ver com a diabetes?
"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Rita Anton &#183; 10/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoal, me deem um conselho. Quantas vezes ao dias vocês medem a glicemia? Vocês chegam a medir com maior frequência quando sentem dores ou formigamento em alguma parte do corpo, pra ver se tem a ver com a diabetes?<br/>
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/60')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  2 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi, Rita!<br/><br/>Entendo sua preocupação, realmente é importante manter o controle da glicemia para gerenciar bem o diabetes. A quantidade de vezes que a glicemia deve ser medida pode variar de pessoa para pessoa, dependendo do tipo de diabetes e das orientações do médico.<br/><br/>Geralmente, algumas pessoas medem a glicemia:<br/><br/>1. Antes das refeições<br/>2. 1-2 horas após as refeições<br/>3. Antes de dormir<br/>4. Eventualmente, durante a madrugada<br/><br/>Se você está sentindo dores ou formigamento, é uma boa ideia medir a glicemia, sim, para verificar se está dentro dos valores recomendados. Mas é fundamental conversar com seu médico sobre esses sintomas, pois dores e formigamentos podem ter várias causas, e só um profissional de saúde pode avaliar corretamente.<br/><br/>Lembre-se de manter o contato regular com seu médico e seguir as orientações dele sobre a frequência da medição da glicemia. E claro, se tiver mais dúvidas ou precisar de mais conselhos, estamos aqui para ajudar!<br/><br/>Um abraço e cuide-se bem!<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 10/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/60')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Paula Farber &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/59-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa tarde pessoal, meu médico me mandou tomar cloridrato clomipramina 25mg pra pressão alta, mas não estou me adaptando. Tomo depois do almoço, mas estou tendo insônia e ele me deixa meio lenta, meio lesada. E não está resolvendo a pressão também. Alguém aqui toma ele?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/59')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Elisa Orta &#183; 14/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/91-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá, boa tarde pessoa. Uma pessoa da minha familia esta com colangiocarcinoma<br/>(Cancer nas vias biliares), e está muito magro e não consegue comer. Ele vai passar pela primeira vez no oncologista semana que vem mas já está bem fraco. Alguém aqui com esse tipo de experiência?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/91')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    B. Franklin &#183; 23/07/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/4-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                     O Reino Invisível das Doenças Crônicas  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Estava lendo o livro The Invisible Kingdom (Meghan O'Rourke), com o intuito de conhecer a experiência de outras pessoas que vivem com doenças crônicas, e ele traz muitos pontos que valem a atenção.<br/><br/>A escritora conta sua experiência com o surgimento de uma doença de difícil diagnóstico, onde devido de seus exames darem resultados normais ou sem um diagnóstico definido, os médicos tendiam a falar que sua doença era psicológica, como ansiedade ou depressão, ou simplesmente que não havia nada de errado com ela. Mas com certeza, pelo que estava sentindo, ela sabia que alguma coisa não estava certa.<br/><br/>Durante toda a trajetória que é contada até ela chegar a um diagnóstico tardio da doença de Lyme, que é transmitida por picada de carrapato, o livro traz muitos pontos importantes:<br/><br/>A dificuldade que médicos têm em lidar com pacientes com doenças crônicas, ou quando não tem certeza do diagnóstico do paciente, e a tendência de tentar uma saída fácil, diagnosticado como doença mental seja lá o que o paciente tenha. E por fim, a falta de empatia e de escuta ativa do sistema de saúde, e o quão sozinho esses pacientes se sentem.<br/><br/>Adicionalmente, a escritora retrata os problemas que o que ela medicina em silos gera para a experiência desses pacientes, que ficam sendo encaminhados de um especialista para o outro, onde nenhum tenta ter a visão do todo, ou do paciente como uma pessoa completa, se atendo somente a sua área de especialidade.<br/><br/>O livro é bastante longo, porém com muitas informações úteis e que podem ajudar ou servir de alento para pessoas na mesma situação.<br/><br/>Quem convive com doenças crônicas por períodos longos e sem um diagnóstico definido se sente sozinho e muitas vezes se culpa pela sua doença. Mas vocês não estão sozinhos, muitas outras pessoas estão com vocês e passando pela mesma experiência.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/4')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        Nenhum comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mario César &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/61-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoa boa tarde<br/>Tem problema eu comer pão integral com uma fatia de queijo meu trigliceridios está 168. Agradeço
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/61')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mario César &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/97-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Estou bastante triste, descobri que estou com catarata por conta da diabete segundo o oftalmologista. Não consigo entender muito bem, mantenho a glicose controlada, jejum 105, prandial deu até menos, 100 glicada 6.4. Estou preocupado.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/97')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            6 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
