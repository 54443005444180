
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post67() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoal
Alguém que teve câncer de mama com metástase no pulmão?
Poderiam me contar um pouco
Teve sintomas?
Conseguiu cura com alguma medicação? Qual tratamento fez? Operou?
</title>
        <meta 
          name="description" 
          content="Oi pessoal
Alguém que teve câncer de mama com metástase no pulmão?
Poderiam me contar um pouco
Teve sintomas?
Conseguiu cura com alguma medicação? Qual tratamento fez? Operou?
"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Karla Geise &#183; 10/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoal<br/>Alguém que teve câncer de mama com metástase no pulmão?<br/>Poderiam me contar um pouco<br/>Teve sintomas?<br/>Conseguiu cura com alguma medicação? Qual tratamento fez? Operou?<br/>
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/67')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  2 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Olá, Karla!<br/><br/>Eu entendo completamente sua preocupação e desejo de ouvir histórias de outras pessoas que estão passando pela mesma situação. Primeiro, gostaria de oferecer meu apoio e dizer que você não está sozinha nessa jornada.<br/><br/>Cada caso de câncer é único, então os sintomas e tratamentos podem variar. Algumas pessoas com metástase no pulmão experimentam falta de ar, tosse persistente ou dor no peito, mas esses sintomas podem ser diferentes de pessoa para pessoa.<br/><br/>Sobre o tratamento, existem diversas opções dependendo do estágio e das características específicas do câncer. Isso pode incluir medicação, como quimioterapia ou terapia-alvo, radioterapia, e em alguns casos, cirurgia. A escolha do tratamento ideal é algo que precisa ser discutido cuidadosamente com seu oncologista, que conhece todos os detalhes do seu caso.<br/><br/>Enquanto aguarda respostas de outras pessoas no grupo, lembre-se sempre de conversar abertamente com seu médico sobre suas dúvidas e preocupações. Ele(a) poderá fornecer orientações personalizadas e atualizadas sobre o seu tratamento.<br/><br/>Se precisar de mais alguma coisa, estou aqui para ajudar!<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 10/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/67')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Paula Farber &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/94-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Alguém com diabetes, que tem fezes moles e diarréia constante?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/94')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Michele Lombardi &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/103-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, meu filho tem 10 anos e está com triglicerídeos e colesterol mais alto que deveria. Gostaria de saber como posso fazer melhor a alimentação dele, oque pode e não pode comer. Obrigado.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/103')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        2 comentários
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Carla Chagas &#183; 14/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/89-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá, eu gostaria de saber mais sobre neuropatia
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/89')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Neila Gabler &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/46-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, se puderem me ajudar aqui...<br/><br/>Fiz os meus exames médicos apontam, e estou com os seguintes resultados:<br/><br/>-Colesterol de Total- 220 mg/dL.<br/>-Triglicerideos- 195 mg/dL.<br/><br/>Qual remédio devo tomar?<br/><br/>Desde já agradeço!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/46')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            Esse post foi útil?
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marlene Techini &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/100-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, bom dia. Eu faço uso somente da insulina NPH e simplesmente não abaixa nada. Tomo ela faz um tempo e após 2hrs da refeição continua alta e em jejum tbm. Será que tenho que trocar essa insulina? Ela é fraca? <br/><br/>Aprendi a fazer contagem de carboidratos tbm e não está resolvendo. Alguém que entenda sobre essa insulina poderia me explicar o que ocorre?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/100')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
