
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post79() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoal, sou hipertensa e hoje minha pressão diastólica não saiu do 10 ou 9 ....
A sistólica fica entre 12 e 13 e a diastólica entre 9 e 10.
Oque vocês acham que eu devo fazer?
Já tomei o remédio da pressão as 18 horas tomo a cada 12 horas, mas tenho ansiedade e não estou nem conseguindo dormir porque a pressão não abaixa.
Já tá me dando até dor de cabeça</title>
        <meta 
          name="description" 
          content="Oi pessoal, sou hipertensa e hoje minha pressão diastólica não saiu do 10 ou 9 ....
A sistólica fica entre 12 e 13 e a diastólica entre 9 e 10.
Oque vocês acham que eu devo fazer?
Já tomei o remédio da pressão as 18 horas tomo a cada 12 horas, mas tenho ansiedade e não estou nem conseguindo dormir porque a pressão não abaixa.
Já tá me dando até dor de cabeça"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Rita Anton &#183; 12/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoal, sou hipertensa e hoje minha pressão diastólica não saiu do 10 ou 9 ....<br/>A sistólica fica entre 12 e 13 e a diastólica entre 9 e 10.<br/>Oque vocês acham que eu devo fazer?<br/>Já tomei o remédio da pressão as 18 horas tomo a cada 12 horas, mas tenho ansiedade e não estou nem conseguindo dormir porque a pressão não abaixa.<br/>Já tá me dando até dor de cabeça
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/79')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  4 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Rita,<br/><br/>Entendo sua preocupação, ainda mais com a ansiedade misturada. Vamos tentar acalmar e ver algumas coisas que você pode fazer agora. Primeiro, é importante lembrar que, mesmo com seus esforços, qualquer alteração ou preocupação sobre sua pressão arterial deve sempre ser discutida com seu médico.<br/><br/>Aqui estão algumas dicas que podem ajudar a aliviar um pouco essa situação:<br/><br/>1. **Respiração Profunda:** Tente fazer exercícios de respiração profunda. Inspire lentamente pelo nariz contando até 4, segure o ar contando até 4, e depois expire lentamente pela boca, contando até 4 de novo. Repita isso por alguns minutos.<br/><br/>2. **Ambiente Calmo:** Procure um lugar tranquilo e confortável. Evite ficar em ambientes muito barulhentos ou com muita luz, pois isso pode aumentar sua ansiedade.<br/><br/>3. **Hidratação e Alimentação:** Certifique-se de estar bem hidratada, bebendo água regularmente. Evite consumir cafeína e alimentos muito salgados, que podem aumentar a pressão arterial.<br/><br/>4. **Entretenimento Leve:** Às vezes, uma música calma ou uma leitura leve pode ajudar a distrair a mente e reduzir a ansiedade.<br/><br/>Mas atenção, é fundamental você entrar em contato com seu médico o mais rápido possível para relatar esses sintomas e verificar se há necessidade de ajustar seu tratamento. A dor de cabeça também pode ser um sinal importante que precisa de avaliação médica.<br/><br/>Espero que essas sugestões lhe tragam um pouco de alívio enquanto você fala com seu médico. Cuide-se e, por favor, mantenha-nos informados.<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 12/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/79')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Walter Medeiros &#183; 24/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/151-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá, que vitamina ou suplemento o pré-diabetico pode tomar para engordar?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/151')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marcela Prattes &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/104-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Alguma dica de algo natural para baixar triglicerídeos?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/104')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mario César &#183; 06/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/23-diabetes" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Chá para diabético  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Nao estou tomando medicamento<br/>O médico disse que posso controlar com dieta só<br/>Vocês tem chá bom pra diabetes?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/23')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Manuela Escobar &#183; 23/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/148-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom tarde<br/>Gostaria de tirar uma dúvida, caneta pra medir glicemia pode ser usada várias vezes?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/148')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Dara Costa &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/49-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Vou começar fazer quimioterapia. <br/>Vocês sabem dizer com quantas sessões começa a cair o cabelo?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/49')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
