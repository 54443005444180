import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Button from './Button'
import { format } from "date-fns"

export default function VaccineCard({ 
  vaccine, 
  listId, 
  deleteId, 
  setDeleteId,
  onConfirmDelete,
  deleting,
  shared
}) {

  const mobileBreakpoint = useMediaQuery({ query: '(max-width: 480px)' })
  
  const toggleDeleteId = () => {
    if (deleteId !== listId) {
      setDeleteId(listId)
    } else {
      setDeleteId(null)
    }
  }

  return (
    <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs">
      <div>
        <p className="body">{vaccine.title}</p>
        <p className="secondary-text">Criado em: {format(vaccine.date_created, "d/M/yyyy")}</p>
        <p className="secondary-text">Arquivo: {vaccine.external_filename}</p>
      </div>

      <div className={(mobileBreakpoint) ? "column-buttons top-margin-xs" : "row-buttons top-margin-xs"}>
        <Button type={(mobileBreakpoint) ? "primary button-larger" : "primary"} 
          link={true} to={`/vaccine/${vaccine.id}`} 
          stateValue={{
            previous: (shared) ? `/sharedhealth` : `/health`, 
            title: vaccine.title, 
            file: vaccine.external_filename}}>
          Abrir documento 
        </Button>
        {(setDeleteId) ? <Button type="tertiary" onClick={()=>{toggleDeleteId()}}>
          {(deleteId === listId) ? "Cancelar" : "Excluir"}
        </Button> : null }
      </div>

      { (deleteId === listId) ?
        <div className="editable-card bottom-padding-s right-padding-s top-margin-s bottom-margin-s">
          <div className="editable-card-title top-padding-s left-margin-s right-padding-xs">
            <p className="heading5">Você deseja excluir essa prescrição?</p>
          </div>

          <div className="editable-item-for-card-red top-margin-xs vertical-padding-xs left-padding-s right-padding-xs">
            <div>
                <p className="secondary-text bottom-margin-s">Essa ação não pode ser desfeita. O documento de vacinação, assim como todas as informações associadas
                a ele, serão perdidas. Você deseja prosseguir?</p>
                {(deleteId === listId && deleting) ?
                  <Button type="tertiary" onClick={() => {onConfirmDelete(vaccine.id)}}>Removendo...</Button> :
                  <Button type="tertiary" onClick={() => {onConfirmDelete(vaccine.id)}}>Sim, quero remover esse documento</Button>
                }
            </div>
          </div>
        </div>
        : null }
    </div>
  )
}
