import React from 'react';
import LargeButtonLink from "../components/LargeButtonLink"
import forumIcon from "../images/forum-blue.svg"
import pillIcon from "../images/pill-blue.svg"
import FoodIcon from "../images/bowl-blue.svg"
import ExerciseIcon from "../images/awards-blue.svg"

export default function FastAccessSection({ tabletBreakpoint, mobileBreakpoint, id }) {

  return (
    <div className={(tabletBreakpoint) ? 
      "colorful-background-tablet vertical-padding-m" : "colorful-background vertical-padding-m"}>
      <div className={(mobileBreakpoint) ? 
      "container-simple large-button-container-mobile" : "container-simple large-button-container"}>
        <LargeButtonLink 
          icon=<img src={pillIcon} alt="Botão para portal de saúde"/>
          title="Portal de Saúde"
          text="Adicione exames médicos e  informações de saúde" 
          to={`/health`}
          mobileBreakpoint={mobileBreakpoint}
        />
        <LargeButtonLink 
          icon=<img src={forumIcon} alt="Botão para Fórum"/>
          title="Fórum"
          text="Leia oque outras pessoas escreveram, aprenda sobre saúde e compartilhe seu conhecimento"
          to='/forum'
          mobileBreakpoint={mobileBreakpoint}
        />
        <LargeButtonLink 
          icon=<img src={FoodIcon} alt="Botão para portal de alimentação"/>
          title="Alimentação"
          text="Adicione suas metas de alimentação e acompanhe seu progresso." 
          to={`/eating`}
          mobileBreakpoint={mobileBreakpoint}
        />
        <LargeButtonLink 
          icon=<img src={ExerciseIcon} alt="Botão para Fórum"/>
          title="Exercícios Físicos"
          text="Adicione e acompanhe a realização de suas atividades físicas."
          to={`/exercise`}
          mobileBreakpoint={mobileBreakpoint}
        />
      </div>
    </div>
  )
}
