import React from 'react'
import EditIcon from '../images/edit.svg'
import RemoveIcon from '../images/remove.svg'
import Button from './Button'

export default function EditableItemForCardEditSelection({ 
  children, 
  edit=false,
  editUserField, 
  userField,
  responseOptions,
  gridClass
}) {

  const [editing, setEditing] = React.useState(false)

  const [formData, setFormData] = React.useState({})

  function handleChange(e) {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  function handleSubmit(e) {
    e.preventDefault()

    editUserField(Object.keys(formData)[0], formData[Object.keys(formData)[0]])
    setEditing(false)
  }

  let editionForm = null
  if (edit) {
    editionForm = responseOptions.map((response,i) => (
      <div key={i} className="closed-radio-button">
        <label className="body">{response}</label>
        <input
          name={userField[0]}
          id = {i}
          onChange={handleChange}
          type="radio"
          value={response}
        />
      </div>
    ))
  }


  return (
    <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs">

      <div className='editable-item-for-card-header'>
        <div>{children}</div>
        <div className="row-svg-icons left-margin-xs">
          {(!editing && edit) ? 
              <button onClick={()=>{setEditing(prev=>!prev)}} className='carousel-btn'>
                <img className="" src={EditIcon}/>
              </button> : null }
        </div>
      </div>
  
      {(editing) ? 
      <form onSubmit={handleSubmit}>
        <div className={`vertical-margin-xs ${gridClass}`}>
          { editionForm } 
        </div>
        <div>
          <Button type="primary right-margin-s">Alterar</Button>
          <Button type="tertiary" onClick={()=>setEditing(false)}>Cancelar</Button> 
        </div>
      </form> : null}


    </div>
  )
}
