import React from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom"
import Lupa from '../../images/lupa-2.svg'
//import UserIcon from '../../images/user-circle.svg'
import UserIcon from '../../images/user-thin.svg'
import { ForumContext } from '../../forum/ForumData'
import AuthContext from '../../context/AuthProvider'
import Tag from '../Tag'
import ToggleMenu from '../ToggleMenu'

export default function SearchBar({tabletBreakpoint, notifications}) {
 
  const [searchTerm, setSearchTerm] = React.useState({content: ""})

  const { loggedIn, forumSearchTerm, setForumSearchTerm } = React.useContext(ForumContext)
  const { cookies } = React.useContext(AuthContext);

  const location = useLocation()
  const navigate = useNavigate()

  function handleSubmit(e) {
    e.preventDefault()

    setForumSearchTerm(searchTerm.content)

    setSearchTerm({content: ""})

    navigate('/forum')
  }

  function handleChange(e) {
    const { name, value } = e.target
    setSearchTerm(({
      [name]: value
    }))
  }

  //<Link className='avatar-flex' to="/family">
      //<Avatar size='small' color='avatar-blue' user='Leonardo'/> 
  //</Link>
  //
  return (
    <div className={(tabletBreakpoint) ?
      "container-simple-tablet" : "container-simple"}>
      <div className={(tabletBreakpoint) ? "searchbar-header-tablet" : "searchbar-header"}>
        {(tabletBreakpoint) ?
          <Link to={(loggedIn) ? `/profile` : "/"}>
            <img className="small-logo-nav" src={require("../../images/florence-white-blue.png")} alt="Florence logo"/>
          </Link>
          : null 
        }
        <form onSubmit={handleSubmit}>
          <div className="searchbar">
            <input className="searchinput"
                name="content" onChange={handleChange} type="text" placeholder="Buscar"
                value={searchTerm.content}/>
            <button className="svg-btn-secondary magnifyingglass">
              <img src={Lupa} alt="Perform search on the input terms"/>
            </button>
          </div>
        </form>
        {
          (loggedIn) ?
            <div className={(tabletBreakpoint) ? 
              "searchbar-togglemenu top-padding-xxs" : "searchbar-togglemenu"}>
              <ToggleMenu menuItems="forum" id={cookies.get("userId")} notifications={notifications}/>
            </div>
            :
            <Link to="/loginrequired" state={{ pageOfInterest: location.pathname }}>
              <img src={UserIcon} alt="Login in the page"/>
            </Link>
        }
      </div>
      {(forumSearchTerm) ? 
        <div className="top-margin-xs">
          <Tag color="green" remove={()=>{setForumSearchTerm("")}}>{forumSearchTerm}</Tag> 
        </div>
        : null
      }
    </div>
  )
}
