
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post18() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Dúvida saúde - diabetes - Diabetes de jejum</title>
        <meta 
          name="description" 
          content="Bom dia boa semana a todos"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Maria Schwed &#183; 06/08/2024
          </p>
        </div>
        <p className="heading4 bottom-margin-s">Diabetes de jejum</p>


        <p className="body content bottom-padding-s">
            Bom dia boa semana a todos
        </p>


            <img className="post-image bottom-margin-xs" 
            src={require(`../../post_images/de09f7ea-9280-4fc2-a949-3c9bfe65623d.18`)}  
            alt="Post image submitted by user"/>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/18')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  2 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Bom dia! 🙂<br/><br/>Que bom ver a sua glicose em jejum dentro de um valor saudável, 100 mg/dL é um ótimo resultado! Continue monitorando regularmente e seguindo as orientações do seu médico.<br/><br/>Lembre-se de sempre manter uma alimentação equilibrada, praticar atividades físicas e seguir o tratamento prescrito. Se tiver qualquer dúvida ou perceber alguma alteração nos seus resultados, não hesite em procurar o seu médico.<br/><br/>Tenha uma ótima semana e que Deus renove nossa Fé e esperança sempre!<br/><br/>Um abraço!
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 06/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/18')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariana Silva &#183; 12/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/78-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. O médico me receitou sinvastatina 20 mg uma vez por dia.<br/>Será que estou tão mal assim fico preocupada??<br/>Meu exame deu isso<br/>HDL 78.5<br/>NAO-Hdl 180mg<br/>Colesterol total 258.3<br/>Ldl 164<br/>Triglicerides 77<br/>Hemoglobina glicada 4.9%<br/><br/>Obrigado!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/78')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Amanda Flores &#183; 19/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/129-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Retirei o nodulo da mama faz um mês mais ou menos, e ontem saiu o resultado da cirurgia que 4 linfonodos já tinham sido atingidos. Alguém pode me ajudar no que isso significa?<br/><br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/129')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mario César &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/31-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Pessoal, meu exame de colesterol deu assim... Vocês sabem se está alterado? Ainda não mostrei para o médico<br/><br/>Colesterol total: 193 mg/dL<br/>Colesterol HDL: 69.7 mg/dL<br/>Triglicerídeos: 89.0
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/31')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Claudia Coimbra &#183; 13/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/83-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Preciso de ajuda. Quando tem moleza no corpo e vontade de dormir o dia todo é porque a diabetes está baixa?<br/>Meu marido tem diabates, e as vezes ele dorme o dia todo.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/83')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Luíza Castro &#183; 13/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/80-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal! Qual creme vcs usam para a pele? Porque diabetes ressecada bastante a pele
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/80')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
