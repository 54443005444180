const backendUrl = "https://backend-florence.onrender.com"
//const backendUrl = "http://127.0.0.1:5050"
// use env-variables
const backendFrontendPwd = "1208" 

export default async function registerUser(creds) {
  const credsRenamed = {
    "first_name": creds.firstName,
    "last_name": creds.lastName,
    "email": creds.email,
    "birth_date": `${creds.day}/${creds.month}/${creds.year}`,
    "sex": creds.sex,
    "password": creds.password
  }

  const res = await fetch(`${backendUrl}/register`,{ 
      method: "POST", 
      body: JSON.stringify(credsRenamed),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Front-Authorization": `${backendFrontendPwd}`
      }
    }
  )

  if (!res.ok) {
    throw {
      message: "Já existe um usuário cadastrado com esse e-mail",
      statusText: res.statusText,
      status: res.status
    }
  }
  const data = await res.json()
  return data
}

export async function refreshAccessToken(refreshToken) { 
  //console.log("refreshAccessToken")
  const res = await fetch(`${backendUrl}/refresh`,{ 
      method: "POST", 
      body: JSON.stringify(),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Front-Authorization": `${backendFrontendPwd}`,
        "Authorization": `Bearer ${refreshToken}`
      }
    }
  )

  if (!res.ok) {
    throw {
      message: "Refresh token invalid.",
      statusText: res.statusText,
      status: res.status
    }
  }
  const data = await res.json()
  return data
}


export async function getDataFromApi(endpoint, auth, cookies, body=null, raw=false, postGroup=null) { 

  let accessToken = null
  let refreshToken = null
  // if auth is null, try to get tokens from cookies
  if (!auth.refreshToken) {
    // try to recover tokens from cookies
    //console.log("trying to recover tokens from cookies")
    refreshToken = cookies.get("refreshToken") 
    accessToken = cookies.get("accessToken")
  } else {
    refreshToken = auth.refreshToken
    accessToken = auth.accessToken
  }

  let res = null
  if (!body) {
    if (!postGroup) {
      postGroup = ''
    }
    res = await fetch(`${backendUrl}/${endpoint}`,{ 
        method: "GET", 
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Front-Authorization": `${backendFrontendPwd}`,
          "Authorization": `Bearer ${accessToken}`,
          "X-post-group": `${postGroup}`
        },
      }
    )
  } else {
    res = await fetch(`${backendUrl}/${endpoint}`,{ 
        method: "POST", 
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Front-Authorization": `${backendFrontendPwd}`,
          "Authorization": `Bearer ${accessToken}`
        },
      }
    )
  }

  let data = null
  if (!res.ok) {
    const errorMessage = await res.json()

    if (errorMessage.message === 'The token has expired.') {
      //console.log("Access token has expired. Refreshing it.")
      try {
        const refreshedToken = await refreshAccessToken(refreshToken)
        try {
          //data = await getDataFromApi(endpoint, 
          //  { refreshToken, accessToken: refreshedToken.access_token },
          //  cookies, body )
          data = await getDataFromApi(endpoint, 
            { refreshToken, accessToken: refreshedToken.access_token },
            cookies, body, raw, postGroup)
          accessToken = refreshedToken.access_token
        } catch(err) {
          throw {
            message: "Error validation token",
            statusText: res.statusText,
            status: res.status
          }
        }
      } catch(err) {
        throw {
          message: "Error validation token",
          statusText: res.statusText,
          status: res.status
        }
      }
    } else {

      throw {
        message: "Error validation token",
        statusText: res.statusText,
        status: res.status
      }
    }
  } else {
    if (raw) {
      return {res: res, tokens: { refreshToken, accessToken } }
    } else {
      data = await res.json()
    }
    //console.log("no errors") 
  }
  return { ...data, tokens: { refreshToken, accessToken } }
}


export async function insertDependent(creds, auth, cookies) { 
  
  let accessToken = null
  let refreshToken = null
  if (!auth.refreshToken) {
    // try to recover tokens from cookies
    //console.log("trying to recover tokens from cookies")
    refreshToken = cookies.get("refreshToken" ) 
    accessToken = cookies.get("accessToken")
  } else {
    refreshToken = auth.refreshToken
    accessToken = auth.accessToken
  }

  const credsRenamed = {
    "first_name": creds.firstName,
    "last_name": creds.lastName,
    "email": creds.email,
    "birth_date": `${creds.day}/${creds.month}/${creds.year}`,
    "sex": creds.sex,
    "password": creds.password
  }

  let res = await fetch(`${backendUrl}/family`,{ 
      method: "POST",
      body: JSON.stringify(credsRenamed),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Front-Authorization": `${backendFrontendPwd}`,
        "Authorization": `Bearer ${accessToken}`
      }
    }
  )
  
  let data = null
  if (!res.ok) {
    const errorMessage = await res.json()

    if (errorMessage.message === 'The token has expired.') {
      //console.log("Access token has expired. Refreshing it.")
      try {
        const refreshedToken = await refreshAccessToken(refreshToken)
        try {
          data = await insertDependent({ refreshToken, accessToken: refreshedToken.access_token },
            cookies )
          accessToken = refreshedToken.access_token
        } catch(err) {
          console.log(err)  
          throw {
            message: "Error validation token",
            statusText: res.statusText,
            status: res.status
          }
        }
      } catch(err) {
        console.log(err)  
        throw {
          message: "Já existe um usuário cadastrado com esse e-mail",
          statusText: res.statusText,
          status: res.status
        }
      }
    } else {
      console.log(errorMessage.description)
      console.log(errorMessage.message)
      throw {
        message: "Já existe um usuário cadastrado com esse e-mail",
        statusText: res.statusText,
        status: res.status
      }
    }
  } else {
    //console.log("no errors")
    data = await res.json()
  }
  return { ...data, tokens: { refreshToken, accessToken } }
}

export async function userLogin(creds) {
  //console.log("userLogin")
  const res = await fetch(`${backendUrl}/login`,{ 
      method: "POST", 
      body: JSON.stringify(creds),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Front-Authorization": `${backendFrontendPwd}`
      }
    }
  )

  if (!res.ok) {
    throw {
      message: "E-mail ou senha incorretos",
      statusText: res.statusText,
      status: res.status
    }
  }
  const data = await res.json()
  return data
}

export async function resetPassword(creds) {

  //console.log("resetPassword")
  //console.log(creds)

  const res = await fetch(`${backendUrl}/resetpassword`,{ 
      method: "PUT", 
      body: JSON.stringify(creds),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Front-Authorization": `${backendFrontendPwd}`
      }
    }
  )

  if (!res.ok) {
    throw {
      message: "E-mail incorreto",
      statusText: res.statusText,
      status: res.status
    }
  }
  const data = await res.json()
  return data
}


export async function userLogout(auth, cookies) { 
  
  let accessToken = null
  let refreshToken = null
  if (!auth.refreshToken) {
    // try to recover tokens from cookies
    //console.log("trying to recover tokens from cookies")
    refreshToken = cookies.get("refreshToken" ) 
    accessToken = cookies.get("accessToken")
  } else {
    refreshToken = auth.refreshToken
    accessToken = auth.accessToken
  }

  console.log('login out. Access token:')
  console.log(accessToken)

  let res = await fetch(`${backendUrl}/logout`,{ 
      method: "POST", 
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Front-Authorization": `${backendFrontendPwd}`,
        "Authorization": `Bearer ${accessToken}`
      }
    }
  )
  

  let data = null
  if (!res.ok) {
    throw {
      message: "Error validation token",
      statusText: res.statusText,
      status: res.status
    }
  } else {
    //console.log("no errors")
    data = await res.json()
  }
  return data
}


export async function deleteDataFromApi(requestBody, endpoint, auth, cookies) { 
  //console.log('deleteDataFromApi')

  let accessToken = null
  let refreshToken = null
  // if auth is null, try to get tokens from cookies
  if (!auth.refreshToken) {
    // try to recover tokens from cookies
    //console.log("trying to recover tokens from cookies")
    refreshToken = cookies.get("refreshToken" ) 
    accessToken = cookies.get("accessToken")
  } else {
    refreshToken = auth.refreshToken
    accessToken = auth.accessToken
  }

  let res = await fetch(`${backendUrl}/${endpoint}`,{ 
      method: "DELETE", 
      body: JSON.stringify(requestBody),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Front-Authorization": `${backendFrontendPwd}`,
        "Authorization": `Bearer ${accessToken}`
      }
    }
  )
  

  let data = null
  if (!res.ok) {
    const errorMessage = await res.json()

    if (errorMessage.message === 'The token has expired.') {
      //console.log("Access token has expired. Refreshing it.")
      try {
        const refreshedToken = await refreshAccessToken(refreshToken)
        try {
          data = await deleteDataFromApi(requestBody, endpoint, 
            { refreshToken, accessToken: refreshedToken.access_token },
            cookies )
          accessToken = refreshedToken.access_token
        } catch(err) {
          console.log(err)  
          throw {
            message: "Error validation token",
            statusText: res.statusText,
            status: res.status
          }
        }
      } catch(err) {
        console.log(err)  
        throw {
          message: "Error validation token",
          statusText: res.statusText,
          status: res.status
        }
      }
    } else {
      console.log(errorMessage.description)
      console.log(errorMessage.message)
      throw {
        message: "Error validation token",
        statusText: res.statusText,
        status: res.status
      }
    }
  } else {
    //console.log("no errors")
    data = await res.json()
  }
  return { ...data, tokens: { refreshToken, accessToken } }
}


export async function putRequestToApi(requestBody, endpoint, auth, cookies) { 
  //console.log('putRequestToApi')

  let accessToken = null
  let refreshToken = null
  // if auth is null, try to get tokens from cookies
  if (!auth.refreshToken) {
    // try to recover tokens from cookies
    //console.log("trying to recover tokens from cookies")
    refreshToken = cookies.get("refreshToken" ) 
    accessToken = cookies.get("accessToken")
  } else {
    refreshToken = auth.refreshToken
    accessToken = auth.accessToken
  }

  let res = await fetch(`${backendUrl}/${endpoint}`,{ 
      method: "PUT", 
      body: JSON.stringify(requestBody),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Front-Authorization": `${backendFrontendPwd}`,
        "Authorization": `Bearer ${accessToken}`
      }
    }
  )
  
  let data = null
  if (!res.ok) {
    const errorMessage = await res.json()

    if (errorMessage.message === 'The token has expired.') {
      //console.log("Access token has expired. Refreshing it.")
      try {
        const refreshedToken = await refreshAccessToken(refreshToken)
        try {
          data = await putRequestToApi(requestBody, endpoint, 
            { refreshToken, accessToken: refreshedToken.access_token },
            cookies )
          accessToken = refreshedToken.access_token
        } catch(err) {
          console.log(err)  
          throw {
            message: "Error validation token",
            statusText: res.statusText,
            status: res.status
          }
        }
      } catch(err) {
        console.log(err)  
        throw {
          message: "Error validation token",
          statusText: res.statusText,
          status: res.status
        }
      }
    } else {
      console.log(errorMessage.description)
      console.log(errorMessage.message)
      throw {
        message: "Error validation token",
        statusText: res.statusText,
        status: res.status
      }
    }
  } else {
    //console.log("no errors")
    data = await res.json()
  }
  return { ...data, tokens: { refreshToken, accessToken } }
}


export async function putExamRequestToApi(requestBody, endpoint, auth, cookies) { 
  //console.log('putExamRequestToApi')

  let accessToken = null
  let refreshToken = null
  // if auth is null, try to get tokens from cookies
  if (!auth.refreshToken) {
    // try to recover tokens from cookies
    //console.log("trying to recover tokens from cookies")
    refreshToken = cookies.get("refreshToken" ) 
    accessToken = cookies.get("accessToken")
  } else {
    refreshToken = auth.refreshToken
    accessToken = auth.accessToken
  }

  let res = await fetch(`${backendUrl}/${endpoint}`,{ 
      method: "PUT", 
      body: JSON.stringify(requestBody),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Front-Authorization": `${backendFrontendPwd}`,
        "Authorization": `Bearer ${accessToken}`
      }
    }
  )
  
  let data = null
  if (!res.ok) {
    console.log('result not ok')
    const errorMessage = await res.json()

    if (errorMessage.message === 'The token has expired.') {
      //console.log("Access token has expired. Refreshing it.")
      try {
        const refreshedToken = await refreshAccessToken(refreshToken)
        try {
          data = await putExamRequestToApi(requestBody, endpoint, 
            { refreshToken, accessToken: refreshedToken.access_token },
            cookies )
          accessToken = refreshedToken.access_token
        } catch(err) {
          console.log(err)  
          throw {
            message: "Error validation token",
            statusText: res.statusText,
            status: res.status
          }
        }
      } catch(err) {
        console.log(err)  
        throw {
          message: "Error validation token",
          statusText: res.statusText,
          status: res.status
        }
      }
    } else {
      console.log(errorMessage.description)
      console.log(errorMessage.message)
      throw {
        message: errorMessage.message,
        statusText: res.statusText,
        status: res.status
      }
    }
  } else {
    //console.log("no errors")
    data = await res.blob()
  }
  return data
}

export async function postRequestToApi(
  requestBody, endpoint, auth, cookies, formData=false, raw=false, imageUuid=null, postGroup) { 
  // raw: do not transform the result to json before returning, just returning as it is
  // this is needed when returning image, for example
  

  let accessToken = null
  let refreshToken = null
  // if auth is null, try to get tokens from cookies
  if (!auth.refreshToken) {
    // try to recover tokens from cookies
    //console.log("trying to recover tokens from cookies")
    refreshToken = cookies.get("refreshToken" ) 
    accessToken = cookies.get("accessToken")
  } else {
    refreshToken = auth.refreshToken
    accessToken = auth.accessToken
  }

  let res = null
  if (!formData) {
    if (!postGroup) { // is postGroup is null, send an empty string
      postGroup = ''
    }
    res = await fetch(`${backendUrl}/${endpoint}`,{ 
        method: "POST", 
        body: JSON.stringify(requestBody),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Front-Authorization": `${backendFrontendPwd}`,
          "Authorization": `Bearer ${accessToken}`,
          "X-post-group": `${postGroup}`
        }
      }
    )
  } else {
    if (imageUuid) {
      res = await fetch(`${backendUrl}/${endpoint}`,{ 
          method: "POST", 
          body: requestBody,
          headers: {
            "Front-Authorization": `${backendFrontendPwd}`,
            "Authorization": `Bearer ${accessToken}`,
            "X-image-uuid": `${imageUuid}`
          }
        }
      )
    } else {
      //console.log('form-data request')
      res = await fetch(`${backendUrl}/${endpoint}`,{ 
          method: "POST", 
          body: requestBody,
          headers: {
            "Front-Authorization": `${backendFrontendPwd}`,
            "Authorization": `Bearer ${accessToken}`
          }
        }
      )
    }
  }
  
  let data = null
  if (!res.ok) {
    const errorMessage = await res.json()

    if (errorMessage.message === 'The token has expired.') {
      console.log("Access token has expired. Refreshing it.")
      try {
        const refreshedToken = await refreshAccessToken(refreshToken)
        try {
          data = await postRequestToApi(requestBody, endpoint, 
            { refreshToken, accessToken: refreshedToken.access_token }, 
            cookies, formData, raw, imageUuid, postGroup)
          accessToken = refreshedToken.access_token
        } catch(err) {
          console.log(err)  
          throw {
            message: "Error validation token",
            statusText: res.statusText,
            status: res.status
          }
        }
      } catch(err) {
        console.log(err)  
        throw {
          message: "Error validation token",
          statusText: res.statusText,
          status: res.status
        }
      }
    } else {
      console.log(errorMessage.description)
      console.log(errorMessage.message)
      throw {
        message: "Um outro arquivo com esse mesmo nome já existe. Caso queira substituí-lo, delete o outro arquivo primeiro.",
        statusText: res.statusText,
        status: res.status
      }
    }
  } else {
    //console.log("no errors")
    if (raw) {
      return {res: res, tokens: { refreshToken, accessToken } }
    } else {
      data = await res.json()
    }
  }
  return {...data, tokens: {refreshToken, accessToken}}
}

export async function getForumFeedFromApi(auth, cookies, page, authData, postGroup=null, search="") {
  let response = null
  let postImage = null

  if (search !== "") {
    console.log('ignore the rest and do a filtered search')

    const requestBody = {
      id: page,
      name: search
    }
    response = await postRequestToApi(requestBody, `forum/filter`, auth, cookies, false, true, null, null)
  }
  else if (authData.loggedIn) {
    //console.log('user logged in')
    const requestBody = {
      id: authData.id
    }
    response = await postRequestToApi(requestBody, `forum/user/${page}`, auth, cookies, false, true, null, postGroup)
  } else {
    //console.log('user not logged in')
    response = await getDataFromApi(`forum/feed/${page}`, auth, cookies, null, true, postGroup)
  }

  const posts = await JSON.parse(response.res.headers.get('X-post'))

  const data = {posts: posts, tokens: response.tokens}

  postImage = await response.res.blob()

  //console.log(posts)
  if (!data.posts[0]) { // if there is no more posts, return an empty list
    return {...data, posts: []}
  }

  if (postImage.size > 10) {
    data.posts[0] = {...data.posts[0], hasImage: true, image: postImage}
  } else {
    data.posts[0] = {...data.posts[0], hasImage: false}
  }

  return data

}

export async function getUserForumDataFromApi(auth, cookies, page, authData) {
    const requestBody = {
      id: authData.id,
      name: authData.name 
    }
    const response = await postRequestToApi(requestBody, `forum/user/${page}`, auth, cookies, false, true)

    const posts = await JSON.parse(response.res.headers.get('X-post'))
    const data = {posts: posts, tokens: response.tokens}

    const postImage = await response.res.blob()

    if (!data.posts[0]) {
      return {...data, posts: []}
    }
    if (postImage.size > 10) {
      data.posts[0] = {...data.posts[0], hasImage: true, image: postImage}
    } else {
      data.posts[0] = {...data.posts[0], hasImage: false}
    }

    return data
}

export async function getPostFromApi(postId, auth, cookies, authData) {
  let requestBody = {}
  if (authData.id) {
    //console.log('user logged in')
    requestBody = {
      id: authData.id
    }
  }
  const response = await postRequestToApi(requestBody, `forum/${postId}`, auth, cookies, false, true)

  const post = await JSON.parse(response.res.headers.get('X-post'))
  const data = {post: post, tokens: response.tokens}

  const postImage = await response.res.blob()
  if (postImage.size > 10) {
    data.post = {...data.post, hasImage: true, image: postImage}
  } else {
    data.post = {...data.post, hasImage: false}
  }

  return data
}

export async function getFoodDataFromApi(endpoint, auth, cookies) {

  let response = null
  let foodImage = null

  response = await getDataFromApi(endpoint, auth, cookies, null, true)

  const foodData = await JSON.parse(response.res.headers.get('X-post'))

  const data = {food: foodData, tokens: response.tokens}

  foodImage = await response.res.blob()

  if (!data.food) {
    return {...data, food: []}
  }

  if (foodImage.size > 10) {
    data.food = {...data.food, hasImage: true, image: foodImage}
  } else {
    data.food = {...data.food, hasImage: false}
  }

  return data

}
