import React from 'react'
import { Helmet } from 'react-helmet';
import { useSearchParams } from "react-router-dom"
import { useMediaQuery } from 'react-responsive'
import SearchBar from '../components/Forum/SearchBar'
import Feed from '../components/Forum/Feed'
import Button from '../components/Button'
import Return from '../components/Forum/Return'
import { ForumContext } from './ForumData'
import AuthContext from '../context/AuthProvider'
import ReactGA from "react-ga4";

export default function Forum() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const { loggedIn, setPostGroup, notifications, pageNumber, setPageNumber } = React.useContext(ForumContext)
  const { cookies } = React.useContext(AuthContext);

  //const [pageNumber, setPageNumber] = React.useState(0)

  const [searchParams, setSearchParams] = useSearchParams({})
  const postGroup = searchParams.get("group")

  React.useEffect(()=>{
    console.log('post group')
    console.log(postGroup)
    setPostGroup(postGroup)
    setPageNumber(0)
  }, [postGroup])

  React.useEffect(()=>{
    ReactGA.send({ hitType: "pageview", page: "/forum", title: "Forum visit"})
  }, [])

  return (
    <div className="top-margin-s">

      <Helmet>
        <title>Florence Health - Fórum</title>
        <meta 
          name="description" 
          content="Faça perguntas e tire dúvidas sobre diabetes, pressão alta, hipertensão, colesterol alto, triglicerídeos elevados, e tratamento de câncer, tratamento oncológico. O Fórum da Florence é um local para tirar dúvidas sobre saúde de maneira segura. Dúvidas sobre diabetes, pressão alta, hipertensão, colesterol alto, triglicerídeos e câncer. Conte sua história, compartilhe o que está funcionando para sua saúde, tire dúvidas e aprenda com outras pessoas da comunidade."/>
      </Helmet>

      <SearchBar tabletBreakpoint={tabletBreakpoint} notifications={notifications}/>

      <div className={(tabletBreakpoint) ?
        "container-simple-tablet forum-main top-padding-s": 
        "container-simple forum-main top-padding-s"}>
        <Return to={(cookies.get("userId")) ? `/profile` : '/family'}/>
        <p className="florence-title">Fórum</p>
        <p className="body secondary-color top-margin-s">Conte sua história, compartilhe o que está funcionando para sua saúde, 
          tire dúvidas e aprenda com outras pessoas da comunidade.</p>
        <div className= {(tabletBreakpoint) ? 
          "forum-main-horizontal-menu-tablet top-margin-s bottom-margin-s" : 
          "forum-main-horizontal-menu top-margin-s bottom-margin-s"}>
          <Button type="primary" link={true} to='write'>Escrever post</Button>
          { (loggedIn) ?
            <>
              <Button type="tertiary" link={true} to='myposts'>Meus posts</Button>
              <Button type="tertiary" link={true} to='saved'>Posts salvos</Button>
            </>:
            null
          }
        </div>
        <p className="body secondary-color top-margin-s">Selecione um assunto</p>
        <div className="tags-horizontal-menu top-margin-xs bottom-margin-s">
          <button className={(postGroup === null) ? "tag-selected":"tag-filter"}
            onClick={()=>setSearchParams({})}>Geral</button>
          <button className={(postGroup === "diabetes") ? "tag-selected" : "tag-filter"} 
            onClick={()=>setSearchParams({group: "diabetes"})}>Diabetes</button>
          <button className={(postGroup === "pressao") ? "tag-selected" : "tag-filter"} 
            onClick={()=>setSearchParams({group: "pressao"})}>Pressão alta</button>
          <button className={(postGroup === "colesterol") ? "tag-selected" : "tag-filter"} 
            onClick={()=>setSearchParams({group: "colesterol"})}>Colesterol alto</button>
          <button className={(postGroup === "cancer") ? "tag-selected" : "tag-filter"} 
            onClick={()=>setSearchParams({group: "cancer"})}>Câncer</button>
        </div>
      </div>

      <Feed tabletBreakpoint={tabletBreakpoint} 
        pageNumber={pageNumber} setPageNumber={setPageNumber}
        searchTerm={searchParams.toString()}/>

    </div>
  )
}
