
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post23() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Dúvida saúde - diabetes - Chá para diabético</title>
        <meta 
          name="description" 
          content="Nao estou tomando medicamento
O médico disse que posso controlar com dieta só
Vocês tem chá bom pra diabetes?"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Mario César &#183; 06/08/2024
          </p>
        </div>
        <p className="heading4 bottom-margin-s">Chá para diabético</p>


        <p className="body content bottom-padding-s">
            Nao estou tomando medicamento<br/>O médico disse que posso controlar com dieta só<br/>Vocês tem chá bom pra diabetes?
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/23')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  4 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Olá! Entendo seu interesse em encontrar alternativas naturais para ajudar no controle do diabetes, especialmente se o seu médico recomendou o controle por meio da dieta. Vale lembrar que qualquer complemento à sua dieta deve ser discutido com seu médico ou nutricionista para garantir que é seguro e eficaz para o seu caso específico.<br/><br/>Existem alguns chás que são popularmente conhecidos por terem propriedades que podem auxiliar no controle da glicemia. No entanto, é importante lembrar que eles não substituem os tratamentos prescritos por profissionais de saúde e devem ser utilizados como um complemento à alimentação balanceada e à prática regular de exercícios físicos.<br/><br/>Um exemplo é o chá de camomila, que além de ajudar a relaxar, tem sido associado ao controle dos níveis de açúcar no sangue. O chá de canela é outro que pode ser benéfico, pois há estudos que sugerem que a canela pode ajudar a melhorar a sensibilidade à insulina. Além desses, o chá verde também é uma escolha interessante, visto que possui antioxidantes que podem ajudar na melhoria da função metabólica.<br/><br/>É fundamental, contudo, que você converse com seu médico antes de adicionar novos elementos à sua dieta, inclusive chás, para assegurar que eles são apropriados para sua situação específica e não irão interagir negativamente com sua saúde ou com a eficácia de controlar o diabetes somente com dieta, conforme indicado.<br/><br/>Mantenha um diálogo aberto com seu médico a respeito das suas escolhas alimentares, incluindo o consumo de chás, e siga sempre as orientações profissionais. Se tiver qualquer outra dúvida ou precisar de mais informações, estarei por aqui para ajudar. Cuide-se!
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 06/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/23')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Maria Schwed &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/41-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal!!<br/>Eu ando bem esgotada, qualquer coisa que vou fazer fico super cansada. Não sei se é devido a diabete tipo 2, mas quero melhorar isso. Eu trabalho e tenho 48 anos. Você tem algum dica do que eu possa fazer?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/41')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marlene Techini &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/48-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, tenho 31 anos e não fumo.<br/>Descobri que estou com com câncer de pulmão e estou assustada.<br/>Alguém com câncer no pulmão poderia compartilhar como está sendo?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/48')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariana Zandona &#183; 18/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/122-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Pessoal me ajudem, alguém aí deixou de comer arroz pra normalizar a diabetes e conseguiu
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/122')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Maria Schwed &#183; 11/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/74-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Qual é o nível do colesterol normal?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/74')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Rita Anton &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/96-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia. Será que açúcar demerara quem tem diabetes pode usar?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/96')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            8 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
