import React from "react"

export default function Avatar({user, size, color}) {

  const smallAvatar = (
    <p className={`avatar-small-letter ${color}`}>{user[0].toUpperCase()}</p>
  )

  const largeAvatar = (
      <p className="avatar-letter bottom-margin-s horizontal-margin-s">{user[0].toUpperCase()}</p>
  )

  return (
    (size === "small") ? smallAvatar : largeAvatar 
  )
}
