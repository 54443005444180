
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post104() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Alguma dica de algo natural para baixar triglicerídeos?</title>
        <meta 
          name="description" 
          content="Alguma dica de algo natural para baixar triglicerídeos?"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Marcela Prattes &#183; 15/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Alguma dica de algo natural para baixar triglicerídeos?
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/104')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  3 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Olá, Marcela!<br/><br/>Que bom que você está buscando maneiras de cuidar da sua saúde! Existem algumas dicas naturais que podem te ajudar a baixar os triglicerídeos:<br/><br/>1. **Alimentação Balanceada**: Invista em uma alimentação rica em frutas, legumes, verduras, grãos integrais e proteínas magras como peixe e frango sem pele.<br/><br/>2. **Consumo de Gorduras Saudáveis**: Utilize óleos vegetais como azeite de oliva e óleo de canola. As castanhas, nozes e sementes também são ótimas fontes de gorduras boas.<br/><br/>3. **Redução de Açúcar e Carboidratos Refinados**: Tente diminuir o consumo de doces, bebidas açucaradas, pães brancos e massas feitas com farinha refinada.<br/><br/>4. **Fibras Solúveis**: Alimentos como aveia, cevada, feijão, maçã e cenoura são ricos em fibras que podem ajudar a reduzir os triglicerídeos.<br/><br/>5. **Atividade Física**: Fazer exercícios regularmente, como caminhadas diárias de 30 minutos, pode ajudar a controlar os níveis de triglicerídeos.<br/><br/>6. **Evitar Álcool em Excesso**: O consumo excessivo de álcool pode aumentar significativamente os triglicerídeos, então seja cuidadosa com a quantidade que você ingere.<br/><br/>Vale lembrar que cada organismo é diferente, e é importante conversar com o seu médico sobre essas mudanças para garantir que são seguras e adequadas para você. Espero ter ajudado!<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 15/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/104')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marília Dornelles &#183; 13/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/87-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia pessoal. Estou com diabetes gestacional. Como posso abaixar a diabetes através da alimentação?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/87')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    J. Sandel &#183; 06/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/24-diabetes" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Dúvida Pré-diabetes  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa noite pessoal. Tenho uma dúvida:<br/><br/>Pré-diabético toma medicamentos pra diabetes ou só faz dieta?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/24')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Paula Farber &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/28-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, tenho diabetes tipo 2 e dor nas pernas, muita ardência a noite. Vocês já tiveram isso? Já fizeram uso de vitamina B12 e D e ajudou?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/28')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        2 comentários
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Carolina Mattos &#183; 11/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/72-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia pessoal. Estou triste por ser diagnosticado com diabetes tipo 2. Perdi muito peso e estou muito triste, mas tenho fé que vou conseguir superar. Peço ajuda dos amigos pois está sendo muito difícil
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/72')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Carla Chagas &#183; 14/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/89-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá, eu gostaria de saber mais sobre neuropatia
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/89')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
