
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post65() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoal, queria uma opinião. A médica me disse que meu colesterol tá alto e me passou sinvastatina. Meus resultados estão o seguinte: Ldl 131, Colesterol total 218, Triglicerídeos 65, Hdl 71. Oque vcs acham?</title>
        <meta 
          name="description" 
          content="Oi pessoal, queria uma opinião. A médica me disse que meu colesterol tá alto e me passou sinvastatina. Meus resultados estão o seguinte: Ldl 131, Colesterol total 218, Triglicerídeos 65, Hdl 71. Oque vcs acham?"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Marli Seccon &#183; 10/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoal, queria uma opinião. A médica me disse que meu colesterol tá alto e me passou sinvastatina. Meus resultados estão o seguinte: Ldl 131, Colesterol total 218, Triglicerídeos 65, Hdl 71. Oque vcs acham?
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/65')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  2 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Marli, tudo bem?<br/><br/>Primeiro de tudo, é ótimo que você está verificando sua saúde e acompanhando os resultados dos exames!<br/><br/>Sobre os seus resultados:<br/><br/>- **LDL (colesterol ruim):** O ideal é que ele esteja abaixo de 100 mg/dL, mas variações até 129 mg/dL podem ser aceitas dependendo do seu risco cardiovascular. O seu está um pouco acima do ideal.<br/>- **Colesterol total:** Níveis até 200 mg/dL são considerados desejáveis, então o seu está um pouco acima.<br/>- **Triglicerídeos:** O seu está bem dentro do limite ideal, que é abaixo de 150 mg/dL. Ótimo!<br/>- **HDL (colesterol bom):** Acima de 60 mg/dL é excelente, e o seu está bem acima desse valor! Isso é muito bom!<br/><br/>A sinvastatina é uma medicação comum para ajudar a reduzir o colesterol LDL e prevenir problemas cardíacos. É importante seguir a orientação da sua médica e tomar a medicação conforme prescrito.<br/><br/>Além disso, algumas mudanças no estilo de vida podem ajudar bastante, como:<br/><br/>- **Alimentação:** Tente evitar frituras, alimentos processados e com muitas gorduras saturadas. Inclua mais frutas, legumes, fibras e gorduras "boas" (como azeite de oliva, abacate, etc).<br/>- **Exercícios:** Fazer atividades físicas regularmente, como caminhadas, pode ajudar a melhorar os níveis de colesterol.<br/>- **Não fumar:** Se você fuma, parar de fumar pode melhorar muito sua saúde.<br/><br/>E sempre que tiver dúvidas ou sentir algum efeito colateral com a medicação, fale com a sua médica, ok?<br/><br/>Qualquer coisa, estou aqui para ajudar. Se cuida!<br/><br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 10/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/65')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Martina Coelho &#183; 13/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/86-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal<br/>Fui diagnosticada com câncer de mama há 1 semana, e estou sem chão, parece que o mundo perdeu o sentido…<br/>Confesso que minha vontade é só de ficar deitada na cama, no escuro, sozinha...<br/>Estou desde o dia do diagnóstico sem trabalhar, amanhã terei q voltar, mas só em pensar que terei q encarar os olhos das pessoas me olhando com dó, me já me dá uma ansiedade
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/86')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Rita Anton &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/30-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    O que toma para baixar o colesterol?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/30')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Georgina Klar &#183; 19/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/131-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia Gente<br/>Eu fui no Cardiologista<br/>Ele disse que 125 ainda é Pré diabetes e tem como reverter comendo frutas, verduras, comidas integral e tirando açúcar que volta o normal<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/131')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Tami Costa &#183; 22/07/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/3-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Compartilhando sua vida com uma doença crônica - recomendação de livro  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Há alguns meses, pesquisando livros sobre doenças crônicas, recebi a recomendação do livro Pain Woman Takes Your Keys, and Other Essays from a Nervous System (infelizmente sem versão em português ao meu conhecimento). Diferentemente do que estava procurando, que seria um livro de informações e fatos sobre doenças crônicas, estilo livro acadêmico, Pain Woman é um livro que relata de forma íntima e direta a experiência de desenvolver uma doença crônica, e se deparar que você vai dividir o resto de sua vida com ela. Que o resultado disso é um novo você.<br/><br/>A autora do livro, Sonya Huber, relata através de uma série de textos curtos (ou essays) sua experiência desde a aparição da doença artrite reumatóide, do seu desenvolvimento e de todo impacto que ela teve em sua vida. Ela descreve as dificuldades emocionais e sociais que isso causou, sua revolta e por fim o seu entendimento dessa nova companheira que agora era parte de si mesma.<br/><br/>O livro é bastante pessoal e nos faz conhecer Sonya e nos tornarmos de alguma forma seu amigo, da Sonya pré-artrite reumatóide e pós-artrite reumatóide. Bastante intenso e expressivo, é um dos melhores relatos que conheço do que é viver com uma doença crônica, e recomendo para todos que querem conhecer essa realidade, para lidar com a sua própria ou para melhor empatizar com alguém que está passando por isso a sua volta.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/3')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        Nenhum comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Maria Schwed &#183; 06/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/18-diabetes" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Diabetes de jejum  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia boa semana a todos
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/18')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
