
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post103() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoal, meu filho tem 10 anos e está com triglicerídeos e colesterol mais alto que deveria. Gostaria de saber como posso fazer melhor a alimentação dele, oque pode e não pode comer. Obrigado.</title>
        <meta 
          name="description" 
          content="Oi pessoal, meu filho tem 10 anos e está com triglicerídeos e colesterol mais alto que deveria. Gostaria de saber como posso fazer melhor a alimentação dele, oque pode e não pode comer. Obrigado."/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Michele Lombardi &#183; 15/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoal, meu filho tem 10 anos e está com triglicerídeos e colesterol mais alto que deveria. Gostaria de saber como posso fazer melhor a alimentação dele, oque pode e não pode comer. Obrigado.
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/103')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  5 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              2 comentários
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Michele,<br/><br/>Entendo sua preocupação. É ótimo que você esteja buscando informações para ajudar seu filho a ter uma alimentação mais saudável. Aqui vão algumas dicas práticas que podem ajudar a controlar os níveis de triglicerídeos e colesterol:<br/><br/>1. **Frutas e Verduras**: Inclua uma boa quantidade de frutas e verduras na alimentação do seu filho. Eles são ricos em fibras e ajudam a reduzir o colesterol ruim (LDL).<br/><br/>2. **Grãos Integrais**: Optar por alimentos integrais como pão integral, arroz integral e aveia pode fazer uma grande diferença. Eles também são ricos em fibras.<br/><br/>3. **Gorduras Saudáveis**: É importante escolher gorduras saudáveis. Inclua alimentos como abacate, azeite de oliva e oleaginosas (nozes, amêndoas) na dieta.<br/><br/>4. **Corte as Frituras**: Tente evitar frituras e alimentos muito gordurosos. Prefira carnes magras e métodos de preparo mais saudáveis, como grelhar, assar ou cozinhar.<br/><br/>5. **Redução de Açúcar**: Limite o consumo de doces e refrigerantes. Esses alimentos podem aumentar os níveis de triglicerídeos no sangue.<br/><br/>6. **Peixes**: Tente incluir peixes na alimentação pelo menos duas vezes por semana. Salmão e sardinha são opções ricas em ômega-3, que faz bem para o coração.<br/><br/>7. **Lanches Saudáveis**: Ofereça lanches mais saudáveis para ele, como frutas, iogurte natural, ou palitinhos de cenoura e pepino.<br/><br/>Além disso, incentivar a prática de atividades físicas é essencial. Atividades simples como brincar ao ar livre, andar de bicicleta ou nadar podem ajudar a melhorar a saúde dele.<br/><br/>Sempre bom lembrar que é importante consultar um nutricionista para ter um plano alimentar personalizado para as necessidades específicas do seu filho. E, claro, siga as orientações do médico dele.<br/><br/>Se precisar de mais alguma coisa, estarei por aqui.<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 15/08/2024
                  </p>
                </div>
              </div>


              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Obrigado pela resposta
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Michele Lombardi &#183; 15/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/103')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariana Zandona &#183; 18/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/122-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Pessoal me ajudem, alguém aí deixou de comer arroz pra normalizar a diabetes e conseguiu
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/122')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Fernando Pessoa &#183; 18/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/128-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Com quantos anos começaram a tomar remédio de hipertensão?<br/>Quantos remédios tomam por dia?<br/>Tenho 25 anos, e não consigo aceitar que sou
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/128')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariana Silva &#183; 12/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/78-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. O médico me receitou sinvastatina 20 mg uma vez por dia.<br/>Será que estou tão mal assim fico preocupada??<br/>Meu exame deu isso<br/>HDL 78.5<br/>NAO-Hdl 180mg<br/>Colesterol total 258.3<br/>Ldl 164<br/>Triglicerides 77<br/>Hemoglobina glicada 4.9%<br/><br/>Obrigado!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/78')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariano Oliveira &#183; 13/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/82-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, bom dia! Alguém aqui já viu nos exames o termo opacidade nodular? Pode me ajudar com oque significa.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/82')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Elisa Orta &#183; 14/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/91-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá, boa tarde pessoa. Uma pessoa da minha familia esta com colangiocarcinoma<br/>(Cancer nas vias biliares), e está muito magro e não consegue comer. Ele vai passar pela primeira vez no oncologista semana que vem mas já está bem fraco. Alguém aqui com esse tipo de experiência?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/91')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
