
export default function Marks({ data, xScale, yScale, xValue, yValue, innerHeight}) {

  return (
    data.map(d => 
      <>
        <rect
          key = {d.class}
          className = {`marks-bars-${d.colour}`}
          y = {yScale(yValue(d))}
          height = {innerHeight - yScale(yValue(d))}
          x = {xScale(xValue(d))}
          width = {xScale.bandwidth()}/>
        <circle
          className = {`circle-value-${d.colour}`}
          cx={xScale(xValue(d))+xScale.bandwidth()/2} 
          cy={(xScale.bandwidth() > 34) ? 
            yScale(yValue(d))-23 : 
            yScale(yValue(d))-20}
          r={(xScale.bandwidth() > 34) ? 20 : 17}
        />
        <text
          className = {`text-plot`}
          x={(yValue(d)>9) ? 
            xScale(xValue(d))+xScale.bandwidth()/2-10:
            xScale(xValue(d))+xScale.bandwidth()/2-5 
          }
          y={(xScale.bandwidth() > 34) ? 
            yScale(yValue(d))-23+6 :
            yScale(yValue(d))-20+6
          }
        >{yValue(d)}</text>
      </>
    ))
}
