
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post137() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Pessoal, eu sou diabética tipo 2 e estou tendo pico, e estou ficando desesperada. A glicose fica entre 250 e 300. Alguém sabe me informar o que eu faço?</title>
        <meta 
          name="description" 
          content="Pessoal, eu sou diabética tipo 2 e estou tendo pico, e estou ficando desesperada. A glicose fica entre 250 e 300. Alguém sabe me informar o que eu faço?"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Mariana Zandona &#183; 20/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Pessoal, eu sou diabética tipo 2 e estou tendo pico, e estou ficando desesperada. A glicose fica entre 250 e 300. Alguém sabe me informar o que eu faço?
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/137')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  4 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi, Mariana.<br/><br/>Entendo totalmente sua preocupação, e realmente é importante manter os níveis de glicose sob controle. Primeiramente, é essencial que você entre em contato com o seu médico o quanto antes para informar esses picos e seguir as orientações dele. Aqui vão algumas dicas que podem ajudar enquanto isso:<br/><br/>1. **Alimentação Balanceada**: Tente seguir uma dieta com baixo índice glicêmico, ou seja, que eleve menos o açúcar no sangue. Inclua mais legumes, verduras, grãos integrais e proteínas magras em suas refeições.<br/><br/>2. **Atividade Física**: Se o seu médico já liberou, um pouco de exercício físico, como uma caminhada leve, pode ajudar a reduzir os níveis de glicose no sangue.<br/><br/>3. **Hidratação**: Beba bastante água. A hidratação ajuda a eliminar o excesso de glicose através da urina.<br/><br/>4. **Controle do Estresse**: O estresse pode afetar os níveis de glicose. Tente atividades que te relaxem, como ouvir música ou praticar uma meditação simples.<br/><br/>Lembre-se, essas são dicas gerais e o mais importante é seguir sempre a orientação do seu médico. Se os níveis continuarem altos, especialmente por alguns dias, procure ajuda médica imediatamente.<br/><br/>Fique calma e cuide-se. Estamos aqui para te apoiar no que for preciso!<br/><br/>Abraço,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 20/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/137')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Maria Schwed &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/55-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Gente eh verdade que quem tem pressão alta também tem problema no coração?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/55')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Joana Ling &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/27-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Estou com muita dor nas pernas devido a neuropatia diabética. Eu uso pregabalina, mas ainda continuo com muita dor. Oque é bom para aliviar as dores? Alguém já passou por isso?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/27')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Gabriella Ribas &#183; 17/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/116-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Sentir muitos calafrios,é normal? Ou é sinal de alguma coisa?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/116')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Walter Medeiros &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/106-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, bom dia. Estou tomando Corus, Clorana, Carnavediol (2x ao dia) e minha pressão está 15x10. Que posso fazer?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/106')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marli Seccon &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/102-geral" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Alguém de vcs toma Vitamina D e Ômega 3? Gostaria de saber se é bom.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/102')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
