import React from 'react'
import PlusIcon from '../images/plus.svg'
import ChevronUpIcon from '../images/chevron-up-2.svg'
import ChevronDownIcon from '../images/chevron-down-2.svg'

export default function EditableItemsCard2({ children, title, clsName, toggleCard, showCard, 
  icons=true, userAdd=true, addCard }) {

  const content = (toggleCard && !showCard) ?
    ( 
      <div className={(clsName) ? `editable-card bottom-padding-s right-padding-s ${clsName}` :
          "editable-card bottom-padding-s right-padding-s "}>
        <div className="editable-card-title top-padding-s left-margin-s right-padding-xs">
          <p className="heading5">{title}</p>
          <button className="svg-btn-secondary" onClick={toggleCard}>
            <img src={ChevronDownIcon} alt="open menu items"/>
          </button>
        </div>
      </div>
    )
    : (
      <div className={(clsName) ? `editable-card bottom-padding-s right-padding-s ${clsName}` :
          "editable-card bottom-padding-s right-padding-s "}>
        <div className="editable-card-title top-padding-s left-margin-s right-padding-xs">
          <p className="heading5">{title}</p>
          <div> 
            { (icons && showCard ) ?
            <>
              { (userAdd) ? 
                <button className='svg-btn-secondary' onClick={()=>{addCard()}}>
                  <img className="right-margin-xs" src={PlusIcon} alt="add new item to list"/>
                </button> : 
                null 
              }
              <button className="svg-btn-secondary" onClick={toggleCard}>
                <img src={ChevronUpIcon} alt="close menu items"/>
              </button>
            </>: 
            (icons && userAdd) ? 
                <button className='svg-btn-secondary' onClick={()=>{addCard()}}>
                  <img src={PlusIcon} alt="add new item to list"/>
                </button>
                : null
            }
          </div>
        </div>
        {children}
      </div>
    )

  return (
    content
  )
}
