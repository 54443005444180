
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post115() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi gente, boa noite !
Minha sobrinha de 12 anos foi diagnosticada diabetes tipo 1.
Hoje ela começou a ficar gripada e não sabemos qual xarope ela pode tomar.
Alguém pode me ajudar 
Ainda não conseguimos as consultas pelo SUS.
</title>
        <meta 
          name="description" 
          content="Oi gente, boa noite !
Minha sobrinha de 12 anos foi diagnosticada diabetes tipo 1.
Hoje ela começou a ficar gripada e não sabemos qual xarope ela pode tomar.
Alguém pode me ajudar 
Ainda não conseguimos as consultas pelo SUS.
"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Camila da Silveira &#183; 17/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi gente, boa noite !<br/>Minha sobrinha de 12 anos foi diagnosticada diabetes tipo 1.<br/>Hoje ela começou a ficar gripada e não sabemos qual xarope ela pode tomar.<br/>Alguém pode me ajudar <br/>Ainda não conseguimos as consultas pelo SUS.<br/>
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/115')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  3 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Camila, boa noite!<br/><br/>Entendo a sua preocupação com sua sobrinha. Com a saúde dela em primeiro lugar, é muito importante sermos cuidadosos com os medicamentos, especialmente quando se trata de uma condição como o diabetes tipo 1. Alguns xaropes podem conter açúcar, o que pode afetar os níveis de glicose no sangue.<br/><br/>Minha sugestão é evitar qualquer medicamento sem a orientação de um profissional de saúde. Mesmo que vocês ainda estejam aguardando a consulta pelo SUS, vale a pena tentar um atendimento em uma UBS (Unidade Básica de Saúde) ou nas farmácias que oferecem orientação farmacêutica gratuitamente. Esses profissionais podem indicar um xarope que seja seguro para ela.<br/><br/>Além disso, continue monitorando os sintomas e, se observar alguma piora ou algo preocupante, procure um pronto-socorro.<br/><br/>Espero que isso ajude e que sua sobrinha melhore em breve!<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 17/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/115')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Tomaz Silveira &#183; 17/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/121-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa noite pessoal<br/>Quando eu estou nervoso ou com crise de ansiedade, mastigar chiclete me ajuda a acalmar. Sabe de algum que seja indicado pro diabético?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/121')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Gabrielli Machado &#183; 11/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/70-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Alguém pra indicar um adoçante que seja melhor pra quem tem diabetes?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/70')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Bruna Almeida &#183; 16/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/112-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal boa tarde minha hemoglobina glicada deu 13.5, ta muito alta?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/112')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Camila da Silveira &#183; 21/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/142-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Hoje fui no meu endocrinologista e ele mudou meu medicamento que já tomava faz tempo cloridrato de pioglitasona 15mg por forxiga 10mg. Reparei que a diferença de preço é grande. Esse Forxiga é muito melhor?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/142')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mario César &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/31-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Pessoal, meu exame de colesterol deu assim... Vocês sabem se está alterado? Ainda não mostrei para o médico<br/><br/>Colesterol total: 193 mg/dL<br/>Colesterol HDL: 69.7 mg/dL<br/>Triglicerídeos: 89.0
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/31')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
