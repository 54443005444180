
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post45() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoal, boa tarde. Me tirem uma dúvida.
Diabético pode usar açúcar mascavo no lugar do açúcar normal?</title>
        <meta 
          name="description" 
          content="Oi pessoal, boa tarde. Me tirem uma dúvida.
Diabético pode usar açúcar mascavo no lugar do açúcar normal?"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Danna Rosa &#183; 08/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoal, boa tarde. Me tirem uma dúvida.<br/>Diabético pode usar açúcar mascavo no lugar do açúcar normal?
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/45')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  1 pessoa achou útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi, Danna. Boa tarde!<br/><br/>É ótimo ver você buscando informações para melhor gerenciar sua saúde. Sobre o uso de açúcar mascavo, é importante lembrar que, mesmo sendo um tipo de açúcar menos processado que o açúcar refinado, ele ainda é açúcar e pode afetar os níveis de glicose no sangue de maneira semelhante.<br/><br/>Para pessoas com diabetes, é essencial controlar a quantidade de açúcar e carboidratos ingeridos para manter os níveis de glicose sob controle. Uma melhor opção pode ser adoçantes artificiais ou naturais que não elevam tanto a glicose, como a estévia ou o xilitol.<br/><br/>Mesmo assim, é sempre bom conversar com seu médico ou nutricionista antes de fazer qualquer alteração na sua dieta. Eles podem te orientar de acordo com suas necessidades específicas.<br/><br/>Qualquer dúvida, estamos aqui para ajudar!<br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 08/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/45')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marlene Techini &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/35-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal<br/>Alguém trocou propranolol por metoprolol?<br/>Tem alguma diferença significativa?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/35')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Carla Chagas &#183; 14/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/89-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá, eu gostaria de saber mais sobre neuropatia
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/89')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Larissa Maia &#183; 20/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/136-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Pessoal, para quem usava sonda, vcs se alimentavam com algo além da dieta? <br/>Meu pai está tomando a dieta conforme a nutricionista mandou mas o peso dele baixou demais, agora o tratamento já acabou mas ainda não se alimenta pela boca.<br/>Gostaria de saber se tem algo que possa dar além da dieta para ele ganhar peso, a fraqueza dificulta para conseguir fazer as tarefas do dia a dia, e isso acaba deixando ele bem, pra baixo
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/136')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            6 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marlene Techini &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/100-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, bom dia. Eu faço uso somente da insulina NPH e simplesmente não abaixa nada. Tomo ela faz um tempo e após 2hrs da refeição continua alta e em jejum tbm. Será que tenho que trocar essa insulina? Ela é fraca? <br/><br/>Aprendi a fazer contagem de carboidratos tbm e não está resolvendo. Alguém que entenda sobre essa insulina poderia me explicar o que ocorre?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/100')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Neila Gabler &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/98-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia. Medi a minha glicemia e me assustei, dei 405.<br/>Me digam uma receita Detox para baixar a glicemia rápido?<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/98')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            7 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
