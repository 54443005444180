
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post86() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoal
Fui diagnosticada com câncer de mama há 1 semana, e estou sem chão, parece que o mundo perdeu o sentido…
Confesso que minha vontade é só de ficar deitada na cama, no escuro, sozinha...
Estou desde o dia do diagnóstico sem trabalhar, amanhã terei q voltar, mas só em pensar que terei q encarar os olhos das pessoas me olhando com dó, me já me dá uma ansiedade</title>
        <meta 
          name="description" 
          content="Oi pessoal
Fui diagnosticada com câncer de mama há 1 semana, e estou sem chão, parece que o mundo perdeu o sentido…
Confesso que minha vontade é só de ficar deitada na cama, no escuro, sozinha...
Estou desde o dia do diagnóstico sem trabalhar, amanhã terei q voltar, mas só em pensar que terei q encarar os olhos das pessoas me olhando com dó, me já me dá uma ansiedade"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Martina Coelho &#183; 13/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoal<br/>Fui diagnosticada com câncer de mama há 1 semana, e estou sem chão, parece que o mundo perdeu o sentido…<br/>Confesso que minha vontade é só de ficar deitada na cama, no escuro, sozinha...<br/>Estou desde o dia do diagnóstico sem trabalhar, amanhã terei q voltar, mas só em pensar que terei q encarar os olhos das pessoas me olhando com dó, me já me dá uma ansiedade
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/86')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  5 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Martina,<br/><br/>Sinto muito pelo seu diagnóstico e entendo que este é um momento extremamente difícil para você. Quero que saiba que é normal sentir-se sobrecarregada e ansiosa. Lidar com um diagnóstico de câncer de mama traz muitos sentimentos e dúvidas, e é importante dar tempo a você mesma para processar tudo isso.<br/><br/>Se você puder, converse com alguém de sua confiança sobre como está se sentindo – pode ser um amigo, um familiar ou até mesmo um profissional, como um psicólogo. Não precisa carregar esse peso sozinha.<br/><br/>Sobre voltar ao trabalho, é compreensível que isso te cause ansiedade. Pode ser útil falar com seu chefe ou seus colegas sobre o que está acontecendo e sobre como eles podem te apoiar. Muitas pessoas acham que ajuda ser honesta sobre seus sentimentos, pois isso pode diminuir a ansiedade e criar um ambiente mais acolhedor.<br/><br/>Lembre-se de cuidar de você mesma em meio a tudo isso. Tente fazer pequenas coisas que possam te trazer um pouco de conforto e alegria, como ouvir uma música que você gosta ou dar uma caminhada leve.<br/><br/>E se, em qualquer momento, você sentir que a ansiedade está muito forte ou que você não está conseguindo lidar com isso sozinha, não hesite em procurar ajuda profissional. Isso não é um sinal de fraqueza, mas sim de que você está se cuidando.<br/><br/>Estou aqui para te apoiar da maneira que puder. Força e muita luz para você nesse caminho.<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 13/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/86')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Walter Medeiros &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/106-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, bom dia. Estou tomando Corus, Clorana, Carnavediol (2x ao dia) e minha pressão está 15x10. Que posso fazer?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/106')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Neila Gabler &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/98-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia. Medi a minha glicemia e me assustei, dei 405.<br/>Me digam uma receita Detox para baixar a glicemia rápido?<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/98')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            7 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Gabrielli Machado &#183; 18/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/124-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia!<br/>Meu exame de Histoquímico tem o seguinte resultado:<br/>Hers 2 negativo escore 0<br/>Mas alguém teve esse resultado e sabe me falar o significado?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/124')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Maria Silva &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/53-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, amanhã é minha última seção de quimioterapita. Peço orações por mim
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/53')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariana Silva &#183; 12/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/78-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. O médico me receitou sinvastatina 20 mg uma vez por dia.<br/>Será que estou tão mal assim fico preocupada??<br/>Meu exame deu isso<br/>HDL 78.5<br/>NAO-Hdl 180mg<br/>Colesterol total 258.3<br/>Ldl 164<br/>Triglicerides 77<br/>Hemoglobina glicada 4.9%<br/><br/>Obrigado!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/78')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
