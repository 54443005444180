
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post129() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Retirei o nodulo da mama faz um mês mais ou menos, e ontem saiu o resultado da cirurgia que 4 linfonodos já tinham sido atingidos. Alguém pode me ajudar no que isso significa?

</title>
        <meta 
          name="description" 
          content="Retirei o nodulo da mama faz um mês mais ou menos, e ontem saiu o resultado da cirurgia que 4 linfonodos já tinham sido atingidos. Alguém pode me ajudar no que isso significa?

"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Amanda Flores &#183; 19/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Retirei o nodulo da mama faz um mês mais ou menos, e ontem saiu o resultado da cirurgia que 4 linfonodos já tinham sido atingidos. Alguém pode me ajudar no que isso significa?<br/><br/>
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/129')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  5 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi, Amanda,<br/><br/>Primeiro, quero dizer que sinto muito pelo que você está passando. Receber esse tipo de notícia nunca é fácil, né? Mas vamos tentar entender juntos.<br/><br/>Quando um nódulo é retirado e os linfonodos são analisados, os médicos estão verificando se o câncer se espalhou para outras partes do corpo. No seu caso, parece que encontraram câncer em 4 linfonodos. Isso geralmente significa que o câncer conseguiu se espalhar para esses "gânglios", que são como pequenos filtros espalhados pelo corpo.<br/><br/>Mas não se desespere, tá? Isso ajuda os médicos a saberem qual o melhor tratamento para você daqui pra frente. Pode ser que eles recomendem quimioterapia, radioterapia ou outras formas de tratamento para garantir que todas as células cancerígenas sejam eliminadas.<br/><br/>O mais importante agora é seguir direitinho as orientações do seu médico e manter sempre um canal aberto com ele para tirar todas as suas dúvidas. Aqui na comunidade, muita gente já passou ou está passando por situações parecidas, e com certeza podem te dar apoio e compartilhar experiências.<br/><br/>Se precisar de qualquer coisa, estamos aqui!<br/><br/>Um abraço,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 19/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/129')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Tomaz Silveira &#183; 13/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/81-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, já fazem suas semanas que meço minha glicose em jejum a após fazer 1 hora de musculação e 50 minutos de caminhada, e quando meço antes do almoço sem comer nada, ela aumenta entre 30 e 40 pontos.<br/><br/>Estou estranhando porque isso nunca aconteceu antes, sempre reduzia cerca de 20 a 30 pontos após os exercícios, mas semana passada de 210 em jejum foi pra 265 após os exercícios e hoje 235 foi  pra 262 após exercícios. <br/><br/>Oque vocês acham?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/81')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Flavia de Albuquerque &#183; 17/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/114-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    A minha hemoglobina clicada deu deu 5,8. Pré diabetes. O que devo fazer para não virar diabética? Existe algum remédio que possa tomar para não virar diabética?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/114')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Manuela Escobar &#183; 23/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/148-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom tarde<br/>Gostaria de tirar uma dúvida, caneta pra medir glicemia pode ser usada várias vezes?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/148')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marcela Prattes &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/104-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Alguma dica de algo natural para baixar triglicerídeos?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/104')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Fernando Pessoa &#183; 18/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/128-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Com quantos anos começaram a tomar remédio de hipertensão?<br/>Quantos remédios tomam por dia?<br/>Tenho 25 anos, e não consigo aceitar que sou
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/128')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
