
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post27() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Estou com muita dor nas pernas devido a neuropatia diabética. Eu uso pregabalina, mas ainda continuo com muita dor. Oque é bom para aliviar as dores? Alguém já passou por isso?</title>
        <meta 
          name="description" 
          content="Estou com muita dor nas pernas devido a neuropatia diabética. Eu uso pregabalina, mas ainda continuo com muita dor. Oque é bom para aliviar as dores? Alguém já passou por isso?"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Joana Ling &#183; 07/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Estou com muita dor nas pernas devido a neuropatia diabética. Eu uso pregabalina, mas ainda continuo com muita dor. Oque é bom para aliviar as dores? Alguém já passou por isso?
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/27')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  1 pessoa achou útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Olá!<br/><br/>Eu sinto muito saber que você está sofrendo com dor nas pernas devido à neuropatia diabética. É realmente difícil lidar com isso, e é ótimo que você está buscando maneiras de melhorar a situação.<br/><br/>Primeiro, é sempre importante seguir as orientações do seu médico, especialmente no que diz respeito ao uso de medicamentos como a pregabalina. Mas além dos remédios, existem algumas coisas que podem ajudar a aliviar a dor e melhorar sua qualidade de vida:<br/><br/>1. **Exercício Físico Leve:** Atividades como caminhar ou fazer exercícios de alongamento podem melhorar a circulação e ajudar a reduzir a dor. Mas comece devagar e, de preferência, consulte seu médico antes de iniciar qualquer rotina nova.<br/><br/>2. **Massagem e Fisioterapia:** Massagens suaves ou sessões de fisioterapia podem ser eficazes. Existem também profissionais especializados em cuidados para pessoas com neuropatia.<br/><br/>3. **Cuide dos Pés:** Verifique seus pés diariamente para evitar feridas que você pode não sentir e usar calçados confortáveis pode aliviar a pressão e reduzir a dor.<br/><br/>4. **Alívio Natural:** Algumas pessoas encontram alívio com terapias alternativas, como acupuntura ou uso de pomadas com capsaicina, mas sempre converse com seu médico antes de tentar algo novo.<br/><br/>5. **Alimentação:** Manter um controle rigoroso dos níveis de açúcar no sangue é fundamental para evitar que a neuropatia piore. Uma dieta equilibrada pode ajudar nisso.<br/><br/>Lembre-se também de conversar com seu médico sobre a possibilidade de ajustar a dose da pregabalina ou sobre outras opções de tratamento que possam ser mais eficazes para você.<br/><br/>Espero que essas dicas possam ajudar um pouco. E, claro, se alguém aqui tiver mais experiências ou dicas, sinta-se à vontade para compartilhar!<br/><br/>Melhoras e um abraço!<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 07/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/27')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marlene Techini &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/48-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, tenho 31 anos e não fumo.<br/>Descobri que estou com com câncer de pulmão e estou assustada.<br/>Alguém com câncer no pulmão poderia compartilhar como está sendo?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/48')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Neila Gabler &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/98-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia. Medi a minha glicemia e me assustei, dei 405.<br/>Me digam uma receita Detox para baixar a glicemia rápido?<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/98')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            7 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mario César &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/97-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Estou bastante triste, descobri que estou com catarata por conta da diabete segundo o oftalmologista. Não consigo entender muito bem, mantenho a glicose controlada, jejum 105, prandial deu até menos, 100 glicada 6.4. Estou preocupado.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/97')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            6 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    B. Franklin &#183; 23/07/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/4-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                     O Reino Invisível das Doenças Crônicas  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Estava lendo o livro The Invisible Kingdom (Meghan O'Rourke), com o intuito de conhecer a experiência de outras pessoas que vivem com doenças crônicas, e ele traz muitos pontos que valem a atenção.<br/><br/>A escritora conta sua experiência com o surgimento de uma doença de difícil diagnóstico, onde devido de seus exames darem resultados normais ou sem um diagnóstico definido, os médicos tendiam a falar que sua doença era psicológica, como ansiedade ou depressão, ou simplesmente que não havia nada de errado com ela. Mas com certeza, pelo que estava sentindo, ela sabia que alguma coisa não estava certa.<br/><br/>Durante toda a trajetória que é contada até ela chegar a um diagnóstico tardio da doença de Lyme, que é transmitida por picada de carrapato, o livro traz muitos pontos importantes:<br/><br/>A dificuldade que médicos têm em lidar com pacientes com doenças crônicas, ou quando não tem certeza do diagnóstico do paciente, e a tendência de tentar uma saída fácil, diagnosticado como doença mental seja lá o que o paciente tenha. E por fim, a falta de empatia e de escuta ativa do sistema de saúde, e o quão sozinho esses pacientes se sentem.<br/><br/>Adicionalmente, a escritora retrata os problemas que o que ela medicina em silos gera para a experiência desses pacientes, que ficam sendo encaminhados de um especialista para o outro, onde nenhum tenta ter a visão do todo, ou do paciente como uma pessoa completa, se atendo somente a sua área de especialidade.<br/><br/>O livro é bastante longo, porém com muitas informações úteis e que podem ajudar ou servir de alento para pessoas na mesma situação.<br/><br/>Quem convive com doenças crônicas por períodos longos e sem um diagnóstico definido se sente sozinho e muitas vezes se culpa pela sua doença. Mas vocês não estão sozinhos, muitas outras pessoas estão com vocês e passando pela mesma experiência.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/4')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        Nenhum comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Manuela Escobar &#183; 23/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/148-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom tarde<br/>Gostaria de tirar uma dúvida, caneta pra medir glicemia pode ser usada várias vezes?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/148')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
