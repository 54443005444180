
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post20() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Dúvida saúde - diabetes - Diabetes</title>
        <meta 
          name="description" 
          content="Duas horas após o almoço"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Danna Rosa &#183; 06/08/2024
          </p>
        </div>
        <p className="heading4 bottom-margin-s">Diabetes</p>


        <p className="body content bottom-padding-s">
            Duas horas após o almoço
        </p>


            <img className="post-image bottom-margin-xs" 
            src={require(`../../post_images/90263697-3bc2-4bb0-b097-dc5d2990521f.28`)}  
            alt="Post image submitted by user"/>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/20')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  3 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi! Tudo bem? 😊<br/><br/>Obrigado por compartilhar a leitura da sua glicose duas horas após o almoço. Pelo valor que você mostrou, 125 mg/dL, parece estar dentro de uma faixa razoável, mas é sempre bom confirmar com o seu médico qual é o melhor intervalo para você, especificamente.<br/><br/>Manter um controle regular dos níveis de glicose é muito importante para gerenciar a diabetes. Além de fazer as medições regularmente, não esqueça de seguir a alimentação que seu médico ou nutricionista recomendou e de realizar atividades físicas conforme orientação.<br/><br/>Se você tiver alguma dúvida específica sobre esse resultado ou se esse número deveria ser diferente, recomendo conversar com o seu médico para uma orientação mais precisa.<br/><br/>Qualquer outra dúvida, estou aqui para ajudar! 💙
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 06/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/20')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariana Zandona &#183; 16/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/109-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi gente. Vai fazer dois meses que fiz a última quimioterapia da branca de cinco horas cada foram seis seções e estou ainda totalmente dormente nos pés e dor nas pernas e cólicas. Já fiz três braquiterapia e agora vou fazer 24 radioterapias. Mas essa dormência é terrível.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/109')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Gabriella Ribas &#183; 17/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/116-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Sentir muitos calafrios,é normal? Ou é sinal de alguma coisa?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/116')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Maria Meyer &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/33-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, <br/>Estou com pressão elevada fazem dias já, e tenho ido no pronto socorro por causa disso. Lá eles me receitam captopril e me mandam pra casa.<br/>Está desesperador isso. Se vocês já passaram por isso, que chá vocês tomam que possa ajudar?<br/>Obrigado!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/33')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariano Oliveira &#183; 19/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/133-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Algum chá para pressão alta
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/133')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marta Cortês &#183; 17/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/117-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    É verdade quem tem diabetes tem tendência a ter miomas?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/117')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
