
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post139() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Dúvida saúde - geral - Microlitiase vesícula </title>
        <meta 
          name="description" 
          content="Olá, 
tenho cerca de 4 pedras na vesícula com tamanho menor que 1 milímetro. O médico indicou cirurgia é realmente necessário? 


Eu sinto dores no estômago após refeições pesadas e gordurosas, mas eu também tenho uma gastrite!!! "/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Vitoria Novaes &#183; 21/08/2024
          </p>
        </div>
        <p className="heading4 bottom-margin-s">Microlitiase vesícula </p>


        <p className="body content bottom-padding-s">
            Olá, <br/>tenho cerca de 4 pedras na vesícula com tamanho menor que 1 milímetro. O médico indicou cirurgia é realmente necessário? <br/><br/><br/>Eu sinto dores no estômago após refeições pesadas e gordurosas, mas eu também tenho uma gastrite!!! 
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/139')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  7 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi, Vitoria!<br/><br/>Entendo sua preocupação! A microlitíase na vesícula, mesmo com pedras pequenas, pode causar sintomas bem desconfortáveis, como você mencionou. A dor após refeições e a associação com a gastrite podem realmente piorar seu quadro.<br/><br/>Sobre a cirurgia, a indicação do médico leva em consideração vários fatores, inclusive seus sintomas e o risco de complicações futuras. Pedrinhas pequenas podem se movimentar mais facilmente e acabar obstruindo canais, causando situações mais sérias, como uma pancreatite.<br/><br/>O melhor que você pode fazer é seguir as orientações do seu médico e tirar todas as suas dúvidas com ele. Ele conhece seu quadro completo e pode explicar por que achou necessário o procedimento, além de discutir outras possíveis alternativas.<br/><br/>Entretanto, você pode tentar algumas mudanças na sua alimentação para ver se as dores melhoram um pouco. Evitar refeições gordurosas e pesadas, comer em porções menores e mais frequentemente pode aliviar a dor. Hidratação também é importante!<br/><br/>Converse mais com seu médico para entender se há alguma flexibilidade com relação ao tempo para a cirurgia. E lembre-se de seguir as orientações médicas direitinho para evitar complicações maiores, tá bom?<br/><br/>Qualquer coisa, estou por aqui para ajudar! Tudo de bom pra você!<br/><br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 21/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/139')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Carla Chagas &#183; 20/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/135-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Glicada 6,9 e glicemia de jejum 149 é muito ruim?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/135')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    L. Carrol &#183; 24/07/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/13-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Colesterol Alto: Gorduras boas e ruins  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Na minha trajetória desde que descobri o colesterol alto 3 mudanças foram importantes: perder peso, melhorar minha alimentação e começar a fazer exercício físico. Cada uma delas ajuda a sustentar as outras e a melhorar minha saúde.<br/><br/>Sobre alimentação saudável, em particular, aprendi sobre gorduras boas e ruins para nós.<br/><br/>Gorduras boas: São gorduras mono-saturadas, poli-saturadas, omega-3 e omega-6. Ela diminuem o colesterol ruim (LDL) e aumentam o colesterol bom (HDL). A gente consegue consumir elas em alimentos como nozes, abacates e peixes. Essas são super importantes de comer!<br/><br/>Gorduras para evitar: gordura trans, colesterol e gorduras saturadas.<br/><br/>1) Gordura trans: É a gordura mais perigosa que consumimos. É usada como preservativo para longo tempo de vida útil na prateleira. Deve ser evitada completamente.<br/><br/>2_ Colesterol: Gema de ovo é a principal fonte. Conseguimos evitar comendo somente a clara do ovo.<br/><br/>3) Gordura saturada: Gordura encontrada mais facilmente nas comidas que consumimos. Evitar ao máximo. Ideal buscar comidas com 0g a 0.5g dessa gordura.<br/><br/>O ideal e o que eu tento fazer é evitar e substituir as gorduras ruins por gorduras boas: frutas, vegetais, abacate, nozes, feijão, lentilha e peixe são excelentes opções. É importante utilizar também azeite de oliva ao invés de outros óleos comuns.<br/><br/>Adicionalmente, aprendi os benefícios de evitar carne vermelha, carnes processadas, queijos, manteigas, comidas fritas e que contém muita gordura saturada.<br/><br/>Espero que esse conhecimento seja útil para vocês assim como é para mim!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/13')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Joceli Ferreira &#183; 21/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/143-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa noite pessoal. Meu irmão tá com câncer na coluna e no estômago já avançado e perdeu muito peso. Quero ajudar ele, oque eu posso fazer?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/143')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Danna Rosa &#183; 16/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/108-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia pessoal. Tenho diabetes, e quero tirar uma dúvida. <br/>Esse negócio de tomar uma colherzinha de vinagre antes do almoço, é verdade ou ilusão?<br/>Percebo que no pós fica baixa a glicemia, mas fico em dúvida se em algum momento que não estou controlando, da pico.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/108')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Walter Medeiros &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/106-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, bom dia. Estou tomando Corus, Clorana, Carnavediol (2x ao dia) e minha pressão está 15x10. Que posso fazer?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/106')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
