import React from 'react';
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import Header from "../components/Header"
import Footer from "../components/Footer"

export default function About() {
  
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  // go to the top of the page
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>Florence Health - Quem somos</title>
        <meta 
          name="description" 
          content="A Florence é uma startup brasileira de gestão de saúde, 
          fundada em 2023 em Porto Alegre. 
          Fomos pré-incubados no Hospital de Clínicas de Porto Alegre (HCPA), 
          e escolhidos como startup destaque do programa. Hoje estamos incubados na mesma instituição. 
          Nossa missão é ajudar as pessoas a cuidarem melhor de sua saúde"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? "container-simple-tablet":"container-simple"}>
        <Header tabletBreakpoint={tabletBreakpoint} menuShow={true}/>
      </div>

      <div className="container-simple">

        <section className="bottom-padding-xxl">
          <h1 className="florence-title top-margin-xxl">Quem Somos</h1>
          <p className="body top-margin-s">A Florence é uma startup brasileira de gestão de saúde, 
            fundada em 2023 em Porto Alegre.</p>
          <p className="body top-margin-xs">Fomos pré-incubados no Hospital de Clínicas de Porto Alegre (HCPA), 
            e escolhidos como startup destaque do programa. Hoje estamos incubados na mesma instituição.</p> 

          <h1 className="intra-page-title section-border-top top-margin-xl top-padding-s">Missão</h1>
          <p className="body top-margin-s">Ajudar as pessoas a cuidarem de sua saúde.</p>
          
          <h1 className="intra-page-title section-border-top top-margin-xl top-padding-s">Visão</h1>
          <p className="body top-margin-s">
            Revolucionar o cuidado à saúde no Brasil, ajudando a levar informação a quem precisa e 
            dando ferramentas para que todos possam cuidar de sua saúde da melhor forma possível, 
            gerando como resultado uma melhor qualidade de vida para a população. 
          </p>

          <h1 className="intra-page-title section-border-top top-margin-xl top-padding-s">Nossa Cultura</h1>
          <p className="body top-margin-s"><strong>Ética</strong> - Fazemos o certo sempre.</p>
          <p className="body top-margin-xs"><strong>Humildade</strong> - Sabemos que não sabemos de tudo.</p>
          <p className="body top-margin-xs"><strong>Curiosidade</strong> - E estamos sempre dispostos a perguntar e a aprender.</p>
          <p className="body top-margin-xs"><strong>Organização</strong> - Somos disciplinados e trabalhamos de maneira organizada.</p>
          <p className="body top-margin-xs"><strong>Compaixão</strong> - O principal valor da Florence é o cuidado, 
            a atenção e o carinho que devemos prestar a todos com quem interagimos em nosso dia-a-dia, 
            sejam colegas, parceiros ou usuários. 
            Porém, esse valor deve ser ainda mais latente em relação àqueles que estão sofrendo.</p>
        </section>
      </div>

      <Footer border="footer-no-right-border"/>
    </> 
  )
}
