import React from 'react'

export default function AxisBottom({ data, xScale, tickFormat, innerHeight, innerWidth,tickOffset }) {
  return (
    data.map( d => (
        <g transform={`rotate(-90) translate(${-innerHeight} ${xScale(d.date)-innerWidth})`}>
          <text className="text-plot-medium" dy=".81em" y={innerHeight+tickOffset}>
            {tickFormat(d.date)}
          </text>
        </g>
      )
    )
  )
}
