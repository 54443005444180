
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post92() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoal. Estou com glicada 8.9. Será q consigo reverter? Estou grávida e fiquei muito triste com essa descoberta</title>
        <meta 
          name="description" 
          content="Oi pessoal. Estou com glicada 8.9. Será q consigo reverter? Estou grávida e fiquei muito triste com essa descoberta"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Joana Ling &#183; 15/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoal. Estou com glicada 8.9. Será q consigo reverter? Estou grávida e fiquei muito triste com essa descoberta
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/92')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  3 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi, Joana.<br/><br/>Primeiro de tudo, parabéns pela gravidez! 😊<br/><br/>Eu entendo que você esteja preocupada com a sua glicada alta, especialmente agora que está grávida. Mas não precisa se desesperar. Muitas mulheres conseguem controlar o diabetes gestacional com mudanças na dieta, exercícios e, em alguns casos, medicação, com acompanhamento do médico.<br/><br/>Aqui vão algumas dicas que podem te ajudar:<br/>1. **Alimentação Balanceada:** Procure seguir uma dieta saudável, rica em fibras, vegetais, proteínas magras e grãos integrais. Evite alimentos ricos em açúcar e carboidratos refinados.<br/>2. **Exercícios Físicos:** Atividades leves, como caminhadas, podem ajudar a controlar a glicose. Claro, sempre consulte seu médico antes de iniciar qualquer exercício.<br/>3. **Monitoramento:** Fique de olho nos seus níveis de glicose conforme orientação do seu médico.<br/>4. **Acompanhamento Médico:** Mantenha um contato próximo com seu endocrinologista e obstetra. Eles são os melhores para te orientar nesse momento.<br/><br/>É super importante seguir as orientações médicas direitinho para garantir a saúde do bebê e a sua. E lembre-se, você não está sozinha; muitas mulheres passam por isso e conseguem levar uma gravidez tranquila e saudável.<br/><br/>Qualquer dúvida ou se precisar de mais alguma coisa, estou por aqui!<br/><br/>Abraços e cuide-se bem, Florence.
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 15/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/92')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mario César &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/31-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Pessoal, meu exame de colesterol deu assim... Vocês sabem se está alterado? Ainda não mostrei para o médico<br/><br/>Colesterol total: 193 mg/dL<br/>Colesterol HDL: 69.7 mg/dL<br/>Triglicerídeos: 89.0
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/31')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Caroline Pereira &#183; 11/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/71-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Estou com glicose em jejum de 151 tomando glifage 1x ao dia. É muito ruim?<br/>A médica havia me receitado 2x ao dia, mas eu estava muito ruim com os primeiros sintomas, e diminui para 1x pela manhã.<br/>Obrigado!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/71')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Tami Costa &#183; 22/07/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/3-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Compartilhando sua vida com uma doença crônica - recomendação de livro  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Há alguns meses, pesquisando livros sobre doenças crônicas, recebi a recomendação do livro Pain Woman Takes Your Keys, and Other Essays from a Nervous System (infelizmente sem versão em português ao meu conhecimento). Diferentemente do que estava procurando, que seria um livro de informações e fatos sobre doenças crônicas, estilo livro acadêmico, Pain Woman é um livro que relata de forma íntima e direta a experiência de desenvolver uma doença crônica, e se deparar que você vai dividir o resto de sua vida com ela. Que o resultado disso é um novo você.<br/><br/>A autora do livro, Sonya Huber, relata através de uma série de textos curtos (ou essays) sua experiência desde a aparição da doença artrite reumatóide, do seu desenvolvimento e de todo impacto que ela teve em sua vida. Ela descreve as dificuldades emocionais e sociais que isso causou, sua revolta e por fim o seu entendimento dessa nova companheira que agora era parte de si mesma.<br/><br/>O livro é bastante pessoal e nos faz conhecer Sonya e nos tornarmos de alguma forma seu amigo, da Sonya pré-artrite reumatóide e pós-artrite reumatóide. Bastante intenso e expressivo, é um dos melhores relatos que conheço do que é viver com uma doença crônica, e recomendo para todos que querem conhecer essa realidade, para lidar com a sua própria ou para melhor empatizar com alguém que está passando por isso a sua volta.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/3')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        Nenhum comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Paula Farber &#183; 18/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/125-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Pessoal com diabetes tipo 1. Quais os legumes vocês mais usam no dia a dia?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/125')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Fernando Pessoa &#183; 18/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/128-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Com quantos anos começaram a tomar remédio de hipertensão?<br/>Quantos remédios tomam por dia?<br/>Tenho 25 anos, e não consigo aceitar que sou
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/128')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
