import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { format } from "date-fns"
import Button from './Button'

export default function ExamCard({ 
  exam, 
  listId, 
  id, 
  deleteId, 
  setDeleteId,
  onConfirmDelete,
  toggleShowExam, 
  patientId,
  deleting,
  shared
}) {

  const mobileBreakpoint = useMediaQuery({ query: '(max-width: 480px)' })
  
  const examData = exam.results.map((item,i) => (
    <div key={i} className="bottom-margin-xs">
      {(item.unit && item.unit!=="None") ?
        <p class="body">&#x2022; {`${item.parameter}: ${item.value} ${item.unit}`}</p> :
        <p class="body">&#x2022; {`${item.parameter}: ${item.value}`}</p>
      }
      {(item.reference_range) ?
        <p class="secondary-text">{`Valores de referência: ${item.reference_range}`}</p> :
        null
      }
      {(item.observation) ?
        <p class="secondary-text">{`Observações: ${item.observation}`}</p> :
        null
      }
    </div>
  ))

  const toggleDeleteId = () => {
    if (deleteId !== listId) {
      setDeleteId(listId)
    } else {
      setDeleteId(null)
    }
  }

  return (
    <div className={(exam.confirmed) ? 
      "editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs" : 
      "editable-item-for-card-yellow top-margin-xs vertical-padding-xs left-padding-s right-padding-xs" }>
      <div className='editable-item-for-card-header'>
        <div>
          <p className="body">{(exam.type) ? exam.type : exam.file_name}</p>
          {(exam.result_release_date) ? 
            <p className="secondary-text">Emissão: {exam.result_release_date}</p> :
            <p className="secondary-text">Criado em: {format(exam.created_at, "d/M/yyyy")}</p>
          }
          {(exam.file_name) ? 
            <p className="secondary-text">Arquivo: {exam.file_name}</p> : null
          }
          {(exam.data_extracted) ?
              (exam.confirmed) ? null :
              <p className="secondary-text">Status: Conferência dos resultados extraídos pendente</p>
              : <p className="secondary-text">Status: Extraindo resultados do exame, isso pode levar alguns minutos</p>
          }
        </div>
      </div>

      <div className={(mobileBreakpoint) ? "column-buttons top-margin-xs" : "row-buttons top-margin-xs"}>
        {(shared || !exam.data_extracted || exam.confirmed) ?
          <Button type={(mobileBreakpoint) ? "primary button-larger" : "primary"} 
            link={true} to={`/exam/${id}`} 
            stateValue={{
                previous: (shared) ? `/sharedhealth` : `/health`, 
              title: exam.type, 
              file: exam.file_name}}>
            Abrir Exame
          </Button> :
          <Button type={(mobileBreakpoint) ? "primary button-larger" : "primary"} 
            link={true} to={`/exam/check/${id}`} 
            stateValue={{
                previous: `/health`, 
                title: exam.type, 
                file: exam.file_name,
                message: "Nosso sistema leu e retirou as informações do documento. Por segurança, é necessário que você confirme que as informações estão corretas."
              }}>
            Conferir Exame
          </Button>
        }
        { (exam.confirmed && exam.results.length > 0) ?
          <Button type="secondary" onClick={()=>{toggleShowExam(listId)}}>
            {(exam.show) ?
            "Esconder" :
            "Ver resultados"
            }
          </Button> : null
        }
        {(!shared) ?
        <Button type="tertiary" onClick={()=>{toggleDeleteId()}}>
          {(deleteId === listId) ? "Cancelar" : "Excluir"}
        </Button> : null}
      </div>

      { (deleteId === listId) ?
        <div className="editable-card bottom-padding-s right-padding-s top-margin-s bottom-margin-s">
          <div className="editable-card-title top-padding-s left-margin-s right-padding-xs">
            <p className="heading5">Você deseja excluir esse exame?</p>
          </div>

          <div className="editable-item-for-card-red top-margin-xs vertical-padding-xs left-padding-s right-padding-xs">
            <div>
                <p className="secondary-text bottom-margin-s">Essa ação não pode ser desfeita. O exame, assim como todas as informações associadas
                a ele, serão perdidas. Você deseja prosseguir?</p>
                {(deleteId === listId && deleting) ?
                  <Button type="tertiary" onClick={() => {onConfirmDelete(id)}}>Removendo...</Button> :
                  <Button type="tertiary" onClick={() => {onConfirmDelete(id)}}>Sim, quero remover esse exame</Button>
                }
            </div>
          </div>
        </div>
        : null }
      {(exam.show) ?
      <div className="top-margin-s">
        {examData}
      </div> : null }
    </div>
  )
}
