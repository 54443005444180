
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post126() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>O que vocês sabem sobre neuropatia? Estou de cadeira de rodas por isso</title>
        <meta 
          name="description" 
          content="O que vocês sabem sobre neuropatia? Estou de cadeira de rodas por isso"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Carlos Frantz &#183; 18/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            O que vocês sabem sobre neuropatia? Estou de cadeira de rodas por isso
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/126')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  3 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Carlos,<br/><br/>Sinto muito em saber sobre a sua situação. Neuropatia é um problema que afeta os nervos, podendo causar dor, fraqueza e até mesmo perda de sensibilidade. No caso do diabetes, ela é muitas vezes causada pelos altos níveis de açúcar no sangue que, com o tempo, podem danificar os nervos.<br/><br/>Aqui estão algumas informações que podem te ajudar a entender melhor:<br/><br/>1. **Causas:** No caso do diabetes, controlar bem os níveis de açúcar no sangue é fundamental para prevenir ou retardar a neuropatia.<br/>2. **Sintomas:** Pode incluir dor, formigamento, sensação de queimação, perda de sensibilidade e até dificuldade para mover partes do corpo.<br/>3. **Tratamento:** O tratamento geralmente inclui medicamentos para controlar a dor e mudanças no estilo de vida, como uma dieta saudável e exercício físico, dentro dos seus limites.<br/>4. **Cuidados diários:** Inspecione seus pés diariamente para evitar feridas, use sapatos confortáveis, e nunca ande descalço.<br/><br/>Eu recomendo bastante conversar com seu médico sobre o melhor plano de tratamento e gerenciamento da neuropatia. Ele pode te dar orientações específicas e até indicar um fisioterapeuta para te ajudar com exercícios que podem ser feitos mesmo estando em uma cadeira de rodas.<br/><br/>Se precisar de mais alguma coisa ou tiver outra dúvida, estou aqui para ajudar!<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 18/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/126')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Leana de Sá &#183; 22/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/146-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa noite. Alguém tem coceiras nas pernas provocadas pelo diabetes? Que remédio usam para tratar?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/146')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mario César &#183; 06/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/23-diabetes" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Chá para diabético  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Nao estou tomando medicamento<br/>O médico disse que posso controlar com dieta só<br/>Vocês tem chá bom pra diabetes?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/23')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Danna Rosa &#183; 16/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/108-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia pessoal. Tenho diabetes, e quero tirar uma dúvida. <br/>Esse negócio de tomar uma colherzinha de vinagre antes do almoço, é verdade ou ilusão?<br/>Percebo que no pós fica baixa a glicemia, mas fico em dúvida se em algum momento que não estou controlando, da pico.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/108')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Vitor Machado &#183; 21/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/140-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Alguém aqui toma rybelsus para controle da glicemia?<br/>Eu sinto muito enjoo e não tenho apetite, mas mesmo assim a glicose continua alta
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/140')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Rita Anton &#183; 12/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/79-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, sou hipertensa e hoje minha pressão diastólica não saiu do 10 ou 9 ....<br/>A sistólica fica entre 12 e 13 e a diastólica entre 9 e 10.<br/>Oque vocês acham que eu devo fazer?<br/>Já tomei o remédio da pressão as 18 horas tomo a cada 12 horas, mas tenho ansiedade e não estou nem conseguindo dormir porque a pressão não abaixa.<br/>Já tá me dando até dor de cabeça
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/79')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
