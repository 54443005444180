
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post93() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Qual o melhor arroz para diabético?</title>
        <meta 
          name="description" 
          content="Qual o melhor arroz para diabético?"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Danna Rosa &#183; 15/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Qual o melhor arroz para diabético?
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/93')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  4 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Danna!<br/><br/>Entendo a sua preocupação em escolher o melhor arroz para sua condição. O arroz integral geralmente é uma opção melhor para diabéticos em comparação com o arroz branco. Isso porque o arroz integral tem mais fibras, o que ajuda a controlar os níveis de açúcar no sangue e dá uma sensação de saciedade por mais tempo.<br/><br/>Além do arroz integral, vale experimentar outros tipos de grãos integrais, como quinoa, aveia ou cevada, que também são ótimos para uma dieta equilibrada.<br/><br/>Mas é sempre importante falar com seu médico ou nutricionista antes de fazer mudanças significativas na sua alimentação, tá bom?<br/><br/>Espero ter ajudado! Se precisar de mais alguma coisa, é só perguntar.<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 15/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/93')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariana Zandona &#183; 16/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/109-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi gente. Vai fazer dois meses que fiz a última quimioterapia da branca de cinco horas cada foram seis seções e estou ainda totalmente dormente nos pés e dor nas pernas e cólicas. Já fiz três braquiterapia e agora vou fazer 24 radioterapias. Mas essa dormência é terrível.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/109')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariana Silva &#183; 12/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/78-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. O médico me receitou sinvastatina 20 mg uma vez por dia.<br/>Será que estou tão mal assim fico preocupada??<br/>Meu exame deu isso<br/>HDL 78.5<br/>NAO-Hdl 180mg<br/>Colesterol total 258.3<br/>Ldl 164<br/>Triglicerides 77<br/>Hemoglobina glicada 4.9%<br/><br/>Obrigado!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/78')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Dara Costa &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/34-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal! Me falaram que quem tem colesterol alto não pode comer raízes, como batata doce, mandioca, inhame.<br/>É verdade que não pode ?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/34')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Paula Farber &#183; 18/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/125-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Pessoal com diabetes tipo 1. Quais os legumes vocês mais usam no dia a dia?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/125')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Elis Ferreira &#183; 19/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/130-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Alguém descobriu metástase  pulmonar após terminar a quimio de mama de Luminal A?<br/>Fiz uma tomografia 20 dias após terminar a quimio e deu imagem sugestiva, e agora estou aguardando o laudo. <br/>A metástase pulmonar é tão rápida assim?<br/>Estou sem chão<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/130')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
