import React from 'react';
import { Outlet } from "react-router-dom"
import { useNavigate, useLocation } from "react-router-dom"
import AuthContext from './context/AuthProvider'
import { getDataFromApi, getFoodDataFromApi } from './api'

const PortalContext = React.createContext()

export default function AuthenticatedUser() {

  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  //const [userData, setUserData] = React.useState(null);
  const [family, setFamily] = React.useState(null)
  const [health, setHealth] = React.useState({})
  const [eating, setEating] = React.useState(null)

  const [mealsTodayLoading, setMealsTodayLoading] = React.useState(false)
  const [mealsToday, setMealsToday] = React.useState([])
  const [mealsHistory, setMealsHistory] = React.useState([])

  const [owner, setOwner] = React.useState(false)

  const [reload, setReload] = React.useState(false)

  const location = useLocation()
  const navigate = useNavigate()

  async function loadDataFromAPI() {
    console.log('load data from api - family')
    try {
      //const data = await getDataFromApi('myprofile', auth, cookies)
      const data = await getDataFromApi('family', auth, cookies)
      setFamily(data.family)
      setOwner(data.owner)

      //console.log('Authenticated user: back to the main')

      setAuth(prev => ({...prev, ...data.tokens}))
    } catch (err) {
      console.log(err)
      console.log("Could not authenticate user")
      navigate('./loginrequired', { state : { previousPath: location.pathname }, replace: true})
    }
  }

  async function loadEatingDataFromAPI() {
    try {
      const eatingData = await getDataFromApi(`food/today/${+cookies.get("userId")}`, auth, cookies)
      //console.log(healthData)
      setEating(eatingData)
      setMealsToday([])

    } catch (err) {
      console.log("Could not authenticate user")
      navigate('./loginrequired', { state : { previousPath: location.pathname }, replace: true})
    }
  }

  async function loadHealthDataFromAPI(id) {
    try {
      const healthData = await getDataFromApi('userhealth', auth, cookies, {"id": id})
      //console.log(healthData)
      setHealth(prev => ({...prev, [id]: healthData}))

    } catch (err) {
      console.log("Could not authenticate user")
      navigate('./loginrequired', { state : { previousPath: location.pathname }, replace: true})
    }
  }

  React.useEffect(() => {
    console.log('load data from api - family - 0 ')
    if (!cookies.get('shared')) {
      loadDataFromAPI()
    }
    //console.log("AuthenticatedUser: fetching data from API")
    //loadHealthDataFromAPI(103)

  }, [])

  //React.useEffect(() => {
  //
  //  if (!cookies.get('shared')) {
  //    loadDataFromAPI()
  //  }
  //}, [reload])

  async function loadMealFromAPI(id) {

    const foodData = await getFoodDataFromApi(`food/${id}`, auth, cookies)

    //const updatedTodaysFood = mealsToday.filter(food => food.id !== id)
    //updatedTodaysFood.push(foodData.food)
    setMealsToday(prev => [...prev, foodData.food])
  }

  //console.log('mealsToday')
  //console.log(mealsToday)

  React.useEffect(() => {
    // load today's meals
    if (!mealsTodayLoading) {
      setMealsTodayLoading(true)
      if (eating && eating.ids.length > 0) {
        eating.ids.forEach((id)=> {
          // load meal from API
          loadMealFromAPI(id)
        })
      }
      setMealsTodayLoading(false)
    }

  }, [eating])


  return (
    <PortalContext.Provider value={{
      family, 
      owner, 
      setReload, 
      auth, 
      cookies, 
      health, eating, 
      setEating, 
      loadHealthDataFromAPI, 
      loadEatingDataFromAPI, 
      mealsToday,
      setMealsToday,
      mealsTodayLoading
    }}>
      <Outlet />
    </PortalContext.Provider>
  )
}

export { PortalContext }




