import React from 'react';
import chevronRight from "../images/chevron-right-solid.svg"
import chevronLeft from "../images/chevron-left-solid.svg"

export default function Carousel({mobileBreakpoint, buttonsList, setIndex, index}) {

  function goToNextIndex() {
    setIndex(prev => { return prev + 1 === buttonsList.length ? 0 : prev + 1 } )
  }

  function goToPrevIndex() {
    setIndex(prev => { return prev - 1 < 0 ? buttonsList.length - 1 : prev - 1 } )
  }

  function handleClick(index) {
    setIndex(index)
  }

  return (
    <div className="carousel">

      {(mobileBreakpoint) ? 
      <>
      <button className="svg-btn" onClick={()=>goToPrevIndex()}>
        <img className="chevron" src={chevronLeft} alt="Left arrow to change image"/> 
      </button>

      <button  className="carousel-btn carousel-secondary" 
        onClick={()=>handleClick((index+1)===buttonsList.length ? 0 : index + 1)}>
        { buttonsList[(index+1)===buttonsList.length ? 0 : index + 1] }
      </button>
      <button  className="carousel-btn " 
        onClick={()=>handleClick(index)}>
        { buttonsList[index] }
      </button>
      <button  className="carousel-btn carousel-secondary" 
        onClick={()=>handleClick((index-1) < 0 ? buttonsList.length - 1 : index - 1)}>
        { buttonsList[(index-1) < 0 ? buttonsList.length - 1: index - 1] }
      </button>

      <button className="svg-btn" onClick={()=>goToNextIndex()}>
        <img className="chevron" src={chevronRight} alt="Right arrow to change image"/>
      </button>
      </>
      :
      <>
      <button className="svg-btn" onClick={()=>goToPrevIndex()}>
        <img className="chevron" src={chevronLeft} alt="Left arrow to change image"/> 
      </button>

      <button className="carousel-btn carousel-tertiary" 
        onClick={()=>handleClick(
          (index+1) === buttonsList.length ? 
            1 : ((index+2)===buttonsList.length ? 0 : index + 2))}>
        { buttonsList[(index+1) === buttonsList.length ? 
          1 : ((index+2)===buttonsList.length ? 0 : index + 2)] }
      </button>
      <button  className="carousel-btn carousel-secondary" 
        onClick={()=>handleClick((index+1)===buttonsList.length ? 0 : index + 1)}>
        { buttonsList[(index+1)===buttonsList.length ? 0 : index + 1] }
      </button>
      <button  className="carousel-btn " 
        onClick={()=>handleClick(index)}>
        { buttonsList[index] }
      </button>
      <button  className="carousel-btn carousel-secondary" 
        onClick={()=>handleClick((index-1) < 0 ? buttonsList.length - 1 : index - 1)}>
        { buttonsList[(index-1) < 0 ? buttonsList.length - 1: index - 1] }
      </button>
      <button  className="carousel-btn carousel-tertiary" 
        onClick={()=>handleClick((index-2) < 0 ? buttonsList.length - 1 : index - 2)}>
        { buttonsList[(index-2) < 0 ? buttonsList.length - 1: index - 2] }
      </button>

      <button className="svg-btn" onClick={()=>goToNextIndex()}>
        <img className="chevron" src={chevronRight} alt="Right arrow to change image"/>
      </button>
      </>
      }

    </div>
  )
}
