import React from "react"
import { Link } from "react-router-dom"
import ToggleMenu from "../components/ToggleMenu.js"
import HeaderLinks from "./HeaderLinks.js"

export default function Header({tabletBreakpoint, menuItems, id, menuShow}) {

  return (
    <nav className={(tabletBreakpoint) ? "nav-center-align top-padding-xs" : "top-padding-xs"}>
      <Link to="/">
        <img className="small-logo-nav" src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
      </Link>
      { (tabletBreakpoint) ?
        <HeaderLinks menuItems={menuItems} id={id} /> :
        ( menuShow ? <ToggleMenu menuItems={menuItems} id={id}/> : null )
      }
    </nav>
  )
}
