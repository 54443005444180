
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post89() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Olá, eu gostaria de saber mais sobre neuropatia</title>
        <meta 
          name="description" 
          content="Olá, eu gostaria de saber mais sobre neuropatia"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Carla Chagas &#183; 14/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Olá, eu gostaria de saber mais sobre neuropatia
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/89')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  4 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Carla,<br/><br/>Claro, posso te explicar um pouco sobre neuropatia! Neuropatia é um problema que afeta os nervos e pode causar sintomas como formigamento, dor, fraqueza e perda de sensibilidade, especialmente nas mãos e nos pés. Pessoas com diabetes precisam prestar bastante atenção nisso, porque níveis altos de açúcar no sangue por um longo período podem danificar os nervos.<br/><br/>É muito importante manter o controle do seu nível de açúcar no sangue para prevenir ou diminuír os sintomas de neuropatia. Aqui vão algumas dicas que podem ajudar:<br/><br/>1. **Controle da Glicose**: Siga direitinho o plano de tratamento que seu médico prescreveu, incluindo os medicamentos e a dieta.<br/>2. **Alimentação Saudável**: Tente comer alimentos balanceados, com bastante vegetais e evite açúcar e carboidratos refinados.<br/>3. **Atividade Física**: Mantenha-se ativa, isso ajuda no controle da glicose e na circulação.<br/>4. **Cuidados com os Pés**: Verifique seus pés todos os dias para ver se tem cortes, bolhas ou machucados. Use calçados confortáveis e meias sem costura.<br/><br/>Sempre converse com seu médico sobre qualquer novo sintoma ou preocupação. Eles são a melhor fonte de orientação para o seu caso específico.<br/><br/>Espero ter ajudado! Fique bem.<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 14/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/89')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Flavia de Albuquerque &#183; 17/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/114-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    A minha hemoglobina clicada deu deu 5,8. Pré diabetes. O que devo fazer para não virar diabética? Existe algum remédio que possa tomar para não virar diabética?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/114')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Paula Farber &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/59-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa tarde pessoal, meu médico me mandou tomar cloridrato clomipramina 25mg pra pressão alta, mas não estou me adaptando. Tomo depois do almoço, mas estou tendo insônia e ele me deixa meio lenta, meio lesada. E não está resolvendo a pressão também. Alguém aqui toma ele?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/59')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Vitoria Novaes &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/68-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Médico receitou rosuvastatina 10mg<br/><br/>colesterol 279<br/>HDL 45<br/>VLDL 30<br/>LDL 197 <br/>Triglicérides 190<br/><br/>está mt ruim mesmo???<br/><br/>24 anos e 50kg!<br/><br/>Tenho histórico de colesterol alto desde 13 anos. Neste momento voltei com o tratamento com a medicação + dieta mas a melhora é mínima. 
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/68')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Elisa Orta &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/47-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, boa tarde<br/><br/>Gostaria de saber se 5 seções de rádioterapia são capazes de reduzir as dores no corpo causadas pelo câncer ?<br/><br/>Muito obrigado!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/47')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Michele Lombardi &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/50-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Eu tenho só 30 anos e descobri que estou com câncer no útero, e o tratamento é quimioterapia e radioterapia. Não tem como fazer a cirurgia. Estou com muito medo de não curar
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/50')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            Esse post foi útil?
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
