
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post61() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoa boa tarde
Tem problema eu comer pão integral com uma fatia de queijo meu trigliceridios está 168. Agradeço</title>
        <meta 
          name="description" 
          content="Oi pessoa boa tarde
Tem problema eu comer pão integral com uma fatia de queijo meu trigliceridios está 168. Agradeço"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Mario César &#183; 10/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoa boa tarde<br/>Tem problema eu comer pão integral com uma fatia de queijo meu trigliceridios está 168. Agradeço
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/61')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  1 pessoa achou útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Mario, boa tarde!<br/><br/>Comer pão integral com uma fatia de queijo pode ser uma opção balanceada, mas tudo depende do contexto geral da sua dieta e da sua saúde. O pão integral é uma boa escolha porque tem mais fibras do que o pão branco, o que pode ajudar a controlar o colesterol e os triglicerídeos. Contudo, é importante prestar atenção ao tipo de queijo que você está consumindo, pois alguns tipos têm alto teor de gordura e sódio.<br/><br/>Para manter seus triglicerídeos sob controle, é importante seguir uma dieta equilibrada, rica em frutas, vegetais, grãos integrais e proteínas magras. Tente também evitar alimentos processados, açúcares refinados e gorduras saturadas em excesso.<br/><br/>Mas nunca é demais lembrar: antes de fazer qualquer mudança significativa na dieta, consulte sempre seu médico ou nutricionista, que conhece melhor seu histórico e pode te dar orientações específicas.<br/><br/>Desejo muita saúde pra você!<br/><br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 10/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/61')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marlene Techini &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/35-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal<br/>Alguém trocou propranolol por metoprolol?<br/>Tem alguma diferença significativa?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/35')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Carla Esteves &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/52-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa noite pessoal. <br/>Alguém que está fazendo quimioterapia sente os pés dormentes e as pontas dos dedos das mãos? <br/>Meus pés estão muito dormentes, e está aumentando. Estou na quinta sessão de quimio
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/52')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marli Seccon &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/102-geral" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Alguém de vcs toma Vitamina D e Ômega 3? Gostaria de saber se é bom.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/102')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Rita Anton &#183; 06/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/22-diabetes" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Diabetes dicas  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Gente, boa dia!<br/><br/>Eu fui diagnosticada com diabetes fazem uma semaninhas e comecei uma reeducação alimentar (fui na nutri). Mas não estou acompanhando a glicemia, não tenho o aparelho ainda. Vocês acham que precisa comprar? Ou dá pra fazer na farmácia de vez em quando?<br/><br/>Obrigado!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/22')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Elisa Orta &#183; 14/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/91-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá, boa tarde pessoa. Uma pessoa da minha familia esta com colangiocarcinoma<br/>(Cancer nas vias biliares), e está muito magro e não consegue comer. Ele vai passar pela primeira vez no oncologista semana que vem mas já está bem fraco. Alguém aqui com esse tipo de experiência?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/91')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
