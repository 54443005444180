
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post95() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoal. Estou enjoada de comida. O que fazer pra não ter esse enjôo? Não estou conseguindo comer nada</title>
        <meta 
          name="description" 
          content="Oi pessoal. Estou enjoada de comida. O que fazer pra não ter esse enjôo? Não estou conseguindo comer nada"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Andressa Barros &#183; 15/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoal. Estou enjoada de comida. O que fazer pra não ter esse enjôo? Não estou conseguindo comer nada
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/95')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  12 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              3 comentários
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Andressa,<br/><br/>Sinto muito que você esteja passando por isso. O enjoo é um efeito colateral comum no tratamento do câncer, e eu entendo que pode ser muito difícil lidar com ele. Aqui vão algumas sugestões que podem ajudar:<br/><br/>1. **Coma pequenas porções**: Tente comer pequenas quantidades de comida várias vezes ao dia em vez de grandes refeições.<br/><br/>2. **Alimentos leves**: Opte por alimentos mais leves e secos, como biscoitos de água e sal, pães torrados ou frutas secas.<br/><br/>3. **Hidrate-se**: Beba líquidos aos poucos ao longo do dia. Chás suaves, água de coco e sucos naturais podem ser bons.<br/><br/>4. **Evite alimentos gordurosos ou fritos**: Esses podem piorar o enjoo.<br/><br/>5. **Gengibre**: Algumas pessoas acham que o gengibre em chá ou balas de gengibre ajuda a aliviar o enjoo.<br/><br/>6. **Ambiente tranquilo**: Tente se alimentar em um ambiente calmo e relaxado.<br/><br/>7. **Converse com seu médico**: É importante conversar com o seu médico sobre os sintomas. Eles podem prescrever medicamentos específicos para ajudar com o enjoo.<br/><br/>Espero que essas dicas ajudem um pouco. Lembre-se de se cuidar e falar com seu médico sobre qualquer preocupação que você tenha.<br/><br/>Com carinho,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 15/08/2024
                  </p>
                </div>
              </div>


              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Força  e fé minha amiga você vai conseguir superar essa doença
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Michele Lombardi &#183; 15/08/2024
                  </p>
                </div>
              </div>


              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Já passamos por uma situação assim com uma familiar, precisar ter força e coragem, e cuidado com as comidas ruins mesmo, como fritura. Melhoras, fica com Deus
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Caroline Pereira &#183; 15/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/95')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marília Dornelles &#183; 20/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/134-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Gente me tirem uma dúvida: É verdade que quando a gente usa insulina uma vez , é para sempre?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/134')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            6 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Camila da Silveira &#183; 17/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/115-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi gente, boa noite !<br/>Minha sobrinha de 12 anos foi diagnosticada diabetes tipo 1.<br/>Hoje ela começou a ficar gripada e não sabemos qual xarope ela pode tomar.<br/>Alguém pode me ajudar <br/>Ainda não conseguimos as consultas pelo SUS.<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/115')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Elisa Orta &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/36-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa tarde pessoal,<br/><br/>Vocês que tem hipertensão primária, fizeram exames pra investigar? Quais?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/36')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Maria de Lourdes &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/40-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal,<br/>Tenho pressão alta e hoje de manhã fiquei nervosa e ela subiu <br/>Tomei Losartana, hidroclorotiazida um diazepam e um anlodipino e um antidepressivo<br/>Agora a pressão está em 12/7 mas os batimentos cardíacos estão em 107<br/>É normal isso?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/40')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marília Dornelles &#183; 13/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/87-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia pessoal. Estou com diabetes gestacional. Como posso abaixar a diabetes através da alimentação?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/87')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
