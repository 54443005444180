
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post101() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoal, bom dia. Gostaria da opinião de vocês, tenho um familiar com diabetes tipo 1 os níveis quase sempre altos. Os médicos ainda não perceberam as causas. Já aconteceu a alguém?</title>
        <meta 
          name="description" 
          content="Oi pessoal, bom dia. Gostaria da opinião de vocês, tenho um familiar com diabetes tipo 1 os níveis quase sempre altos. Os médicos ainda não perceberam as causas. Já aconteceu a alguém?"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Elisa Orta &#183; 15/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoal, bom dia. Gostaria da opinião de vocês, tenho um familiar com diabetes tipo 1 os níveis quase sempre altos. Os médicos ainda não perceberam as causas. Já aconteceu a alguém?
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/101')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  4 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Elisa, bom dia!<br/><br/>Entendo a preocupação, manter os níveis de glicose controlados é super importante para quem tem diabetes tipo 1. Alguns fatores podem influenciar esses níveis, como alimentação, falta da dose correta de insulina, exercício físico inadequado, estresse e até outras condições de saúde.<br/><br/>Aqui estão algumas dicas que podem ajudar:<br/><br/>1. **Alimentação:** Uma dieta equilibrada é fundamental. Procurar alimentos com baixo índice glicêmico e evitar alimentos ricos em açúcar refinado pode ajudar.<br/>2. **Monitoramento Contínuo:** Medir frequentemente os níveis de glicose pode identificar padrões ou fatores desencadeantes.<br/>3. **Exercícios:** Atividade física regular pode ser benéfica, mas é bom conversar com o médico para ajustar a insulina se necessário.<br/>4. **Consulta Regular:** Continuar com as consultas regulares é crucial. Às vezes, a dosagem da medicação precisa ser ajustada.<br/>5. **Estresse:** Tentar reduzir o estresse com técnicas de relaxamento, como meditação, pode ter um impacto positivo.<br/><br/>Compartilhe essas observações com o médico do seu familiar. Ele poderá ajustar o tratamento conforme a necessidade.<br/><br/>Se precisar de mais alguma coisa, estou aqui para ajudar! Abraços!
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 15/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/101')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Camila da Silveira &#183; 21/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/142-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Hoje fui no meu endocrinologista e ele mudou meu medicamento que já tomava faz tempo cloridrato de pioglitasona 15mg por forxiga 10mg. Reparei que a diferença de preço é grande. Esse Forxiga é muito melhor?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/142')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Gabriella Ribas &#183; 17/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/116-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Sentir muitos calafrios,é normal? Ou é sinal de alguma coisa?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/116')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Fernando Pessoa &#183; 18/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/128-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Com quantos anos começaram a tomar remédio de hipertensão?<br/>Quantos remédios tomam por dia?<br/>Tenho 25 anos, e não consigo aceitar que sou
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/128')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    L. Carrol &#183; 23/07/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/6-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Cuidando do colesterol alto  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Tenho colesterol alto faz alguns anos, e quando fui diagnosticado pela primeira vez foi um pouco de choque, mas aprendi a lidar com doença e hoje meu colesterol está em níveis normais devido às mudanças que fiz de estilo de vida.<br/><br/>No fim, tenho certeza que o diagnóstico veio para o bem, pelo seguinte motivo: nos meus estudos aprendi que cerca de 85% das pessoas com mais de 50 anos (como é meu caso agora) tem algum bloqueio significativo em suas artérias, porém sem nenhum sintoma. Em dois terços dos casos (30% das vezes) o primeiro sintoma é um ataque do coração. Eu poderia estar nessa estatística, mas não estou, porque fui diagnosticado antes e mudei meu estilo de vida!<br/><br/>Duas dicas que acho que são importantes para quem descobriu que tem essa doença ou que está preocupado com seu colesterol:<br/><br/>1 - Saiba a diferença entre colesterol HDL (colesterol bom) e LDL (colesterol ruim), e como fazer para aumentar o colesterol bom e diminuir o ruim,.<br/><br/>2 - Tem 3 coisas que temos controle, e que afetam nosso colesterol e nossa saúde: nosso peso, o que comemos e nossa prática de exercícios físicos. Essas coisas estão no nosso controle e são nossa responsabilidade.<br/><br/>Esses pontos foram os que fizeram a diferença na minha saúde, e talvez possam fazer também na sua!<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/6')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        Nenhum comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Rita Anton &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/30-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    O que toma para baixar o colesterol?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/30')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
