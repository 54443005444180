
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post99() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Boa tarde pessoal, eu estou com sinusite e garganta inflamada, e minha glicemia deu um descontrole. Ela antes era 88 a 110 agora está entre 168 a 200. Será que é essas inflamações? É normal isso?</title>
        <meta 
          name="description" 
          content="Boa tarde pessoal, eu estou com sinusite e garganta inflamada, e minha glicemia deu um descontrole. Ela antes era 88 a 110 agora está entre 168 a 200. Será que é essas inflamações? É normal isso?"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Dara Costa &#183; 15/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Boa tarde pessoal, eu estou com sinusite e garganta inflamada, e minha glicemia deu um descontrole. Ela antes era 88 a 110 agora está entre 168 a 200. Será que é essas inflamações? É normal isso?
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/99')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  6 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Dara, boa tarde!<br/><br/>É super compreensível você estar preocupada com essa alteração nos níveis de glicemia.<br/><br/>Quando estamos com alguma infecção, como sinusite ou garganta inflamada, nosso corpo lança mão de vários processos para combater a doença, e isso pode sim afetar os níveis de açúcar no sangue. Infecções e inflamações podem causar um aumento na glicemia porque o corpo libera hormônios que ajudam a combater a infecção, e esses hormônios tendem a aumentar os níveis de glicose.<br/><br/>É importante você manter contato com seu médico para que ele possa orientar adequadamente sobre como controlar sua glicemia durante esse período, além de acompanhar a evolução da infecção.<br/><br/>Enquanto isso, tente seguir sua rotina de alimentação e medicação o mais próximo possível do recomendado. E claro, mantenha-se bem hidratada, isso ajuda bastante!<br/><br/>Se você estiver sentindo piora nos sintomas ou se a glicemia continuar alta, não hesite em procurar atendimento médico.<br/><br/>Espero que você melhore logo! Abraços!
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 15/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/99')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marli Seccon &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/102-geral" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Alguém de vcs toma Vitamina D e Ômega 3? Gostaria de saber se é bom.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/102')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Manuela Escobar &#183; 18/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/127-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Alguém sabe oque passar na ferida do pé diabético, pra tirar o mal cheiro? Lavar com oque?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/127')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    T. Estudante &#183; 24/07/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/12-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Frutas boas para quem tem diabetes  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Eu gosto muito de comer frutas, mas as vezes fico um pouco receoso sobre quais devo comer e quais podem me prejudicar devido a diabetes.<br/><br/>Vi esse vídeo muito bom do Dr. Roberto Yano explicando sobre isso, e compartilho aqui:<br/><br/>https://www.youtube.com/watch?v=qHvFfwOT8jw<br/><br/>Vale a pena assistir o vídeo, mas aqui está a lista de frutas que ele recomenda:<br/><br/>1) Abacate.<br/><br/>2) Pera.<br/><br/>3) Maçã.<br/><br/>4) Laranja (ideal é comer a fruta inteira, não só tomar o suco).<br/><br/>5) Cereja.<br/><br/>6) Morango.<br/><br/>7) Kiwi.<br/><br/>8 Pêssego.<br/><br/>Espero que essa lista ajude!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/12')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Danna Rosa &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/45-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, boa tarde. Me tirem uma dúvida.<br/>Diabético pode usar açúcar mascavo no lugar do açúcar normal?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/45')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Maria Meyer &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/33-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, <br/>Estou com pressão elevada fazem dias já, e tenho ido no pronto socorro por causa disso. Lá eles me receitam captopril e me mandam pra casa.<br/>Está desesperador isso. Se vocês já passaram por isso, que chá vocês tomam que possa ajudar?<br/>Obrigado!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/33')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
