import React from "react";
import Cookies from "universal-cookie"

const AuthContext = React.createContext({});

export function AuthProvider({ children }) {
  const [auth, setAuth] = React.useState({});
  const cookies = new Cookies();

  React.useEffect(() => {
    if (auth.refreshToken) {
      //console.log('updating cookies')

      //cookies.remove("refreshToken")
      //cookies.remove("accessToken")
      //console.log(cookies.get("accessToken"))

      cookies.set("refreshToken", auth.refreshToken, { path: '/' })
      cookies.set("accessToken", auth.accessToken, { path: '/' })
      //console.log(auth.accessToken)
      //console.log(cookies.get("accessToken"))
    }
  }, [auth])

  function logout() {
    console.log("logging out...")
    setAuth({})
    cookies.remove("refreshToken", { path: '/' })
    cookies.remove("accessToken", { path: '/' })
    cookies.remove("userId", { path: '/' })
    cookies.remove("userName", { path: '/' })

  }

  return (
    <AuthContext.Provider value={{ auth, setAuth, cookies, logout }}>
      {children}
    </AuthContext.Provider>
  )
}
  
export default AuthContext;
