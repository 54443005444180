import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Button from './Button'

export default function MedicationCard({ 
  medication,
  medicationIndex,
  prescriptionId, 
  prescriptionExternalFile,
  removeMedication,
  shared
}) {
  
  const mobileBreakpoint = useMediaQuery({ query: '(max-width: 480px)' })
 
  return (
    <div className={(prescriptionId) ? 
      "editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs":
      "editable-item-for-card-green top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"}>
      <p className="body">{medication.name}</p>
      {(medication.frequency) ? 
        <p className="secondary-text">Frequência: {medication.frequency}</p> : null
      }
      {(medication.quantity) ? 
        <p className="secondary-text">Quantidade: {medication.quantity}</p> : null
      }
      {(medication.begin_medication)?
        <p className="secondary-text">Iniciar uso: {medication.begin_medication}</p> : null
      }
      {(medication.end_medication)?
        <p className="secondary-text">Uso até: {medication.end_medication}</p> : null
      }
      {(medication.observation) ?
        <p className="secondary-text">Observações: {medication.observation}</p> : null
      }
      {(prescriptionId === null) ?
        <p className="secondary-text">Medicamento adicionado manualmente, sem prescrição médica</p> : null
      }

      <div className={(mobileBreakpoint) ? "column-buttons top-margin-xs" : "row-buttons top-margin-xs"}>
        {(prescriptionId !== null) ?
            <Button type={(mobileBreakpoint) ? "primary button-larger" : "primary"} 
              link={true} to={`/prescription/${prescriptionId}`} 
              stateValue={{
                previous: (shared) ? `/sharedhealth` : `/health`, 
                title: `Prescrição: ${medication.name}`, 
                file: `${prescriptionExternalFile}`
              }}>
              Abrir prescrição
            </Button>
          : null
        }
        {(!shared) ? 
          <Button type="tertiary" onClick={()=>{removeMedication(prescriptionId, medication.id, medicationIndex)}}>
            Excluir 
          </Button> : null }
      </div>

    </div>
  )
}
