import React from 'react'
import { Link } from 'react-router-dom'
import PostLikes from './PostLikes'
import PostComments from './PostComments'
import Author from './Author'
import AuthContext from '../../context/AuthProvider'

export default function PostPreview({ post }) {

  const { cookies } = React.useContext(AuthContext);
  
  return (
    <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
      <Author>{post}</Author>
      <Link className="thumbnail-link" to={`/forum/${post.id}`} reloadDocument={true}>
        <p className="heading4 primary-color bottom-margin-xs">{post.title}</p>
        <p className="body primary-color content bottom-padding-xs">{(post.content.length > 200) ? 
          post.content.slice(0,200) + "..." : post.content }</p>
      </Link>
      <div className="post-stats bottom-padding-xxs">
        <PostLikes postId={post.id} foundUseful={(cookies.get("userId") && post.voted_by) ? 
          post.voted_by.includes(cookies.get("userId")) : false} 
          qtyPeopleFoundUseful={(post.voted_by) ? post.voted_by.length : 0} />
        <PostComments qtyComments={post.comments_qty} /> 
      </div>
    </div>
  )
}
