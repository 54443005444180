
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post151() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Olá, que vitamina ou suplemento o pré-diabetico pode tomar para engordar?</title>
        <meta 
          name="description" 
          content="Olá, que vitamina ou suplemento o pré-diabetico pode tomar para engordar?"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Walter Medeiros &#183; 24/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Olá, que vitamina ou suplemento o pré-diabetico pode tomar para engordar?
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/151')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  1 pessoa achou útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Olá, Walter!<br/><br/>Entendo sua preocupação em encontrar formas de ganhar peso de maneira saudável. No entanto, é muito importante lembrar que, como pré-diabético, você deve tomar cuidados extras com tudo o que consome, inclusive vitaminas e suplementos.<br/><br/>Antes de qualquer coisa, sempre consulte o seu médico ou nutricionista antes de começar a tomar qualquer suplemento. Eles poderão te orientar da melhor forma, levando em conta suas necessidades específicas.<br/><br/>Dito isso, algumas dicas gerais que podem ajudar a ganhar peso com saúde incluem:<br/>- **Faça refeições balanceadas**: Priorize alimentos ricos em nutrientes, como grãos inteiros, legumes, proteínas magras (frango, peixe, ovos) e gorduras saudáveis (abacate, azeite).<br/>- **Coma mais frequentemente**: Em vez de três grandes refeições, tente dividir sua alimentação em 5 ou 6 refeições menores ao longo do dia.<br/>- **Adicione lanches nutritivos**: Lanches como castanhas, iogurte natural com frutas, ou um sanduíche integral com pasta de amendoim podem ser boas opções.<br/>- **Foque nas proteínas**: Alimentos ricos em proteínas podem ajudar a construir e manter a massa muscular, o que pode contribuir para um ganho de peso saudável.<br/><br/>Mas, repito, é fundamental conversar com seu médico ou nutricionista antes de fazer qualquer mudança significativa na sua alimentação ou tomar suplementos.<br/><br/>Estou aqui para qualquer outra dúvida que você tenha!<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 24/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/151')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Danna Rosa &#183; 06/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/20-diabetes" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Diabetes  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Duas horas após o almoço
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/20')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Rita Anton &#183; 12/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/79-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, sou hipertensa e hoje minha pressão diastólica não saiu do 10 ou 9 ....<br/>A sistólica fica entre 12 e 13 e a diastólica entre 9 e 10.<br/>Oque vocês acham que eu devo fazer?<br/>Já tomei o remédio da pressão as 18 horas tomo a cada 12 horas, mas tenho ansiedade e não estou nem conseguindo dormir porque a pressão não abaixa.<br/>Já tá me dando até dor de cabeça
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/79')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marília Dornelles &#183; 13/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/87-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia pessoal. Estou com diabetes gestacional. Como posso abaixar a diabetes através da alimentação?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/87')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Rita Anton &#183; 06/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/22-diabetes" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Diabetes dicas  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Gente, boa dia!<br/><br/>Eu fui diagnosticada com diabetes fazem uma semaninhas e comecei uma reeducação alimentar (fui na nutri). Mas não estou acompanhando a glicemia, não tenho o aparelho ainda. Vocês acham que precisa comprar? Ou dá pra fazer na farmácia de vez em quando?<br/><br/>Obrigado!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/22')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marcos Rosebrock &#183; 20/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/138-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi gente, tenho diabetes tipo 2 e tomo xgduo. Alguém que usa esse medicamente teve algum diagnóstico de sangue na urina?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/138')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
