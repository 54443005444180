import React from 'react'
import { Link } from "react-router-dom"

export default function LargeButtonLink({mobileBreakpoint, icon, title, text, to}) {

  return (
    <Link to={to} className={(mobileBreakpoint) ? 
      "large-button-link-mobile" : "large-button-link"}>
      {icon}
      <p className="body">{title}</p>
      <p className="secondary-text">{text}</p>
    </Link>
  )
}
