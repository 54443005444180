import React from 'react';
import { useMediaQuery } from 'react-responsive'
import Header from "../components/Header"

import Button from '../components/Button';

export default function NotFoundPage() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  return (
    <>
      <div className={(tabletBreakpoint) ? "container-simple-tablet":"container-simple"}>
        <Header tabletBreakpoint={tabletBreakpoint} menuShow={true}/>
      </div>


      <div className="top-margin-m bottom-margin-l">
        <div className={(tabletBreakpoint) ? 
          "container-simple-tablet vertical-padding-m" : "container-simple vertical-padding-m"}>
          <p className="body">Não encontramos a página que você estava procurando.</p>
          <div className="top-margin-s">
            <Button link={true} to={'/'} type="tertiary">Ir para página inicial</Button>
          </div>
        </div>
      </div>
    </>
  )
}
