import React from 'react'
import { extent, scaleLinear, scaleTime, timeFormat } from 'd3'
import Marks from './Marks'
import AxisBottom from './AxisBottom'

export default function Plot ({ data, parentWidth }) {

  const xAxisFormat = tickValue => timeFormat("%d/%m")(tickValue)

  const width=parentWidth-50
  const height=320
  const margin={
    top: 60,
    right: 30,
    left: 30,
    bottom: 70
  }

  const innerHeight = height - margin.top - margin.bottom
  const innerWidth = width - margin.right - margin.left

  const yValue = d => d.value
  const xValue = d => d.date
 
  const xScale = scaleTime()
    .domain(extent(data, xValue))
    .range([0, innerWidth])

  const yScale = scaleLinear()
    .domain(extent(data, yValue))
    .range([innerHeight, 0])

  return (
    <div className='plot'>
      <svg width={width} height={height}>
        <rect width={width} height={height} rx="12"/>
        <g transform={`translate(${margin.left}, ${margin.top})`}>
          <Marks 
            data={data} 
            yScale={yScale} 
            xScale={xScale} 
            yValue={yValue} 
            xValue={xValue}
            circleRadius={5}
            valueCircleRadius={20}
          />
          <line className="plot-line" y1={innerHeight+20} y2={innerHeight+20} x2={innerWidth}/>
          <AxisBottom
            data={data}
            xScale={xScale}
            tickFormat={xAxisFormat}
            innerHeight={innerHeight+60}
            innerWidth={265}
            tickOffset={12}
          />
        </g>
      </svg>
    </div>
  )
}
