import React from 'react'
import { useParams, useLocation, useNavigate } from "react-router-dom"
import { useMediaQuery } from 'react-responsive'
import Return from '../components/Forum/Return'
import PageScroller from '../components/PageScroller'
import { PortalContext } from '../AuthenticatedUser'
import { putExamRequestToApi } from '../api'
import Attention from "../images/sun.svg"
import HighlightedMessage from '../components/HighlightedMessage'
import Button from '../components/Button'
import PrescriptionForm from '../components/Health/PrescriptionForm'
import ExamForm from '../components/Health/ExamForm'

export default function DocumentCheckPage({ documentType }) {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const {id} = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const { auth, cookies } = React.useContext(PortalContext)
  const [ exam, setExam ] = React.useState(null)
  const [ showExam, setShowExam ] = React.useState(false)

  async function loadExamFromAPI() {
    
    try {
      const body = {
        "id": id,
      }
      const endPoint = documentType
      const data = await putExamRequestToApi(body, endPoint, auth, cookies) 
      setExam(data)

    } catch (err) {
      console.log("Could not authenticate user")
      navigate(`/health/${id}`, { state : { previousPath: location.pathname }, replace: true})
    }
  }

  React.useEffect(() => {
    loadExamFromAPI()
  }, [])

  function downloadFile() {
    const url = window.URL.createObjectURL(new Blob([exam]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute(
      'download',
      location.state?.search.file,
    );
    link.download = location.state?.search.file || "exame"
    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  let checkForm = null
  if (documentType === 'prescription') {
    checkForm = (
      <PrescriptionForm id={id} title={location.state?.search.title} file={location.state?.search.file} />
    )
  } else {
    checkForm = (
      <ExamForm id={id} />
    )
  }
          //<button onClick={()=>downloadFile()}>Download do arquivo</button>
  return (
    (!exam) ? 
    <div className="icon-loading-middle-page">
      <img className="small-logo-nav loading-icon" 
          src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
      <p>Carregando página...</p> 
    </div>
    :
    <> 
      <div className={(tabletBreakpoint) ? 'container-simple-tablet' : 'container-simple'}>
        <div className='top-margin-m'>
          <Return to={location.state?.search.previous ? location.state.search.previous : "/health"} backTo='Portal de Saúde'/>
        </div>
      </div>
     
      <div className="top-margin-m bottom-padding-m">
        <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>

        <div className="top-margin-l">
          {(location.state?.search.message) ?
          <HighlightedMessage icon={Attention} type="warning" header="Importante" 
            body={location.state?.search.message} /> : null }
        </div>
        <div className="editable-card editable-card-max-width top-margin-s bottom-padding-s">

          <div className="top-padding-s horizontal-padding-s">
            <p className="heading5 bottom-margin-s">{location.state?.search.title}</p>

            <Button type="secondary bottom-margin-s" onClick={()=>{setShowExam(prev => !prev)}}>
              { (showExam) ? "Esconder documento" : "Mostrar documento" } 
            </Button>
            { (showExam) ?
              <PageScroller 
                examTitle={location.state?.search.title}
                download={()=>downloadFile()}
                allowDownload={false}>
                {exam}
              </PageScroller> :
              null
            }
          </div>

          {checkForm}
          </div>
        </div>
      </div> 
    </>
    
  )
}
