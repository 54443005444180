
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post28() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoal, tenho diabetes tipo 2 e dor nas pernas, muita ardência a noite. Vocês já tiveram isso? Já fizeram uso de vitamina B12 e D e ajudou?</title>
        <meta 
          name="description" 
          content="Oi pessoal, tenho diabetes tipo 2 e dor nas pernas, muita ardência a noite. Vocês já tiveram isso? Já fizeram uso de vitamina B12 e D e ajudou?"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Paula Farber &#183; 07/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoal, tenho diabetes tipo 2 e dor nas pernas, muita ardência a noite. Vocês já tiveram isso? Já fizeram uso de vitamina B12 e D e ajudou?
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/28')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  3 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              2 comentários
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi! Eu sou a Florence, assistente de saúde aqui no grupo.<br/><br/>Primeiro, sinto muito que você esteja passando por isso. A dor e ardência nas pernas podem ser sintomas bem desconfortáveis, especialmente à noite. Algumas pessoas com diabetes tipo 2 relatam esses sintomas devido à neuropatia diabética, que é um dano nos nervos causado pelo alto nível de açúcar no sangue por um período prolongado. Mas, lembre-se sempre de conversar com seu médico para um diagnóstico mais preciso.<br/><br/>Sobre as vitaminas: Algumas pessoas sentem melhora ao suplementar com vitaminas B12 e D, pois elas ajudam na saúde do sistema nervoso e na função muscular. No entanto, antes de começar qualquer suplementação, é fundamental falar com seu médico para garantir que é a melhor opção para você e que as dosagens estão corretas.<br/><br/>Além disso, manter o controle rigoroso do seu nível de glicose no sangue, fazer exercícios regulares (como caminhar) e seguir uma alimentação equilibrada podem ajudar a aliviar esses sintomas.<br/><br/>Espero que isso ajude um pouco. Se precisar de mais alguma coisa, estou por aqui.<br/><br/>Fique bem e cuide-se! 🌷
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 07/08/2024
                  </p>
                </div>
              </div>


              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Muito interessante
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Leonardo Silveira &#183; 08/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/28')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Paula Farber &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/59-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa tarde pessoal, meu médico me mandou tomar cloridrato clomipramina 25mg pra pressão alta, mas não estou me adaptando. Tomo depois do almoço, mas estou tendo insônia e ele me deixa meio lenta, meio lesada. E não está resolvendo a pressão também. Alguém aqui toma ele?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/59')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Maria Meyer &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/33-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, <br/>Estou com pressão elevada fazem dias já, e tenho ido no pronto socorro por causa disso. Lá eles me receitam captopril e me mandam pra casa.<br/>Está desesperador isso. Se vocês já passaram por isso, que chá vocês tomam que possa ajudar?<br/>Obrigado!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/33')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariano Oliveira &#183; 19/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/133-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Algum chá para pressão alta
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/133')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    T. Estudante &#183; 24/07/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/12-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Frutas boas para quem tem diabetes  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Eu gosto muito de comer frutas, mas as vezes fico um pouco receoso sobre quais devo comer e quais podem me prejudicar devido a diabetes.<br/><br/>Vi esse vídeo muito bom do Dr. Roberto Yano explicando sobre isso, e compartilho aqui:<br/><br/>https://www.youtube.com/watch?v=qHvFfwOT8jw<br/><br/>Vale a pena assistir o vídeo, mas aqui está a lista de frutas que ele recomenda:<br/><br/>1) Abacate.<br/><br/>2) Pera.<br/><br/>3) Maçã.<br/><br/>4) Laranja (ideal é comer a fruta inteira, não só tomar o suco).<br/><br/>5) Cereja.<br/><br/>6) Morango.<br/><br/>7) Kiwi.<br/><br/>8 Pêssego.<br/><br/>Espero que essa lista ajude!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/12')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Danna Rosa &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/58-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, fui diagnosticada com diabetes tipo 1 e to tomando insulina. Me ensinaram a aplicar na barriga, mas queria saber se dá pra aplicar na coxa e se dói menos
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/58')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
