import React from 'react'
import EditIcon from '../images/edit.svg'
import RemoveIcon from '../images/remove.svg'
import Button from './Button'

export default function EditableItemForCard2({ children, colour, remove=false, edit=false,
  editUserField, listId=null, dictFields, userField, placeholder, deleteItem, additionCard=false, 
  addItemSubmit, setAddCard, dateField=null}) {

  const [editing, setEditing] = React.useState(false)

  const [formData, setFormData] = React.useState({})

  function handleChange(e) {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  function handleSubmit(e) {
    e.preventDefault()

    if (additionCard) {
      const finalForm =  formData
      if (!dictFields) {
        addItemSubmit(userField[0], finalForm[userField[0]])
      } else {
        // check if form es empty. If yes, send back an empty object
        if (Object.keys(finalForm).length === 0) {
          addItemSubmit(userField[0], finalForm)
          return
        }
        if (dateField) {
          finalForm[[dateField]] = new Date
        }
        addItemSubmit(userField[0], finalForm)
        // ex: {created: date, disease: 'diabetes'}
      }
      setAddCard()
    } else {
      
      const finalForm = (dictFields) ? {[userField[0]]: formData} : formData
      
      if (dateField && dictFields) {
        finalForm[userField[0]][[dateField]] = new Date
      }


      if (listId===null) {
        editUserField(Object.keys(finalForm)[0], finalForm[Object.keys(finalForm)[0]])
      } else {
        editUserField(Object.keys(finalForm)[0], listId, finalForm[Object.keys(finalForm)[0]])
      }
      setEditing(false)
    }
  }

  let editionForm = null
  if (edit || additionCard) {
    if (dictFields) {
      editionForm = dictFields.map((field,i) => (
        <div key={i} className="bottom-margin-xs">
        <textarea name={field} className="profile-question-form form-full-width shorter-form" 
          onChange={handleChange} type="text" placeholder={placeholder[i]}
          value={(formData[field]) ? formData[field] : ''}/> 
        </div>
      ))
    } else {
      editionForm = userField.map((field,i) => (
        <div key={i} className="bottom-margin-xs">
        <textarea name={field} className="profile-question-form form-full-width shorter-form" 
          onChange={handleChange} type="text" placeholder={placeholder[i]}
          value={(formData[field]) ? formData[field] : ''}/> 
        </div>
      ))
    }
  }

  return (
    <div className={(colour) ? 
      `editable-item-for-card-${colour} top-margin-xs vertical-padding-xs left-padding-s right-padding-xs`:
      "editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"}>

      {(!additionCard) ?
      <div className='editable-item-for-card-header'>
        <div>{children}</div>
        <div className="row-svg-icons left-margin-xs">
          {(!editing && edit) ? 
              <button onClick={()=>{setEditing(prev=>!prev)}} className='carousel-btn'>
                <img className="" src={EditIcon}/>
              </button> : null }
          {(remove) ? 
            <button onClick={()=>{deleteItem(userField[0], listId)}} className='carousel-btn'>
              <img className="" src={RemoveIcon}/>
            </button> : null }
        </div>
      </div> : null }
  
      {(editing || additionCard) ? 
      <form onSubmit={handleSubmit}>
        <div className="vertical-margin-xs">
          { editionForm } 
        </div>
        <div>
          {(additionCard) ?
            <>
              <Button type="primary right-margin-s">Criar</Button>
              <Button type="tertiary" onClick={()=>setAddCard()}>Cancelar</Button>
            </> :
            <>
              <Button type="primary right-margin-s">Alterar</Button>
              <Button type="tertiary" onClick={()=>setEditing(false)}>Cancelar</Button> 
            </>}
        </div>
      </form> : null}


    </div>
  )
}
