
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post68() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Médico receitou rosuvastatina 10mg

colesterol 279
HDL 45
VLDL 30
LDL 197 
Triglicérides 190

está mt ruim mesmo???

24 anos e 50kg!

Tenho histórico de colesterol alto desde 13 anos. Neste momento voltei com o tratamento com a medicação + dieta mas a melhora é mínima. </title>
        <meta 
          name="description" 
          content="Médico receitou rosuvastatina 10mg

colesterol 279
HDL 45
VLDL 30
LDL 197 
Triglicérides 190

está mt ruim mesmo???

24 anos e 50kg!

Tenho histórico de colesterol alto desde 13 anos. Neste momento voltei com o tratamento com a medicação + dieta mas a melhora é mínima. "/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Vitoria Novaes &#183; 10/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Médico receitou rosuvastatina 10mg<br/><br/>colesterol 279<br/>HDL 45<br/>VLDL 30<br/>LDL 197 <br/>Triglicérides 190<br/><br/>está mt ruim mesmo???<br/><br/>24 anos e 50kg!<br/><br/>Tenho histórico de colesterol alto desde 13 anos. Neste momento voltei com o tratamento com a medicação + dieta mas a melhora é mínima. 
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/68')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  3 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi, Vitoria! Obrigada por compartilhar sua situação. Entendo que possa ser preocupante ver esses números, especialmente com um histórico de colesterol alto desde jovem.<br/><br/>Seus níveis de colesterol e triglicérides estão de fato acima do ideal, e é importante seguir direitinho as indicações do seu médico para melhorar esses valores. A rosuvastatina é uma medicação bastante comum para ajudar a controlar os níveis de colesterol, e pode levar um tempinho para você ver melhoras substanciais, então tenha paciência.<br/><br/>Aqui estão algumas dicas que podem te ajudar junto com a medicação:<br/>- **Dieta:** Continue a seguir a dieta recomendada pelo seu médico ou nutricionista. Evite alimentos gordurosos, frituras e alimentos processados. Foque em frutas, vegetais, peixes, grãos integrais e óleos saudáveis como azeite de oliva.<br/>- **Exercícios:** Praticar atividades físicas regularmente é muito importante. Não precisa ser nada complicado – uma caminhada de 30 minutos, por exemplo, já ajuda bastante.<br/>- **Hidratação:** Beber bastante água ao longo do dia.<br/>- **Evitar o cigarro e álcool em excesso:** Se você fuma, procurar reduzir ou parar. No caso do álcool, tentar consumir com moderação.<br/><br/>O importante é lembrar que essas mudanças de hábitos, junto com a medicação, vão ajudar sim a melhorar seus níveis de colesterol. Mas é um processo, e às vezes as melhoras não são imediatas. Mantenha contato próximo com seu médico para acompanhar os progressos e ajustar o tratamento se necessário.<br/><br/>Qualquer dúvida, estamos aqui para ajudar, viu? Um abraço e cuide-se! 🌻
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 10/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/68')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Elis Ferreira &#183; 19/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/130-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Alguém descobriu metástase  pulmonar após terminar a quimio de mama de Luminal A?<br/>Fiz uma tomografia 20 dias após terminar a quimio e deu imagem sugestiva, e agora estou aguardando o laudo. <br/>A metástase pulmonar é tão rápida assim?<br/>Estou sem chão<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/130')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Gabrielli Machado &#183; 11/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/70-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Alguém pra indicar um adoçante que seja melhor pra quem tem diabetes?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/70')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mario César &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/97-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Estou bastante triste, descobri que estou com catarata por conta da diabete segundo o oftalmologista. Não consigo entender muito bem, mantenho a glicose controlada, jejum 105, prandial deu até menos, 100 glicada 6.4. Estou preocupado.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/97')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            6 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Tami Costa &#183; 06/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/25-diabetes" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Dúvida hipoglicemia:  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Quanto de glicemia é considerado hipoglicemia? Hoje estava com 75, mas não estava sentindo nada
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/25')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    T. Estudante &#183; 24/07/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/12-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Frutas boas para quem tem diabetes  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Eu gosto muito de comer frutas, mas as vezes fico um pouco receoso sobre quais devo comer e quais podem me prejudicar devido a diabetes.<br/><br/>Vi esse vídeo muito bom do Dr. Roberto Yano explicando sobre isso, e compartilho aqui:<br/><br/>https://www.youtube.com/watch?v=qHvFfwOT8jw<br/><br/>Vale a pena assistir o vídeo, mas aqui está a lista de frutas que ele recomenda:<br/><br/>1) Abacate.<br/><br/>2) Pera.<br/><br/>3) Maçã.<br/><br/>4) Laranja (ideal é comer a fruta inteira, não só tomar o suco).<br/><br/>5) Cereja.<br/><br/>6) Morango.<br/><br/>7) Kiwi.<br/><br/>8 Pêssego.<br/><br/>Espero que essa lista ajude!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/12')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
