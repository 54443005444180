import React from 'react'
import { Navigate } from "react-router-dom"
import AuthContext from '../context/AuthProvider'

export default function LogSharedUserOut() {

  const { cookies } = React.useContext(AuthContext)

  React.useEffect(() => {
    cookies.remove("shared", { path: '/' })
    cookies.remove("sharedRefreshToken", { path: '/' })
    cookies.remove("sharedAccessToken", { path: '/' })
    cookies.remove("sharedUserId", { path: '/' })
  }, [])

  return (
    <Navigate to="/" replace />
  )
}

