import React from 'react'
import PostPreview from './PostPreview'

export default function RelatedPosts({ posts }) {

  if (posts.length === 0)
    return null

  const relatedPostsLinks = posts.map(post => (<PostPreview post={post}/>))

  return (
    <div>
      <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>
      {relatedPostsLinks}
    </div>
  )
}

