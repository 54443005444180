import React from 'react'
import Button from './Button'
import { postRequestToApi } from '../api'
import { PortalContext } from '../AuthenticatedUser'
import Attention from "../images/sun.svg"
import HighlightedMessage from '../components/HighlightedMessage'

export default function UploadDocumentCard({ setAddCard, examSubmitted, setExamSubmitted, document="exam" }) {

  const [exam, setExam] = React.useState();
  const [textFormData, setTextFormData] = React.useState({title: ""});
  const [error, setError] = React.useState(null)
  const [submitting, setSubmitting] = React.useState(false)

  const { auth, cookies, setReload } = React.useContext(PortalContext)

  const userRef = React.useRef()

  React.useEffect(() => {
    if (userRef.current) {
      userRef.current.focus()
    }
  }, []);

  async function handleSubmit(e) {
    e.preventDefault()

    setSubmitting(false)

    if (document==="vaccine" && !textFormData.title) {
      setError("É necessário adicionar um título ou descrição ao documento.")
    } else {
      if (exam) {

        setError(null)

        const formData = new FormData()
        formData.append('exam', exam)

        try {
          setSubmitting(true)
          if (document === "exam") {
            await postRequestToApi(formData, `exam/${cookies.get("userId")}`, auth, cookies, true)
          } else if (document === "prescription") {
            await postRequestToApi(formData, `prescription/${cookies.get("userId")}`, auth, cookies, true)
          } else {
            await postRequestToApi(formData, 
              `vaccine/${cookies.get("userId")}`, 
              auth, 
              cookies, 
              true, 
              false,  
              textFormData.title )
          }
          
          setExamSubmitted(true)
        } catch (err) {
          console.log(err)
          setError(err.message)
        } finally {
          setSubmitting(false)
        }
      }

      //window.location.reload();
      setReload(prev => !prev)
    }
  }

  function handleOnChange(e) {
    const target = e.target

    setExam(target.files[0])
  }

  const handleTextInputChange = (e) => {
    const { name, value } = e.target

    setTextFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  let addText = null
  let submittedText = null
  let submittedMessage = null
  let submitAnotherMessage = null
  if (document === "exam") {
    addText = "Adicionar exame"
    submittedText = "Seu exame foi submetido com sucesso!"
    submittedMessage = "Em alguns instantes ele irá aparecer na lista abaixo"
    submitAnotherMessage = "Submeter outro"
  } else if (document === "prescription") {
    addText = "Adicionar prescrição"
    submittedText = "Sua prescrição foi submetida com sucesso!"
    submittedMessage = "Em alguns instantes ela irá aparecer na lista abaixo"
    submitAnotherMessage = "Submeter outra"
  } else {
    addText = "Adicionar documento/carteirinha de vacinação"
    submittedText = "Seu documento de vacinação foi submetido com sucesso!"
    submittedMessage = "Em alguns instantes ela irá aparecer na lista abaixo"
    submitAnotherMessage = "Submeter outro"
  }

  return (

    <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs">
      {(error) ?
      <HighlightedMessage icon={Attention} type="warning" header="Atenção" 
        body={error} /> : null }

      {(!examSubmitted) ?
      <form onSubmit={handleSubmit}>
        <div className="">
          <p className="body bottom-margin-xs">{addText}</p> 
          {(document === "vaccine") ? 
            <div>
              <p className="body">Título ou descrição</p>
              <input
                className="input-text bottom-margin-xs"
                name="title"
                onChange={handleTextInputChange}
                type="text"
                ref = {userRef}
                value={textFormData.title}
              />
            </div>
            : null}
          <input type="file" id="exam-upload" name="exam" onChange={handleOnChange}/>
        </div>
        <div>
            <>
              {(exam) ?
                <>
                  {(submitting) ?
                    <Button type="primary right-margin-s top-margin-xs">Criando...</Button> :
                    <Button type="primary right-margin-s top-margin-xs">Criar</Button> }
                  <Button type="tertiary" onClick={()=>setAddCard()}>Cancelar</Button>
                </>
              : null}
            </>
        </div>
      </form> :
      <>
        <p className="body">{submittedText}</p>
        <p className="secondary-text">{submittedMessage}</p>
        <div className="top-margin-xs">
          <Button type="tertiary" onClick={()=>setExamSubmitted(false)}>{submitAnotherMessage}</Button>
        </div>
      </>
      }
    </div>
  )
}
