import React from 'react'
import RemoveIcon from '../../images/remove.svg'
import { format } from "date-fns"

export default function FoodThumbnail({ food, onRemove }) {

  let imageUrl = null
  if (food.hasImage) {
    imageUrl = window.URL.createObjectURL(food.image)
  }

  return (
    <div className="editable-item-for-card-green top-margin-xs vertical-padding-xs left-padding-s right-padding-xs">     
      <div className='editable-item-for-card-header'>
        <div className='food-image-title-row'>
          {(food.hasImage) ?
          <img className="food-image" src={imageUrl} alt="Food image"/> : null }
          <div>
            <p className="body">{food.name}</p> 
            <p className="secondary-text">{food.calories} calorias</p> 
            <p className="secondary-text">{food.proteins} g de proteína</p> 
            <p className="secondary-text">Adicionado em {format(food.created_at,"d/M/yyyy")}</p>
          </div>
        </div>
        <div className="row-svg-icons left-margin-xs">
          <button onClick={()=>{onRemove()}} className='carousel-btn'>
            <img className="" src={RemoveIcon}/>
          </button>
        </div>
      </div>
    </div>
  )
}
