
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post130() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Alguém descobriu metástase  pulmonar após terminar a quimio de mama de Luminal A?
Fiz uma tomografia 20 dias após terminar a quimio e deu imagem sugestiva, e agora estou aguardando o laudo. 
A metástase pulmonar é tão rápida assim?
Estou sem chão
</title>
        <meta 
          name="description" 
          content="Alguém descobriu metástase  pulmonar após terminar a quimio de mama de Luminal A?
Fiz uma tomografia 20 dias após terminar a quimio e deu imagem sugestiva, e agora estou aguardando o laudo. 
A metástase pulmonar é tão rápida assim?
Estou sem chão
"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Elis Ferreira &#183; 19/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Alguém descobriu metástase  pulmonar após terminar a quimio de mama de Luminal A?<br/>Fiz uma tomografia 20 dias após terminar a quimio e deu imagem sugestiva, e agora estou aguardando o laudo. <br/>A metástase pulmonar é tão rápida assim?<br/>Estou sem chão<br/>
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/130')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  5 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Elis,<br/><br/>Primeiro de tudo, sinto muito que você esteja passando por isso. Eu sei que pode ser assustador e angustiante. A metástase pulmonar pode acontecer em alguns casos, mas é importante não tirar conclusões precipitadas antes de ter o laudo completo e conversar com seu médico.<br/><br/>A rapidez com que uma metástase pode aparecer varia de caso para caso. O tipo Luminal A geralmente tem um comportamento mais lento comparado a outros tipos de câncer de mama, mas cada pessoa é única e por isso é essencial que você espere pelo laudo e pelas orientações do seu médico para entender melhor a sua situação específica.<br/><br/>Enquanto isso, tente manter a calma e se cerque de apoio, seja da família, amigos ou de grupos de apoio. É fundamental ter alguém com quem conversar e desabafar durante esse período de incerteza.<br/><br/>Fique forte, Elis. Você não está sozinha nessa.<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 19/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/130')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariana Zandona &#183; 18/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/122-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Pessoal me ajudem, alguém aí deixou de comer arroz pra normalizar a diabetes e conseguiu
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/122')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    L. Carrol &#183; 23/07/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/6-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Cuidando do colesterol alto  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Tenho colesterol alto faz alguns anos, e quando fui diagnosticado pela primeira vez foi um pouco de choque, mas aprendi a lidar com doença e hoje meu colesterol está em níveis normais devido às mudanças que fiz de estilo de vida.<br/><br/>No fim, tenho certeza que o diagnóstico veio para o bem, pelo seguinte motivo: nos meus estudos aprendi que cerca de 85% das pessoas com mais de 50 anos (como é meu caso agora) tem algum bloqueio significativo em suas artérias, porém sem nenhum sintoma. Em dois terços dos casos (30% das vezes) o primeiro sintoma é um ataque do coração. Eu poderia estar nessa estatística, mas não estou, porque fui diagnosticado antes e mudei meu estilo de vida!<br/><br/>Duas dicas que acho que são importantes para quem descobriu que tem essa doença ou que está preocupado com seu colesterol:<br/><br/>1 - Saiba a diferença entre colesterol HDL (colesterol bom) e LDL (colesterol ruim), e como fazer para aumentar o colesterol bom e diminuir o ruim,.<br/><br/>2 - Tem 3 coisas que temos controle, e que afetam nosso colesterol e nossa saúde: nosso peso, o que comemos e nossa prática de exercícios físicos. Essas coisas estão no nosso controle e são nossa responsabilidade.<br/><br/>Esses pontos foram os que fizeram a diferença na minha saúde, e talvez possam fazer também na sua!<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/6')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        Nenhum comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariano Oliveira &#183; 13/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/82-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, bom dia! Alguém aqui já viu nos exames o termo opacidade nodular? Pode me ajudar com oque significa.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/82')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Joana Ling &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/27-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Estou com muita dor nas pernas devido a neuropatia diabética. Eu uso pregabalina, mas ainda continuo com muita dor. Oque é bom para aliviar as dores? Alguém já passou por isso?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/27')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Andressa Barros &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/95-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Estou enjoada de comida. O que fazer pra não ter esse enjôo? Não estou conseguindo comer nada
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/95')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            12 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        3 comentários
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
