
export default function Legend({
  data,
  innerWidth, 
  width=100, 
  height=20,
  offset=4
}) {

  return (
    data.map((d, i) => (
      <g transform={`translate(${innerWidth-110}, ${i*(height+offset)})`}>
        <rect
          className={`circle-value-${d.colour}`}
          width={width}
          height={height}
          rx="10"
        />
        <rect
          className={`marks-bars-${d.colour}`}
          width={10}
          height={height}
          rx="2"
        />

        <text
          className = {`text-plot-small`}
          x={15}
          y={5+height/2}
        >{d.class}</text>
      </g>
    ))
  )
}
