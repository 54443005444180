
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post72() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Bom dia pessoal. Estou triste por ser diagnosticado com diabetes tipo 2. Perdi muito peso e estou muito triste, mas tenho fé que vou conseguir superar. Peço ajuda dos amigos pois está sendo muito difícil</title>
        <meta 
          name="description" 
          content="Bom dia pessoal. Estou triste por ser diagnosticado com diabetes tipo 2. Perdi muito peso e estou muito triste, mas tenho fé que vou conseguir superar. Peço ajuda dos amigos pois está sendo muito difícil"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Carolina Mattos &#183; 11/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Bom dia pessoal. Estou triste por ser diagnosticado com diabetes tipo 2. Perdi muito peso e estou muito triste, mas tenho fé que vou conseguir superar. Peço ajuda dos amigos pois está sendo muito difícil
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/72')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  2 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Bom dia, Caroline.<br/><br/>Lamento muito ouvir sobre o seu diagnóstico e fico triste em saber que você está passando por um momento difícil. É completamente normal se sentir sobrecarregada agora, mas quero te lembrar que não está sozinha nessa.<br/><br/>Primeiro, é importante seguir as orientações do seu médico e manter uma comunicação aberta com ele sobre qualquer preocupação ou dificuldade. A diabetes tipo 2 pode ser gerenciada com mudanças no estilo de vida, medicação e uma alimentação saudável.<br/><br/>Aqui vão algumas dicas que podem te ajudar:<br/>1. **Alimentação:** Tente incluir mais alimentos ricos em fibras como frutas, legumes e grãos integrais. Evite alimentos com altos índices de açúcar e gordura.<br/>2. **Exercícios:** Atividades físicas regulares ajudam a controlar o nível de açúcar no sangue. Caminhadas diárias podem ser um bom começo.<br/>3. **Apoio emocional:** Participe de grupos de apoio como este para compartilhar suas experiências e ouvir de outras pessoas que estão na mesma situação.<br/>4. **Educação:** Procure aprender o máximo possível sobre a diabetes tipo 2, pois entender a condição ajuda a fazer escolhas mais conscientes no dia a dia.<br/><br/>Lembre-se de que cada passo conta e que a sua saúde é uma jornada, não um destino. Se precisar de mais apoio específico, estou aqui para te ajudar.<br/><br/>Se cuida e, com fé e determinação, você vai conseguir superar essa fase.<br/><br/>Um abraço,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 11/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/72')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Joana Ling &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/92-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Estou com glicada 8.9. Será q consigo reverter? Estou grávida e fiquei muito triste com essa descoberta
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/92')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marli Seccon &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/65-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, queria uma opinião. A médica me disse que meu colesterol tá alto e me passou sinvastatina. Meus resultados estão o seguinte: Ldl 131, Colesterol total 218, Triglicerídeos 65, Hdl 71. Oque vcs acham?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/65')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Leana de Sá &#183; 16/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/111-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa tarde alguém aqui já fez ou está fazendo tratamento com a medicação keytruda junto com a quimioterapia?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/111')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariano Oliveira &#183; 19/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/133-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Algum chá para pressão alta
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/133')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Karla Geise &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/67-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal<br/>Alguém que teve câncer de mama com metástase no pulmão?<br/>Poderiam me contar um pouco<br/>Teve sintomas?<br/>Conseguiu cura com alguma medicação? Qual tratamento fez? Operou?<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/67')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
