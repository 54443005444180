
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post98() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Bom dia. Medi a minha glicemia e me assustei, dei 405.
Me digam uma receita Detox para baixar a glicemia rápido?
</title>
        <meta 
          name="description" 
          content="Bom dia. Medi a minha glicemia e me assustei, dei 405.
Me digam uma receita Detox para baixar a glicemia rápido?
"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Neila Gabler &#183; 15/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Bom dia. Medi a minha glicemia e me assustei, dei 405.<br/>Me digam uma receita Detox para baixar a glicemia rápido?<br/>
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/98')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  7 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi, Neila, bom dia!<br/><br/>Entendo que você esteja preocupada com esse valor de glicemia. Um nível de 405 é realmente bastante alto e requer atenção imediata. É muito importante que você entre em contato com seu médico ou vá até uma unidade de saúde o mais rápido possível. Eles poderão avaliar sua condição e dar as orientações corretas.<br/><br/>Quanto às receitas Detox, infelizmente, não existe uma solução mágica que baixe a glicemia rapidamente. A melhor forma de controlar a glicemia é seguindo as orientações do seu médico, mantendo uma dieta equilibrada, tomando seus medicamentos conforme prescritos e, se possível, incorporando exercícios físicos moderados na sua rotina.<br/><br/>Enquanto você não consegue falar com o seu médico, aqui vão algumas dicas gerais para ajudar a controlar sua glicemia:<br/>- Beba bastante água, pois isso ajuda a eliminar o excesso de açúcar através da urina.<br/>- Evite alimentos ricos em carboidratos simples, como pão branco, doces e refrigerantes.<br/>- Prefira alimentos ricos em fibras, como frutas com casca, vegetais e grãos integrais.<br/>- Tente manter a calma e descansar, pois o estresse pode aumentar a glicemia.<br/><br/>Lembre-se, Neila, que cada pessoa é única e o tratamento que funciona para uma pessoa pode não ser o ideal para outra. Por isso, é essencial que você busque ajuda médica para receber uma orientação personalizada. Sua saúde é muito importante!<br/><br/>Cuide-se e, por favor, procure um médico imediatamente para garantir que você receba o cuidado necessário.<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 15/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/98')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    B. Franklin &#183; 23/07/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/4-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                     O Reino Invisível das Doenças Crônicas  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Estava lendo o livro The Invisible Kingdom (Meghan O'Rourke), com o intuito de conhecer a experiência de outras pessoas que vivem com doenças crônicas, e ele traz muitos pontos que valem a atenção.<br/><br/>A escritora conta sua experiência com o surgimento de uma doença de difícil diagnóstico, onde devido de seus exames darem resultados normais ou sem um diagnóstico definido, os médicos tendiam a falar que sua doença era psicológica, como ansiedade ou depressão, ou simplesmente que não havia nada de errado com ela. Mas com certeza, pelo que estava sentindo, ela sabia que alguma coisa não estava certa.<br/><br/>Durante toda a trajetória que é contada até ela chegar a um diagnóstico tardio da doença de Lyme, que é transmitida por picada de carrapato, o livro traz muitos pontos importantes:<br/><br/>A dificuldade que médicos têm em lidar com pacientes com doenças crônicas, ou quando não tem certeza do diagnóstico do paciente, e a tendência de tentar uma saída fácil, diagnosticado como doença mental seja lá o que o paciente tenha. E por fim, a falta de empatia e de escuta ativa do sistema de saúde, e o quão sozinho esses pacientes se sentem.<br/><br/>Adicionalmente, a escritora retrata os problemas que o que ela medicina em silos gera para a experiência desses pacientes, que ficam sendo encaminhados de um especialista para o outro, onde nenhum tenta ter a visão do todo, ou do paciente como uma pessoa completa, se atendo somente a sua área de especialidade.<br/><br/>O livro é bastante longo, porém com muitas informações úteis e que podem ajudar ou servir de alento para pessoas na mesma situação.<br/><br/>Quem convive com doenças crônicas por períodos longos e sem um diagnóstico definido se sente sozinho e muitas vezes se culpa pela sua doença. Mas vocês não estão sozinhos, muitas outras pessoas estão com vocês e passando pela mesma experiência.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/4')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        Nenhum comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Fernanda Salles &#183; 23/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/149-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa tarde, pessoal!<br/>O que fazer pra aumentar as plaquetas?<br/>A minha irmã está em tratamento paliativo faz mais de um ano, e não está conseguindo aumentar as plaquetas de jeito nenhum, e ela precisa disso para poder tomar os remédios da quimioterapia.<br/>Ela já recebeu transfusão de sangue, está tomando ferro há quatro dias, comendo feijão, beterraba e bebendo água de coco, mas sem resultados.<br/>Alguma ideia?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/149')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Jussara Melo &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/38-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa tarde pessoal<br/>Mais alguém aqui tem zumbido no ouvido?<br/>Minha pressão fica ao redor 15 por 10 mesmo tomando Losartana e hidroclorotiazida<br/>Oque vcs acham? Pode ser por isso?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/38')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Tomaz Silveira &#183; 13/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/81-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, já fazem suas semanas que meço minha glicose em jejum a após fazer 1 hora de musculação e 50 minutos de caminhada, e quando meço antes do almoço sem comer nada, ela aumenta entre 30 e 40 pontos.<br/><br/>Estou estranhando porque isso nunca aconteceu antes, sempre reduzia cerca de 20 a 30 pontos após os exercícios, mas semana passada de 210 em jejum foi pra 265 após os exercícios e hoje 235 foi  pra 262 após exercícios. <br/><br/>Oque vocês acham?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/81')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Paula Farber &#183; 06/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/21-diabetes" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Diabetes  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, espero que estejam bem<br/>￼<br/>Gostaria de pedir receitas de café da manhã, almoço e jantar.<br/>Receitas caseiras também<br/><br/>Sou gestante<br/>Não estou conseguindo controlar minha glicose<br/>Mas estou procurando profissionais<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/21')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
