
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post122() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Pessoal me ajudem, alguém aí deixou de comer arroz pra normalizar a diabetes e conseguiu</title>
        <meta 
          name="description" 
          content="Pessoal me ajudem, alguém aí deixou de comer arroz pra normalizar a diabetes e conseguiu"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Mariana Zandona &#183; 18/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Pessoal me ajudem, alguém aí deixou de comer arroz pra normalizar a diabetes e conseguiu
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/122')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  1 pessoa achou útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Mariana,<br/><br/>Entendo sua preocupação. Algumas pessoas realmente decidem diminuir ou até cortar o arroz branco para ajudar no controle do diabetes, uma vez que ele pode aumentar rapidamente o nível de açúcar no sangue.<br/><br/>Mas é importante lembrar que cada corpo reage de um jeito e, antes de fazer qualquer mudança na dieta, o ideal é sempre conversar com o seu médico ou nutricionista. Eles vão poder te orientar direitinho e sugerir substituições saudáveis que se encaixem no seu estilo de vida e nas suas necessidades.<br/><br/>Enquanto isso, uma dica geral é optar por alimentos com baixo índice glicêmico, como arroz integral, quinoa, ou até leguminosas, como feijão e lentilha. Eles ajudam a manter o nível de açúcar no sangue mais estável.<br/><br/>Fica tranquila, com acompanhamento e algumas mudanças é possível sim ter um bom controle da diabetes. 😊<br/><br/>Se precisar de mais alguma coisa, estou à disposição!<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 18/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/122')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Vitoria Novaes &#183; 21/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/139-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Microlitiase vesícula   
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá, <br/>tenho cerca de 4 pedras na vesícula com tamanho menor que 1 milímetro. O médico indicou cirurgia é realmente necessário? <br/><br/><br/>Eu sinto dores no estômago após refeições pesadas e gordurosas, mas eu também tenho uma gastrite!!! 
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/139')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            7 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Fernanda Aguiar &#183; 19/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/132-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi poderia me explicar como faço a contagem de carboidratos
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/132')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marli Seccon &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/65-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, queria uma opinião. A médica me disse que meu colesterol tá alto e me passou sinvastatina. Meus resultados estão o seguinte: Ldl 131, Colesterol total 218, Triglicerídeos 65, Hdl 71. Oque vcs acham?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/65')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Danna Rosa &#183; 16/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/108-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia pessoal. Tenho diabetes, e quero tirar uma dúvida. <br/>Esse negócio de tomar uma colherzinha de vinagre antes do almoço, é verdade ou ilusão?<br/>Percebo que no pós fica baixa a glicemia, mas fico em dúvida se em algum momento que não estou controlando, da pico.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/108')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Marli Seccon &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/102-geral" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Alguém de vcs toma Vitamina D e Ômega 3? Gostaria de saber se é bom.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/102')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
