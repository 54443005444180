
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post51() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Boa noite pessoal
Descobri um câncer no rim e decidi que não vou cuidar .
Não quero cuidar estimativa de vida 5 anos</title>
        <meta 
          name="description" 
          content="Boa noite pessoal
Descobri um câncer no rim e decidi que não vou cuidar .
Não quero cuidar estimativa de vida 5 anos"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Marcela Prattes &#183; 08/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Boa noite pessoal<br/>Descobri um câncer no rim e decidi que não vou cuidar .<br/>Não quero cuidar estimativa de vida 5 anos
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/51')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  Esse post foi útil?
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Bom dia, Marcela.<br/><br/>Sinto muito em saber sobre o seu diagnóstico. Entendo que receber uma notícia dessas pode ser extremamente difícil e assustador. É normal se sentir sobrecarregada e até desanimada. No entanto, é muito importante conversar com alguém sobre como você está se sentindo – seja um amigo, familiar ou um profissional de saúde.<br/><br/>Decidir não buscar tratamento é uma escolha muito pessoal, mas também é uma decisão que impacta profundamente sua vida e a vida das pessoas que te amam. Existem muitas opções de tratamento que podem te ajudar a ter uma melhor qualidade de vida, mesmo com o diagnóstico. Além disso, com os avanços médicos atuais, há sempre novas possibilidades que podem fazer diferença no seu caso.<br/><br/>Que tal marcar uma consulta com seu médico para entender todas as opções que você tem? Às vezes, só de ouvir mais sobre os tratamentos, suas chances e o que esperar, pode ajudar a clarear a mente e te dar mais tranquilidade para tomar uma decisão informada.<br/><br/>Se precisar conversar, estou aqui para apoiar no que for possível. E, por favor, lembre-se que buscar ajuda de um psicólogo ou um grupo de apoio também pode ser muito benéfico.<br/><br/>Abraço carinhoso,<br/><br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 08/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/51')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Maria Schwed &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/55-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Gente eh verdade que quem tem pressão alta também tem problema no coração?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/55')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Michele Lombardi &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/103-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, meu filho tem 10 anos e está com triglicerídeos e colesterol mais alto que deveria. Gostaria de saber como posso fazer melhor a alimentação dele, oque pode e não pode comer. Obrigado.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/103')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        2 comentários
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Maria Schwed &#183; 06/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/18-diabetes" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Diabetes de jejum  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia boa semana a todos
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/18')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariano Oliveira &#183; 19/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/133-pressao" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Algum chá para pressão alta
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/133')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Tomaz Silveira &#183; 17/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/121-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa noite pessoal<br/>Quando eu estou nervoso ou com crise de ansiedade, mastigar chiclete me ajuda a acalmar. Sabe de algum que seja indicado pro diabético?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/121')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
