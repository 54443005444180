
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post78() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoal. O médico me receitou sinvastatina 20 mg uma vez por dia.
Será que estou tão mal assim fico preocupada??
Meu exame deu isso
HDL 78.5
NAO-Hdl 180mg
Colesterol total 258.3
Ldl 164
Triglicerides 77
Hemoglobina glicada 4.9%

Obrigado!</title>
        <meta 
          name="description" 
          content="Oi pessoal. O médico me receitou sinvastatina 20 mg uma vez por dia.
Será que estou tão mal assim fico preocupada??
Meu exame deu isso
HDL 78.5
NAO-Hdl 180mg
Colesterol total 258.3
Ldl 164
Triglicerides 77
Hemoglobina glicada 4.9%

Obrigado!"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Mariana Silva &#183; 12/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoal. O médico me receitou sinvastatina 20 mg uma vez por dia.<br/>Será que estou tão mal assim fico preocupada??<br/>Meu exame deu isso<br/>HDL 78.5<br/>NAO-Hdl 180mg<br/>Colesterol total 258.3<br/>Ldl 164<br/>Triglicerides 77<br/>Hemoglobina glicada 4.9%<br/><br/>Obrigado!
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/78')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  3 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi, Mariana!<br/><br/>Entendo a sua preocupação, mas vou tentar te tranquilizar. A sinvastatina é um medicamento que ajuda a reduzir os níveis de colesterol no sangue, especialmente o LDL, que no seu caso, está um pouquinho elevado.<br/><br/>Seu HDL (colesterol bom) está muito bom, parabéns! Triglicerídeos e hemoglobina glicada também estão em níveis ótimos, isso é excelente. Já o colesterol total e o LDL (colesterol ruim) estão um pouco acima do ideal, e isso pode aumentar o risco de problemas cardíacos.<br/><br/>O seu médico provavelmente receitou a sinvastatina para ajudar a diminuir esses níveis e proteger sua saúde. É importante seguir a orientação dele e tomar o medicamento como prescrito.<br/><br/>Além disso, manter uma alimentação equilibrada, rica em frutas, verduras e grãos integrais, diminuir o consumo de gorduras saturadas e praticar atividades físicas regularmente são atitudes que podem ajudar a controlar o colesterol.<br/><br/>Qualquer dúvida ou preocupação específica, é sempre bom retornar ao médico para discutir. E lembre-se, você está no caminho certo cuidando da sua saúde!<br/><br/>Fique bem e conte com a gente para o que precisar!<br/><br/>Abraços! 🌻
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 12/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/78')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Larissa Maia &#183; 20/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/136-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Pessoal, para quem usava sonda, vcs se alimentavam com algo além da dieta? <br/>Meu pai está tomando a dieta conforme a nutricionista mandou mas o peso dele baixou demais, agora o tratamento já acabou mas ainda não se alimenta pela boca.<br/>Gostaria de saber se tem algo que possa dar além da dieta para ele ganhar peso, a fraqueza dificulta para conseguir fazer as tarefas do dia a dia, e isso acaba deixando ele bem, pra baixo
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/136')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            6 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Dara Costa &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/49-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal. Vou começar fazer quimioterapia. <br/>Vocês sabem dizer com quantas sessões começa a cair o cabelo?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/49')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Luíza Castro &#183; 17/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/118-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá, o que aconselham a dar de café da manhã para uma criança de 10 anos com diabetes tipo 1?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/118')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Carolina Mattos &#183; 11/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/72-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia pessoal. Estou triste por ser diagnosticado com diabetes tipo 2. Perdi muito peso e estou muito triste, mas tenho fé que vou conseguir superar. Peço ajuda dos amigos pois está sendo muito difícil
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/72')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Rita Anton &#183; 10/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/60-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, me deem um conselho. Quantas vezes ao dias vocês medem a glicemia? Vocês chegam a medir com maior frequência quando sentem dores ou formigamento em alguma parte do corpo, pra ver se tem a ver com a diabetes?<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/60')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
