
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post73() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoal. Tomem cuidado com o estresse. Eu estava com a pressão controlada, 11 por 8, mas tive um problema e estreei grande hoje e novamente subiu</title>
        <meta 
          name="description" 
          content="Oi pessoal. Tomem cuidado com o estresse. Eu estava com a pressão controlada, 11 por 8, mas tive um problema e estreei grande hoje e novamente subiu"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Andressa Barros &#183; 11/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoal. Tomem cuidado com o estresse. Eu estava com a pressão controlada, 11 por 8, mas tive um problema e estreei grande hoje e novamente subiu
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/73')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  3 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Andressa,<br/><br/>É realmente importante estar atenta ao estresse, pois ele pode afetar bastante a pressão arterial. Quando estamos estressados, o corpo libera hormônios como a adrenalina e o cortisol que podem elevar temporariamente a pressão.<br/><br/>Tenta reservar um tempinho do seu dia para atividades que te ajudem a relaxar, como ouvir música, praticar yoga ou até mesmo dar uma caminhada ao ar livre. Essas pequenas coisas podem fazer uma diferença grande no controle do estresse e, consequentemente, na sua pressão.<br/><br/>Lembre-se que, se sua pressão continuar a subir ou se você sentir outros sintomas, é fundamental conversar com seu médico para ajustar o tratamento, se necessário.<br/><br/>Cuide-se e qualquer coisa estamos aqui para te ajudar!<br/><br/>Abraços,<br/>Florence
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 11/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/73')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    J. Sandel &#183; 06/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/17-diabetes" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Diabetes - Café da manhã  
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia sugestões de café da manhã por gentileza
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/17')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Neila Gabler &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/46-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, se puderem me ajudar aqui...<br/><br/>Fiz os meus exames médicos apontam, e estou com os seguintes resultados:<br/><br/>-Colesterol de Total- 220 mg/dL.<br/>-Triglicerideos- 195 mg/dL.<br/><br/>Qual remédio devo tomar?<br/><br/>Desde já agradeço!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/46')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            Esse post foi útil?
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Georgina Klar &#183; 19/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/131-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia Gente<br/>Eu fui no Cardiologista<br/>Ele disse que 125 ainda é Pré diabetes e tem como reverter comendo frutas, verduras, comidas integral e tirando açúcar que volta o normal<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/131')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Vitoria Novaes &#183; 21/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/139-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Microlitiase vesícula   
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá, <br/>tenho cerca de 4 pedras na vesícula com tamanho menor que 1 milímetro. O médico indicou cirurgia é realmente necessário? <br/><br/><br/>Eu sinto dores no estômago após refeições pesadas e gordurosas, mas eu também tenho uma gastrite!!! 
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/139')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            7 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Tomaz Silveira &#183; 17/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/121-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Boa noite pessoal<br/>Quando eu estou nervoso ou com crise de ansiedade, mastigar chiclete me ajuda a acalmar. Sabe de algum que seja indicado pro diabético?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/121')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
