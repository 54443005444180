
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post46() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Oi pessoal, se puderem me ajudar aqui...

Fiz os meus exames médicos apontam, e estou com os seguintes resultados:

-Colesterol de Total- 220 mg/dL.
-Triglicerideos- 195 mg/dL.

Qual remédio devo tomar?

Desde já agradeço!</title>
        <meta 
          name="description" 
          content="Oi pessoal, se puderem me ajudar aqui...

Fiz os meus exames médicos apontam, e estou com os seguintes resultados:

-Colesterol de Total- 220 mg/dL.
-Triglicerideos- 195 mg/dL.

Qual remédio devo tomar?

Desde já agradeço!"/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            Neila Gabler &#183; 08/08/2024
          </p>
        </div>


        <p className="body content bottom-padding-s">
            Oi pessoal, se puderem me ajudar aqui...<br/><br/>Fiz os meus exames médicos apontam, e estou com os seguintes resultados:<br/><br/>-Colesterol de Total- 220 mg/dL.<br/>-Triglicerideos- 195 mg/dL.<br/><br/>Qual remédio devo tomar?<br/><br/>Desde já agradeço!
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/46')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  Esse post foi útil?
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              1 comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

              <div className="comment-background comment">
                <p className="body comment-fixed">
                  Oi Neila! Como você está?<br/><br/>Primeiramente, é ótimo que você está se cuidando e monitorando seus exames. Os níveis de colesterol total e triglicerídeos que você mencionou estão um pouco elevados, mas não se preocupe, com algumas mudanças e orientações do seu médico, você pode melhorar esses resultados.<br/><br/>Sobre o remédio, não posso recomendar nenhum específico, porque isso depende muito do seu histórico médico e de outros fatores pessoais que só o seu médico pode avaliar corretamente. O mais importante aqui é você conversar com o seu médico, ele ou ela vai te prescrever a medicação adequada se for necessário.<br/><br/>Enquanto isso, vou deixar algumas dicas que podem te ajudar a controlar o colesterol e os triglicerídeos:<br/>- **Alimentação saudável**: Tente reduzir o consumo de alimentos ricos em gorduras saturadas e trans, como frituras e fast-food. Prefira alimentos ricos em fibras, como frutas, legumes e grãos integrais.<br/>- **Atividade física**: Se puder, pratique alguma atividade física regularmente, como caminhadas. Isso ajuda a melhorar os níveis de colesterol.<br/>- **Evitar álcool e cigarro**: O consumo excessivo de álcool e o hábito de fumar podem piorar os níveis de colesterol e triglicerídeos.<br/>- **Hidratação**: Beba bastante água ao longo do dia.<br/><br/>Espero que essas dicas te ajudem! Mas, por favor, marque uma consulta com o seu médico para discutir os resultados do seu exame e o melhor tratamento para o seu caso.<br/><br/>Qualquer coisa, estou aqui para ajudar. Boa sorte e cuide-se! 😊
                </p>
              </div>

              <div className="post-stats">
                <div className="bottom-margin-s">
                  <p className="secondary-text bottom-margin-xs">
                    Florence Health &#183; 08/08/2024
                  </p>
                </div>
              </div>


          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/46')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Gabrielli Machado &#183; 11/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/70-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Alguém pra indicar um adoçante que seja melhor pra quem tem diabetes?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/70')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            2 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Walter Medeiros &#183; 24/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/151-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá, que vitamina ou suplemento o pré-diabetico pode tomar para engordar?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/151')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Elisa Orta &#183; 08/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/47-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, boa tarde<br/><br/>Gostaria de saber se 5 seções de rádioterapia são capazes de reduzir as dores no corpo causadas pelo câncer ?<br/><br/>Muito obrigado!
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/47')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Michele Lombardi &#183; 15/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/103-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi pessoal, meu filho tem 10 anos e está com triglicerídeos e colesterol mais alto que deveria. Gostaria de saber como posso fazer melhor a alimentação dele, oque pode e não pode comer. Obrigado.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/103')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        2 comentários
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Vitoria Novaes &#183; 21/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/139-geral" reloadDocument={true}>

                  <p className="heading4 primary-color bottom-margin-xs">
                    Microlitiase vesícula   
                  </p>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá, <br/>tenho cerca de 4 pedras na vesícula com tamanho menor que 1 milímetro. O médico indicou cirurgia é realmente necessário? <br/><br/><br/>Eu sinto dores no estômago após refeições pesadas e gordurosas, mas eu também tenho uma gastrite!!! 
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/139')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            7 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
