import React from 'react'
import { Navigate } from "react-router-dom"
import { userLogout } from "../api"
import AuthContext from '../context/AuthProvider'

export default function LogUserOut() {

  const { auth, cookies, logout } = React.useContext(AuthContext)

  React.useEffect(() => {
    userLogout(auth, cookies)
    logout()
  }, [])

  return (
    <Navigate to="/" replace />
  )
}

