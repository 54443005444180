import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Button from './Button'
import { format } from "date-fns"

export default function PrescriptionCard({ 
  prescription, 
  listId, 
  id, 
  deleteId, 
  setDeleteId,
  onConfirmDelete,
  deleting,
  shared
}) {

  const mobileBreakpoint = useMediaQuery({ query: '(max-width: 480px)' })
  
  const toggleDeleteId = () => {
    if (deleteId !== listId) {
      setDeleteId(listId)
    } else {
      setDeleteId(null)
    }
  }

  let medicationString = ''
    for (let i=0; i < prescription.medication.length; i++) {
      if (medicationString === '') {
        medicationString += prescription.medication.at(i).name
      } else {
        medicationString += ', '
        medicationString += prescription.medication.at(i).name
      }
    }
  return (
    <div className={(prescription.confirmed) ? 
      "editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs":
      "editable-item-for-card-yellow top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"}>
      <div className='editable-item-for-card-header'>
        {(prescription.data_extracted) ?
          <div>
            <p className="body">{medicationString}</p>
            {(prescription.professional) ? 
              <p className="secondary-text">Profissional responsável: {prescription.professional}</p> : null
            }
            {(prescription.emission_date) ? 
              <p className="secondary-text">Emissão: {prescription.emission_date}</p> : null
            }
            {(prescription.expiration_date) ? 
              <p className="secondary-text">Validade: {prescription.expiration_date}</p> : null
            }
            <p className="secondary-text">Arquivo: {prescription.external_filename}</p>
            {(prescription.data_extracted) ?
                (prescription.confirmed) ? null :
                <p className="secondary-text">Status: Conferência dos resultados extraídos pendente</p>
                : <p className="secondary-text">Status: Extraindo resultados da prescrição, isso pode levar alguns minutos</p>
            }
          </div>
        :
          <div>
            <p className="body">{prescription.external_filename}</p>
            <p className="secondary-text">Criado em: {format(prescription.created_at, "d/M/yyyy")}</p>
            {(prescription.data_extracted) ?
                (prescription.confirmed) ? null :
                <p className="secondary-text">Status: Conferência dos resultados extraídos pendente</p>
                : <p className="secondary-text">Status: Extraindo resultados da prescrição, isso pode levar alguns minutos</p>
            }
          </div>
        }
      </div>

      <div className={(mobileBreakpoint) ? "column-buttons top-margin-xs" : "row-buttons top-margin-xs"}>
        {(shared || prescription.confirmed || !(prescription.data_extracted)) ? 
        <Button type={(mobileBreakpoint) ? "primary button-larger" : "primary"} 
          link={true} to={`/prescription/${id}`} 
          stateValue={{
              previous: (shared) ? `/sharedhealth` : `/health`, 
              title: medicationString, 
              file: prescription.external_filename
            }}>
          Abrir prescrição
        </Button> :
        <Button type={(mobileBreakpoint) ? "primary button-larger" : "primary"} 
          link={true} to={`/prescription/check/${id}`} 
          stateValue={{
              previous: `/health`, 
              title: medicationString, 
              file: prescription.external_filename,
              message: "Nosso sistema leu e retirou as informações do documento. Por segurança, é necessário que você confirme que as informações estão corretas."
            }}>
          Conferir prescrição
        </Button>
        }
        {(!shared) ?
        <Button type="tertiary" onClick={()=>{toggleDeleteId()}}>
          {(deleteId === listId) ? "Cancelar" : "Excluir"}
        </Button> : null }
      </div>

      { (deleteId === listId) ?
        <div className="editable-card bottom-padding-s right-padding-s top-margin-s bottom-margin-s">
          <div className="editable-card-title top-padding-s left-margin-s right-padding-xs">
            <p className="heading5">Você deseja excluir essa prescrição?</p>
          </div>

          <div className="editable-item-for-card-red top-margin-xs vertical-padding-xs left-padding-s right-padding-xs">
            <div>
                <p className="secondary-text bottom-margin-s">Essa ação não pode ser desfeita. A prescrição, assim como todas as informações associadas
                a ela, incluindo medicações, serão perdidas. Você deseja prosseguir?</p>
                {(deleteId === listId && deleting) ?
                  <Button type="tertiary" onClick={() => {onConfirmDelete(id)}}>Removendo...</Button> :
                  <Button type="tertiary" onClick={() => {onConfirmDelete(id)}}>Sim, quero remover essa prescrição</Button>
                }
            </div>
          </div>
        </div>
        : null }
    </div>
  )
}
