
import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, Link } from "react-router-dom"
import Button from '../../components/Button'
import Return from '../../components/Forum/Return'
import CommentIcon from '../../images/comment.svg'
import LikeIcon from '../../images/like.svg'

export default function Post4() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])


  return (

    <div className="top-margin-s">

      <Helmet>
        <title>Dúvida saúde - geral -  O Reino Invisível das Doenças Crônicas</title>
        <meta 
          name="description" 
          content="Estava lendo o livro The Invisible Kingdom (Meghan O'Rourke), com o intuito de conhecer a experiência de outras pessoas que vivem com doenças crônicas, e ele traz muitos pontos que valem a atenção.

A escritora conta sua experiência com o surgimento de uma doença de difícil diagnóstico, onde devido de seus exames darem resultados normais ou sem um diagnóstico definido, os médicos tendiam a falar que sua doença era psicológica, como ansiedade ou depressão, ou simplesmente que não havia nada de errado com ela. Mas com certeza, pelo que estava sentindo, ela sabia que alguma coisa não estava certa.

Durante toda a trajetória que é contada até ela chegar a um diagnóstico tardio da doença de Lyme, que é transmitida por picada de carrapato, o livro traz muitos pontos importantes:

A dificuldade que médicos têm em lidar com pacientes com doenças crônicas, ou quando não tem certeza do diagnóstico do paciente, e a tendência de tentar uma saída fácil, diagnosticado como doença mental seja lá o que o paciente tenha. E por fim, a falta de empatia e de escuta ativa do sistema de saúde, e o quão sozinho esses pacientes se sentem.

Adicionalmente, a escritora retrata os problemas que o que ela medicina em silos gera para a experiência desses pacientes, que ficam sendo encaminhados de um especialista para o outro, onde nenhum tenta ter a visão do todo, ou do paciente como uma pessoa completa, se atendo somente a sua área de especialidade.

O livro é bastante longo, porém com muitas informações úteis e que podem ajudar ou servir de alento para pessoas na mesma situação.

Quem convive com doenças crônicas por períodos longos e sem um diagnóstico definido se sente sozinho e muitas vezes se culpa pela sua doença. Mas vocês não estão sozinhos, muitas outras pessoas estão com vocês e passando pela mesma experiência."/>
      </Helmet>

      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to='/forum' />
        </div>
        <div className="bottom-margin-s">
          <p className="secondary-text bottom-margin-xs">
            B. Franklin &#183; 23/07/2024
          </p>
        </div>
        <p className="heading4 bottom-margin-s"> O Reino Invisível das Doenças Crônicas</p>


        <p className="body content bottom-padding-s">
            Estava lendo o livro The Invisible Kingdom (Meghan O'Rourke), com o intuito de conhecer a experiência de outras pessoas que vivem com doenças crônicas, e ele traz muitos pontos que valem a atenção.<br/><br/>A escritora conta sua experiência com o surgimento de uma doença de difícil diagnóstico, onde devido de seus exames darem resultados normais ou sem um diagnóstico definido, os médicos tendiam a falar que sua doença era psicológica, como ansiedade ou depressão, ou simplesmente que não havia nada de errado com ela. Mas com certeza, pelo que estava sentindo, ela sabia que alguma coisa não estava certa.<br/><br/>Durante toda a trajetória que é contada até ela chegar a um diagnóstico tardio da doença de Lyme, que é transmitida por picada de carrapato, o livro traz muitos pontos importantes:<br/><br/>A dificuldade que médicos têm em lidar com pacientes com doenças crônicas, ou quando não tem certeza do diagnóstico do paciente, e a tendência de tentar uma saída fácil, diagnosticado como doença mental seja lá o que o paciente tenha. E por fim, a falta de empatia e de escuta ativa do sistema de saúde, e o quão sozinho esses pacientes se sentem.<br/><br/>Adicionalmente, a escritora retrata os problemas que o que ela medicina em silos gera para a experiência desses pacientes, que ficam sendo encaminhados de um especialista para o outro, onde nenhum tenta ter a visão do todo, ou do paciente como uma pessoa completa, se atendo somente a sua área de especialidade.<br/><br/>O livro é bastante longo, porém com muitas informações úteis e que podem ajudar ou servir de alento para pessoas na mesma situação.<br/><br/>Quem convive com doenças crônicas por períodos longos e sem um diagnóstico definido se sente sozinho e muitas vezes se culpa pela sua doença. Mas vocês não estão sozinhos, muitas outras pessoas estão com vocês e passando pela mesma experiência.
        </p>


        <div className="post-stats bottom-padding-xs">
          <button onClick={()=>{navigate('/forum/4')}} className="svg-btn">
            <div className="post-useful-info">
              <div> 
               <img src={LikeIcon} alt="Button indicating if you found this useful" />
              </div>
              <p className="secondary-text">
                  2 pessoas acharam útil
              </p>
            </div>
          </button>

          <div className="post-useful-info"> 
            <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
            <p className="secondary-text">
              Nenhum comentário
            </p>
          </div> 
        </div>
        <div className="top-padding-xs">

          <Button type="primary bottom-margin-s" onClick={()=>{navigate('/forum/4')}}>Comentar</Button>
        </div>

        <div className="top-padding-s">
          <p className="heading4 bottom-padding-xs bottom-border">Postagens Relacionadas</p>


              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Georgina Klar &#183; 19/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/131-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Bom dia Gente<br/>Eu fui no Cardiologista<br/>Ele disse que 125 ainda é Pré diabetes e tem como reverter comendo frutas, verduras, comidas integral e tirando açúcar que volta o normal<br/>
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/131')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Rita Anton &#183; 07/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/30-colesterol" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    O que toma para baixar o colesterol?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/30')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            1 pessoa achou útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Francisca Duarte &#183; 22/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/145-cancer" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Oi gente<br/>Fui a minha primeira consulta com mastologista oncológico, meu tipo é triplo negativo.<br/>Vou começar o tratamento com quimioterapia, alguém com esse mesmo tipo? Como tem sido o tratamento? Obrigado.
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/145')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            3 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Mariana Zandona &#183; 20/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/137-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Pessoal, eu sou diabética tipo 2 e estou tendo pico, e estou ficando desesperada. A glicose fica entre 250 e 300. Alguém sabe me informar o que eu faço?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/137')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            4 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div className={"post-thumbnail top-padding-xxs bottom-padding-xxs"}>
                <div className="">
                  <p className="secondary-text bottom-margin-xs">
                    Luíza Castro &#183; 17/08/2024
                  </p>
                </div>
                <Link className="thumbnail-link" to="/post/118-diabetes" reloadDocument={true}>

                  <p className="body primary-color content bottom-padding-xs">
                    Olá, o que aconselham a dar de café da manhã para uma criança de 10 anos com diabetes tipo 1?
                  </p>
                </Link>
                <div className="post-stats bottom-padding-xxs">
                  <div className="post-stats bottom-padding-xs">
                    <button onClick={()=>{navigate('/forum/118')}} className="svg-btn">
                      <div className="post-useful-info">
                        <div> 
                         <img src={LikeIcon} alt="Button indicating if you found this useful" />
                        </div>
                        <p className="secondary-text">
                            5 pessoas acharam útil
                        </p>
                      </div>
                    </button>

                    <div className="post-useful-info"> 
                      <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
                      <p className="secondary-text">
                        1 comentário
                      </p>
                    </div> 
                  </div>
                </div>
              </div>


        </div>

      </div>
    </div>
  )
}
